// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAJhnRqjiVinFK1hVCbBxJoLFZElDQPIm8",
  authDomain: "giggles-62f17.firebaseapp.com",
  projectId: "giggles-62f17",
  storageBucket: "giggles-62f17.appspot.com",
  messagingSenderId: "867641966215",
  appId: "1:867641966215:web:517159e26d5ca18cb48222",
  measurementId: "G-B39ZRXBZVN",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
