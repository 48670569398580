import { MessageSummaryState } from "../../module/message_summary";
import { notificationActions } from "../../module/notification";
import { useAppDispatch, useAppSelector } from "../../module/hook";
import {
  collection,
  doc,
  getDoc,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  startAfter,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import React, { useEffect, useState } from "react";
import "./notification.css";
import { Header } from "../header";
import { Sidebar } from "../sidebar";
import { getAuth } from "firebase/auth";
import { RightSidebar } from "../right_sidebar";
import moment from "moment";
import defaultAvatar from "../../asset/image/default_avatar.png";

export function Notification() {
  const navigate = useNavigate();

  const auth = getAuth();
  const firestore = getFirestore();

  const messageSummaries = useAppSelector(
    (state) => state.notification.messageSummaries,
  );

  const dispatch = useAppDispatch();

  const [lastVisible, setLastVisible] = useState<any>(undefined);

  const getMessageSummaries = async () => {
    let q;
    if (lastVisible === -1) {
      return;
    } else if (lastVisible !== undefined) {
      q = query(
        collection(firestore, "user", auth.currentUser!.uid, "notification"),
        orderBy("timestamp", "desc"),
        limit(10),
        startAfter(lastVisible),
      );
    } else {
      q = query(
        collection(firestore, "user", auth.currentUser!.uid, "notification"),
        orderBy("timestamp", "desc"),
        limit(10),
      );
    }

    onSnapshot(q, async (snapshot) => {
      const newDocuments = snapshot.docs.map((elem) => elem.data());

      for (const elem of newDocuments) {
        let authorNickname = "";
        let authorAvatarUrl = "";

        const authorDocSnap = await getDoc(
          doc(firestore, "user", elem.authorUid),
        );
        if (authorDocSnap.exists()) {
          const data = authorDocSnap.data();
          authorNickname = data.nickname || "";
          authorAvatarUrl = data.avatarUrl || "";
        }

        const messageSummary: MessageSummaryState = {
          messageType: elem.messageType,
          documentId: elem.documentId,
          commentId: elem.commentId,
          replyId: elem.replyId,
          authorUid: elem.authorUid,
          authorNickname: authorNickname,
          authorAvatarUrl: authorAvatarUrl,
          timestamp: elem.timestamp,
        };

        dispatch(notificationActions.appendMessageSummary(messageSummary));
      }

      if (snapshot.docs.length === 0) {
        setLastVisible(-1);
      } else {
        setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
      }
    });
  };

  useEffect(() => {
    if (auth.currentUser === null) {
      navigate("/login");
    } else {
      setLastVisible(undefined);
      getMessageSummaries();
    }
  }, []);

  useBottomScrollListener(getMessageSummaries);

  return (
    <div className="Notification">
      <Body messageSummaries={messageSummaries} />
    </div>
  );
}

function Body(props: { messageSummaries: MessageSummaryState[] }) {
  return (
    <div className="Notification-Body">
      <Header />
      <Sidebar />
      <Title />
      <Messages messageSummaries={props.messageSummaries} />
      <RightSidebar />
    </div>
  );
}

function Title() {
  return (
    <div className="Notification-Title">
      <h1>알림</h1>
    </div>
  );
}

function Messages(props: { messageSummaries: MessageSummaryState[] }) {
  const messages = props.messageSummaries.map((item, _) => {
    if (item.messageType === "comment") {
      return <Comment messageSummary={item} />;
    } else {
      return <Reply messageSummary={item} />;
    }
  });

  return <div className="Notification-Messages">{messages}</div>;
}

function Comment(props: { messageSummary: MessageSummaryState }) {
  const navigate = useNavigate();

  const onClick = () => {
    navigate("/notification_view", {
      state: {
        messageType: props.messageSummary.messageType,
        documentId: props.messageSummary.documentId,
        commentId: props.messageSummary.commentId,
        replyId: props.messageSummary.replyId,
      },
    });
    navigate(0);
  };

  return (
    <div className="Notification-Comment">
      <Avatar messageSummary={props.messageSummary} />
      <button onClick={onClick}>
        {props.messageSummary.authorNickname}님이 회원님의 게시글에 댓글을
        남겼습니다.
      </button>
      <Time messageSummary={props.messageSummary} />
    </div>
  );
}

function Reply(props: { messageSummary: MessageSummaryState }) {
  const navigate = useNavigate();

  const onClick = () => {
    navigate("/notification_view", {
      state: {
        messageType: props.messageSummary.messageType,
        documentId: props.messageSummary.documentId,
        commentId: props.messageSummary.commentId,
        replyId: props.messageSummary.replyId,
      },
    });
    navigate(0);
  };

  return (
    <div className="Notification-Reply">
      <Avatar messageSummary={props.messageSummary} />
      <button onClick={onClick}>
        {props.messageSummary.authorNickname}님이 회원님의 댓글에 답글을
        남겼습니다.
      </button>
      <Time messageSummary={props.messageSummary} />
    </div>
  );
}

function Avatar(props: { messageSummary: MessageSummaryState }) {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(`/profile_view/${props.messageSummary.authorUid}`);
  };

  if (props.messageSummary.authorAvatarUrl !== "") {
    return (
      <div className="Notification-Avatar">
        <img src={props.messageSummary.authorAvatarUrl} onClick={onClick} />
      </div>
    );
  } else {
    return (
      <div className="Notification-Avatar">
        <img src={defaultAvatar} onClick={onClick} />
      </div>
    );
  }
}

function Time(props: { messageSummary: MessageSummaryState }) {
  const endTimestamp = Date.now();

  const beginDate = moment(props.messageSummary.timestamp);
  const endDate = moment(endTimestamp);
  const diff = endDate.diff(beginDate);
  const diffDuration = moment.duration(diff);

  if (diffDuration.minutes() < 1) {
    return (
      <div className="Notification-Time">
        <p>방금 전</p>
      </div>
    );
  } else if (diffDuration.hours() < 1) {
    return (
      <div className="Notification-Time">
        <p>{diffDuration.minutes()} 분 전</p>
      </div>
    );
  } else if (diffDuration.days() < 1) {
    return (
      <div className="Notification-Time">
        <p>{diffDuration.hours()} 시간 전</p>
      </div>
    );
  } else {
    return (
      <div className="Notification-Time">
        <p>{beginDate.format("YYYY-MM-DD")}</p>
      </div>
    );
  }
}
