import sidebarBase from "../asset/image/sidebar_base.png";
import sidebarHome from "../asset/image/sidebar_home.png";
import sidebarSearch from "../asset/image/sidebar_search.png";
import sidebarPlus from "../asset/image/sidebar_plus.png";
import sidebarBell from "../asset/image/sidebar_bell.png";
import sidebarProfile from "../asset/image/sidebar_profile.png";
import "./sidebar.css";
import { useNavigate } from "react-router-dom";

export function Sidebar() {
  return (
    <div className="Sidebar">
      <SidebarBase />
      <SidebarHome />
      <SidebarSearch />
      <SidebarPlus />
      <SidebarBell />
      <SidebarProfile />
    </div>
  );
}

function SidebarBase() {
  return (
    <div className="Sidebar-Base">
      <img src={sidebarBase} />
    </div>
  );
}

function SidebarHome() {
  const navigate = useNavigate();

  const onClick = () => {
    navigate("/");
  };

  return (
    <div className="Sidebar-Home">
      <img src={sidebarHome} onClick={onClick} />
    </div>
  );
}

function SidebarSearch() {
  const navigate = useNavigate();

  const onClick = () => {
    navigate("/search");
  };

  return (
    <div className="Sidebar-Search">
      <img src={sidebarSearch} onClick={onClick} />
    </div>
  );
}

function SidebarPlus() {
  const navigate = useNavigate();

  const onClick = () => {
    navigate("/write");
  };

  return (
    <div className="Sidebar-Plus">
      <img src={sidebarPlus} onClick={onClick} />
    </div>
  );
}

function SidebarBell() {
  const navigate = useNavigate();

  const onClick = () => {
    navigate("/notification");
  };

  return (
    <div className="Sidebar-Bell">
      <img src={sidebarBell} onClick={onClick} />
    </div>
  );
}

function SidebarProfile() {
  const navigate = useNavigate();

  const onClick = () => {
    navigate("/profile");
  };

  return (
    <div className="Sidebar-Profile">
      <img src={sidebarProfile} onClick={onClick} />
    </div>
  );
}
