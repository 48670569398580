import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DocumentState } from "./document";

export interface FollowingState {
  followings: string[];
  documents: DocumentState[];
}

const initialState: FollowingState = {
  followings: [],
  documents: [],
};

export const following = createSlice({
  name: "following",
  initialState,
  reducers: {
    resetFollowings(state) {
      state.followings = [];
    },

    appendFollowing(state, action: PayloadAction<string>) {
      state.followings = [...state.followings, action.payload];
    },

    resetDocuments(state) {
      state.documents = [];
    },

    appendDocument(state, action: PayloadAction<DocumentState>) {
      const isEqualTimestamp = (document: DocumentState) =>
        document.timestamp === action.payload.timestamp;

      if (state.documents.findIndex(isEqualTimestamp) >= 0) {
        return;
      } else {
        state.documents = [...state.documents, action.payload].sort((a, b) =>
          a.timestamp < b.timestamp ? 1 : -1,
        );
      }
    },
  },
});

export const followingActions = following.actions;
export default following.reducer;
