import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserState } from "./user";

export interface BlockState {
  blockings: UserState[];
}

const initialState: BlockState = {
  blockings: [],
};

export const block = createSlice({
  name: "block",
  initialState,
  reducers: {
    resetBlockings(state) {
      state.blockings = [];
    },

    appendBlocking(state, action: PayloadAction<UserState>) {
      const isEqualTimestamp = (blocking: UserState) =>
        blocking.timestamp === action.payload.timestamp;

      if (state.blockings.findIndex(isEqualTimestamp) >= 0) {
        return;
      } else {
        state.blockings = [...state.blockings, action.payload].sort((a, b) =>
          a.timestamp < b.timestamp ? 1 : -1,
        );
      }
    },
  },
});

export const blockActions = block.actions;
export default block.reducer;
