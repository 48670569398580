import logo from "../asset/image/logo.png";
import menu from "../asset/image/menu.png";
import underscore from "../asset/image/underscore.png";
import { useNavigate } from "react-router-dom";
import "./header.css";
import { getAuth, signOut } from "firebase/auth";
import { coreActions } from "../module/core";
import { useAppDispatch, useAppSelector } from "../module/hook";
import React, { useEffect, useState } from "react";
import gRankingTransparent from "../asset/image/g_ranking_transparent.png";
import gCoinTransparent from "../asset/image/g_coin_transparent.png";
import logout from "../asset/image/logout.png";
import block from "../asset/image/block.png";
import defaultAvatar from "../asset/image/default_avatar.png";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { HandFist } from "@phosphor-icons/react";

export function Header() {
  return (
    <div className="Header">
      <Logo />
      <Navigation />
      <Action />
    </div>
  );
}

function Logo() {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const onClick = () => {
    dispatch(coreActions.setFocus("recommendation"));

    navigate("/");
  };

  return (
    <div className="Header-Logo">
      <img src={logo} onClick={onClick} />
    </div>
  );
}

function Navigation() {
  return (
    <div className="Header-Navigation">
      <Recommendation />
      <Following />
      <Bookmark />
      <Profile />
    </div>
  );
}

function Recommendation() {
  const navigate = useNavigate();

  const focus = useAppSelector((state) => state.core.focus);

  const dispatch = useAppDispatch();

  const onClick = () => {
    dispatch(coreActions.setFocus("recommendation"));

    navigate("/");
  };

  if (focus === "recommendation") {
    return (
      <div className="Header-Recommendation-Focus">
        <button onClick={onClick}>추천</button>
        <img src={underscore} />
      </div>
    );
  } else {
    return (
      <div className="Header-Recommendation">
        <button onClick={onClick}>추천</button>
      </div>
    );
  }
}

function Following() {
  const navigate = useNavigate();

  const focus = useAppSelector((state) => state.core.focus);

  const dispatch = useAppDispatch();

  const onClick = () => {
    dispatch(coreActions.setFocus("following"));

    navigate("/following");
  };

  if (focus === "following") {
    return (
      <div className="Header-Following-Focus">
        <button onClick={onClick}>팔로잉</button>
        <img src={underscore} />
      </div>
    );
  } else {
    return (
      <div className="Header-Following">
        <button onClick={onClick}>팔로잉</button>
      </div>
    );
  }
}

function Bookmark() {
  const navigate = useNavigate();

  const focus = useAppSelector((state) => state.core.focus);

  const dispatch = useAppDispatch();

  const onClick = () => {
    dispatch(coreActions.setFocus("bookmark"));

    navigate("/bookmark");
  };

  if (focus === "bookmark") {
    return (
      <div className="Header-Bookmark-Focus">
        <button onClick={onClick}>북마크</button>
        <img src={underscore} />
      </div>
    );
  } else {
    return (
      <div className="Header-Bookmark">
        <button onClick={onClick}>북마크</button>
      </div>
    );
  }
}

function Profile() {
  const navigate = useNavigate();

  const focus = useAppSelector((state) => state.core.focus);

  const dispatch = useAppDispatch();

  const onClick = () => {
    dispatch(coreActions.setFocus("profile"));

    navigate("/profile");
  };

  if (focus === "profile") {
    return (
      <div className="Header-Profile-Focus">
        <button onClick={onClick}>프로필</button>
        <img src={underscore} />
      </div>
    );
  } else {
    return (
      <div className="Header-Profile">
        <button onClick={onClick}>프로필</button>
      </div>
    );
  }
}

function Action() {
  const isAdmin = useAppSelector((state) => state.core.isAdmin);

  const auth = getAuth();
  const firestore = getFirestore();

  const dispatch = useAppDispatch();

  const checkAdmin = async () => {
    dispatch(coreActions.setIsAdmin(false));

    if (auth.currentUser !== null) {
      const userDocRef = doc(firestore, "admin", auth.currentUser.uid);
      const userDocSnap = await getDoc(userDocRef);

      if (
        userDocSnap.exists() &&
        userDocSnap.data()!.uid === auth.currentUser.uid
      ) {
        dispatch(coreActions.setIsAdmin(true));
      } else {
        dispatch(coreActions.setIsAdmin(false));
      }
    } else {
      dispatch(coreActions.setIsAdmin(false));
    }
  };

  useEffect(() => {
    checkAdmin();
  }, []);

  if (isAdmin) {
    return (
      <div className="Header-Action">
        <Fist />
        <Menu />
        <LoginOrAvatar />
      </div>
    );
  } else {
    return (
      <div className="Header-Action">
        <Menu />
        <LoginOrAvatar />
      </div>
    );
  }
}

function Fist() {
  const navigate = useNavigate();

  const onClick = () => {
    navigate("/admin");
  };

  return (
    <div className="Header-Fist">
      <button onClick={onClick}>
        <HandFist size={24} />
      </button>
    </div>
  );
}

function Menu() {
  const auth = getAuth();

  const [click, setClick] = useState(false);

  const onClick = () => {
    if (click) {
      setClick(false);
    } else {
      setClick(true);
    }
  };

  if (click) {
    if (auth.currentUser !== null) {
      return (
        <div className="Header-Menu">
          <img src={menu} onClick={onClick} />
          <div className="Header-MenuFocusLogout">
            <Ranking />
            <Coin />
            <Block />
            <Logout />
          </div>
        </div>
      );
    } else {
      return (
        <div className="Header-Menu">
          <img src={menu} onClick={onClick} />
          <div className="Header-MenuFocus">
            <Ranking />
            <Coin />
            <Block />
          </div>
        </div>
      );
    }
  } else {
    return (
      <div className="Header-Menu">
        <img src={menu} onClick={onClick} />
      </div>
    );
  }
}

function Ranking() {
  const navigate = useNavigate();

  const onClick = () => {
    navigate("/ranking");
  };

  return (
    <div className="Header-Ranking">
      <img src={gRankingTransparent} onClick={onClick} />
    </div>
  );
}

function Coin() {
  const navigate = useNavigate();

  const onClick = () => {
    navigate("/coin");
  };

  return (
    <div className="Header-Coin">
      <img src={gCoinTransparent} onClick={onClick} />
    </div>
  );
}

function Block() {
  const navigate = useNavigate();

  const onClick = () => {
    navigate("/block");
  };

  return (
    <div className="Header-Block">
      <button onClick={onClick}>
        <img src={block} />
        <p>차단된 프로필</p>
      </button>
    </div>
  );
}

function Logout() {
  const navigate = useNavigate();

  const auth = getAuth();

  const onClick = () => {
    const answer = window.confirm("로그아웃 하시겠습니까?");

    if (answer && auth.currentUser !== null) {
      signOut(auth);
      navigate("/");
      navigate(0);
    }
  };

  return (
    <div className="Header-Logout">
      <button onClick={onClick}>
        <img src={logout} />
        <p>로그아웃</p>
      </button>
    </div>
  );
}

function LoginOrAvatar() {
  const navigate = useNavigate();

  const auth = getAuth();
  const firestore = getFirestore();

  const [avatarUrl, setAvatarUrl] = useState("");

  useEffect(() => {
    if (auth.currentUser !== null) {
      const docRef = doc(firestore, "user", auth.currentUser.uid);

      getDoc(docRef).then((docSnap) => {
        const data = docSnap.data();
        setAvatarUrl(data!.avatarUrl || "");
      });
    }
  }, [auth.currentUser]);

  const onClick = () => {
    if (auth.currentUser !== null) {
      navigate("/profile");
    } else {
      navigate("/login");
    }
  };

  if (auth.currentUser !== null) {
    if (avatarUrl !== "") {
      return (
        <div className="Header-Avatar">
          <img src={avatarUrl} onClick={onClick} />
        </div>
      );
    } else {
      return (
        <div className="Header-Avatar">
          <img src={defaultAvatar} onClick={onClick} />
        </div>
      );
    }
  } else {
    return (
      <div className="Header-LoginOrAvatar">
        <button onClick={onClick}>로그인</button>
      </div>
    );
  }
}
