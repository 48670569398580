import { reply, ReplyState } from "../../module/reply";
import { CommentState } from "../../module/comment";
import { DocumentState } from "../../module/document";
import { reportViewActions } from "../../module/report_view";
import { coreActions } from "../../module/core";
import { useAppDispatch, useAppSelector } from "../../module/hook";
import {
  deleteDoc,
  doc,
  getDoc,
  getFirestore,
  increment,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { useLocation, useNavigate } from "react-router-dom";
import heart from "../../asset/image/heart.png";
import defaultAvatar from "../../asset/image/default_avatar.png";
import React, { useEffect, useState } from "react";
import "./report_view.css";
import moment from "moment";
import { getAuth } from "firebase/auth";
import trash from "../../asset/image/trash.png";
import comment from "../../asset/image/comment.png";
import hashtag from "../../asset/image/hashtag.png";
import { Loading } from "../loading/loading";
import { AdminHeader } from "../admin_header";
import parse from "html-react-parser";
import upFocus from "../../asset/image/up_focus.png";
import up from "../../asset/image/up.png";
import down from "../../asset/image/down.png";
import downFocus from "../../asset/image/down_focus.png";
import more from "../../asset/image/more.png";
import comingSoon from "../../asset/image/coming_soon.png";

export function ReportView() {
  const location = useLocation();

  const firestore = getFirestore();

  const isLoading = useAppSelector((state) => state.core.isLoading);

  const dispatch = useAppDispatch();

  const getDocument = async (documentId: string) => {
    const docRef = doc(firestore, "board", documentId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data();

      let authorAvatarUrl = "";
      let authorNickname = "";

      const userDocRef = doc(firestore, "user", docSnap.data()!.authorUid);
      const userDocSnap = await getDoc(userDocRef);
      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();

        authorAvatarUrl = userData.avatarUrl || "";
        authorNickname = userData.nickname || "";
      }

      const document: DocumentState = {
        documentId: documentId,
        authorUid: data.authorUid || "",
        authorAvatarUrl: authorAvatarUrl,
        authorNickname: authorNickname,
        timestamp: data.timestamp || 0,
        content: data.content || "",
        thumbnailContent: [],
        thumbnailImageSizes: [],
        hashtags: data.hashtags.split(","),
        numUps: data.numUps || 0,
        numDowns: data.numDowns || 0,
        numComments: data.numComments || 0,
        numTokens: data.numTokens || 0,
      };

      return document;
    } else {
      return null;
    }
  };

  const getComment = async (documentId: string, commentId: string) => {
    const docRef = doc(firestore, "board", documentId, "comment", commentId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data();

      let authorAvatarUrl = "";
      let authorNickname = "";

      const userDocRef = doc(firestore, "user", docSnap.data()!.authorUid);
      const userDocSnap = await getDoc(userDocRef);
      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();

        authorAvatarUrl = userData.avatarUrl || "";
        authorNickname = userData.nickname || "";
      }

      const comment: CommentState = {
        commentId: commentId,
        authorUid: data.authorUid || "",
        authorAvatarUrl: authorAvatarUrl,
        authorNickname: authorNickname,
        timestamp: data.timestamp || 0,
        content: data.content || "",
        numUps: data.numUps || 0,
        numDowns: data.numDowns || 0,
        numReplies: data.numReplies || 0,
        numTokens: data.numTokens || 0,
      };

      return comment;
    } else {
      return null;
    }
  };

  const getReply = async (
    documentId: string,
    commentId: string,
    replyId: string,
  ) => {
    const docRef = doc(
      firestore,
      "board",
      documentId,
      "comment",
      commentId,
      "reply",
      replyId,
    );
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data();

      let authorAvatarUrl = "";
      let authorNickname = "";

      const userDocRef = doc(firestore, "user", docSnap.data()!.authorUid);
      const userDocSnap = await getDoc(userDocRef);
      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();

        authorAvatarUrl = userData.avatarUrl || "";
        authorNickname = userData.nickname || "";
      }

      const reply: ReplyState = {
        replyId: replyId,
        authorUid: data.authorUid || "",
        authorAvatarUrl: authorAvatarUrl,
        authorNickname: authorNickname,
        timestamp: data.timestamp || 0,
        content: data.content || "",
        numUps: data.numUps || 0,
        numDowns: data.numDowns || 0,
      };

      return reply;
    } else {
      return null;
    }
  };

  const getMessage = async () => {
    if (location.state.commentId === "" && location.state.replyId === "") {
      const document = await getDocument(location.state.documentId);

      if (document !== null) {
        dispatch(reportViewActions.setDocument(document));
      }
    } else if (location.state.replyId === "") {
      const comment = await getComment(
        location.state.documentId,
        location.state.commentId,
      );

      if (comment !== null) {
        dispatch(reportViewActions.setComment(comment));
      }
    } else {
      const reply = await getReply(
        location.state.documentId,
        location.state.commentId,
        location.state.replyId,
      );

      if (reply !== null) {
        dispatch(reportViewActions.setReply(reply));
      }
    }
  };

  useEffect(() => {
    dispatch(reportViewActions.setReportId(location.state.reportId));
    dispatch(reportViewActions.setDocumentId(location.state.documentId));
    dispatch(reportViewActions.setCommentId(location.state.commentId));
    dispatch(reportViewActions.setReplyId(location.state.replyId));
    getMessage();
  }, []);

  return <div className="ReportView">{isLoading ? <Loading /> : <Body />}</div>;
}

function Body() {
  return (
    <div className="ReportView-Body">
      <AdminHeader />
      <Main />
    </div>
  );
}

function Main() {
  const documentId = useAppSelector((state) => state.reportView.documentId);
  const commentId = useAppSelector((state) => state.reportView.commentId);
  const replyId = useAppSelector((state) => state.reportView.replyId);

  if (commentId === "" && replyId === "") {
    return (
      <div className="ReportView-Main">
        <Document />
      </div>
    );
  } else if (replyId === "") {
    return (
      <div className="ReportView-Main">
        <Document />
      </div>
    );
  } else {
    return (
      <div className="ReportView-Main">
        <Document />
      </div>
    );
  }
}

function Document() {
  const document = useAppSelector((state) => state.reportView.document);

  if (
    document !== null &&
    document!.hashtags.length > 0 &&
    document!.hashtags[0] !== ""
  ) {
    return (
      <div className="ReportView-Document">
        <DocumentHeader />
        <DocumentContent />
        <Hashtags />
        <DocumentSummary />
      </div>
    );
  } else {
    return (
      <div className="ReportView-Document">
        <DocumentHeader />
        <DocumentContent />
        <DocumentSummary />
      </div>
    );
  }
}

function DocumentHeader() {
  return (
    <div className="ReportView-DocumentHeader">
      <DocumentAvatar />
      <DocumentNickname />
      <DocumentTime />
      <DocumentTrash />
    </div>
  );
}

function DocumentAvatar() {
  const document = useAppSelector((state) => state.reportView.document);

  const navigate = useNavigate();

  const onClick = () => {
    navigate(`/profile_view/${document!.authorUid}`);
  };

  if (document !== null && document!.authorAvatarUrl !== "") {
    return (
      <div className="ReportView-DocumentAvatar">
        <img src={document!.authorAvatarUrl} onClick={onClick} />
      </div>
    );
  } else {
    return (
      <div className="ReportView-DocumentAvatar">
        <img src={defaultAvatar} onClick={onClick} />
      </div>
    );
  }
}

function DocumentNickname() {
  const navigate = useNavigate();

  const document = useAppSelector((state) => state.reportView.document);

  const onClick = () => {
    navigate(`/profile_view/${document!.authorUid}`);
  };

  if (document !== null && document!.authorUid !== "") {
    return (
      <div className="ReportView-DocumentNickname">
        <button onClick={onClick}>{document!.authorNickname}</button>
      </div>
    );
  } else {
    return (
      <div className="ReportView-DocumentNickname">
        <button onClick={onClick}>닉네임 없음</button>
      </div>
    );
  }
}

function DocumentTime() {
  const document = useAppSelector((state) => state.reportView.document);

  const endTimestamp = Date.now();

  let beginDate;
  if (document !== null) {
    beginDate = moment(document!.timestamp);
  } else {
    beginDate = moment(0);
  }

  const endDate = moment(endTimestamp);
  const diff = endDate.diff(beginDate);
  const diffDuration = moment.duration(diff);

  if (diffDuration.minutes() < 1) {
    return (
      <div className="ReportView-DocumentTime">
        <p>방금 전</p>
      </div>
    );
  } else if (diffDuration.hours() < 1) {
    return (
      <div className="ReportView-DocumentTime">
        <p>{diffDuration.minutes()} 분 전</p>
      </div>
    );
  } else if (diffDuration.days() < 1) {
    return (
      <div className="ReportView-DocumentTime">
        <p>{diffDuration.hours()} 시간 전</p>
      </div>
    );
  } else {
    return (
      <div className="ReportView-DocumentTime">
        <p>{beginDate.format("YYYY-MM-DD")}</p>
      </div>
    );
  }
}

function DocumentTrash() {
  const navigate = useNavigate();

  const reportId = useAppSelector((state) => state.reportView.reportId);
  const document = useAppSelector((state) => state.reportView.document);

  const firestore = getFirestore();

  const dispatch = useAppDispatch();

  const onClick = async () => {
    if (document !== null) {
      const answer = window.confirm("정말 삭제하시겠습니까?");

      if (answer) {
        dispatch(coreActions.setIsLoading(true));

        const docRef = doc(firestore, "board", document.documentId);
        await deleteDoc(docRef);

        const metadataRef = doc(firestore, "metadata", document.documentId);
        await deleteDoc(metadataRef);

        const userRef = doc(firestore, "user", document.authorUid);
        await updateDoc(userRef, {
          numPosts: increment(-1),
        });

        const postRef = doc(
          firestore,
          "user",
          document.authorUid,
          "post",
          document.documentId,
        );
        await deleteDoc(postRef);

        for (const hashtag of document.hashtags) {
          if (hashtag !== "") {
            const hashtagRef = doc(
              firestore,
              "hashtag",
              hashtag,
              "post",
              document.documentId,
            );

            await deleteDoc(hashtagRef);
          }
        }

        const reportRef = doc(firestore, "report", reportId);
        await deleteDoc(reportRef);

        navigate("/admin");

        dispatch(coreActions.setIsLoading(false));
      }
    }
  };

  return (
    <div className="ReportView-DocumentTrash">
      <button onClick={onClick}>
        <img src={trash} />
      </button>
    </div>
  );
}

function DocumentContent() {
  const document = useAppSelector((state) => state.reportView.document);

  let parsedContent;
  if (document !== null) {
    parsedContent = parse(document!.content);
  } else {
    parsedContent = parse("");
  }

  return <div className="Read-Content">{parsedContent}</div>;
}

function Hashtags() {
  const document = useAppSelector((state) => state.reportView.document);

  return (
    <div className="ReportView-Hashtags">
      {
        <ul>
          {document!.hashtags.map((hashtag, index) => (
            <li key={index}>
              <Hashtag hashtag={hashtag} />
            </li>
          ))}
        </ul>
      }
    </div>
  );
}

function Hashtag(props: { hashtag: string }) {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(`/search_query/${props.hashtag.slice(1)}`);
  };

  return (
    <div className="ReportView-Hashtag">
      <img src={hashtag} />
      <button className="ReportView-Hashtag-tag" onClick={onClick}>
        {props.hashtag.slice(1)}
      </button>
    </div>
  );
}

function DocumentSummary() {
  return (
    <div className="ReportView-DocumentSummary">
      <DocumentNumUps />
      <DocumentNumDowns />
      <DocumentNumComments />
      <DocumentNumTokens />
    </div>
  );
}

function DocumentNumUps() {
  const navigate = useNavigate();

  const documentId = useAppSelector((state) => state.reportView.documentId);

  const [numUps, setNumUps] = useState(0);
  const [click, setClick] = useState(false);

  const auth = getAuth();

  const firestore = getFirestore();

  useEffect(() => {
    if (documentId !== "") {
      const metadataDocRef = doc(firestore, "metadata", documentId);

      getDoc(metadataDocRef).then((docSnap) => {
        if (docSnap.exists() && docSnap.data().hasOwnProperty("numUps")) {
          setNumUps(docSnap.data()["numUps"]);
        }
      });
    }
  }, [documentId]);

  if (documentId !== "" && auth.currentUser !== null) {
    const upDocRef = doc(
      firestore,
      "metadata",
      documentId,
      "up",
      auth.currentUser.uid,
    );

    getDoc(upDocRef).then((docSnap) => {
      if (docSnap.exists()) {
        setClick(true);
      }
    });

    const summaryDocRef = doc(firestore, "metadata", documentId);

    const onClick = async () => {
      if (click) {
        setNumUps(numUps - 1);
        setClick(false);

        await deleteDoc(upDocRef);

        await updateDoc(summaryDocRef, {
          numUps: increment(-1),
        });
      } else {
        setNumUps(numUps + 1);
        setClick(true);

        const upTimestamp = Date.now();

        await setDoc(upDocRef, {
          uid: auth.currentUser!.uid,
          timestamp: upTimestamp,
        });

        await updateDoc(summaryDocRef, {
          numUps: increment(1),
        });
      }
    };

    if (click) {
      return (
        <div className="ReportView-DocumentNumUps-Focus">
          <button onClick={onClick}>
            <img src={upFocus} />
            <p>{numUps}</p>
          </button>
        </div>
      );
    } else {
      return (
        <div className="ReportView-DocumentNumUps">
          <button onClick={onClick}>
            <img src={up} />
            <p>{numUps}</p>
          </button>
        </div>
      );
    }
  } else {
    const onClick = () => {
      navigate("/login");
    };

    return (
      <div className="ReportView-DocumentNumUps">
        <button onClick={onClick}>
          <img src={up} />
          <p>{numUps}</p>
        </button>
      </div>
    );
  }
}

function DocumentNumDowns() {
  const navigate = useNavigate();

  const documentId = useAppSelector((state) => state.reportView.documentId);

  const [numDowns, setNumDowns] = useState(0);
  const [click, setClick] = useState(false);

  const auth = getAuth();

  const firestore = getFirestore();

  useEffect(() => {
    if (documentId !== "") {
      const metadataDocRef = doc(firestore, "metadata", documentId);

      getDoc(metadataDocRef).then((docSnap) => {
        if (docSnap.exists() && docSnap.data().hasOwnProperty("numDowns")) {
          setNumDowns(docSnap.data()["numDowns"]);
        }
      });
    }
  }, [documentId]);

  if (documentId !== "" && auth.currentUser !== null) {
    const downDocRef = doc(
      firestore,
      "metadata",
      documentId,
      "down",
      auth.currentUser.uid,
    );

    getDoc(downDocRef).then((docSnap) => {
      if (docSnap.exists()) {
        setClick(true);
      }
    });

    const metadataDocRef = doc(firestore, "metadata", documentId);

    const onClick = async () => {
      if (click) {
        setNumDowns(numDowns - 1);
        setClick(false);

        await deleteDoc(downDocRef);

        await updateDoc(metadataDocRef, {
          numDowns: increment(-1),
        });
      } else {
        setNumDowns(numDowns + 1);
        setClick(true);

        const upTimestamp = Date.now();

        await setDoc(downDocRef, {
          uid: auth.currentUser!.uid,
          timestamp: upTimestamp,
        });

        await updateDoc(metadataDocRef, {
          numDowns: increment(1),
        });
      }
    };

    if (click) {
      return (
        <div className="ReportView-DocumentNumDowns-Focus">
          <button onClick={onClick}>
            <img src={downFocus} />
            <p>{numDowns}</p>
          </button>
        </div>
      );
    } else {
      return (
        <div className="ReportView-DocumentNumDowns">
          <button onClick={onClick}>
            <img src={down} />
            <p>{numDowns}</p>
          </button>
        </div>
      );
    }
  } else {
    const onClick = () => {
      navigate("/login");
    };

    return (
      <div className="ReportView-DocumentNumDowns">
        <button onClick={onClick}>
          <img src={down} />
          <p>{numDowns}</p>
        </button>
      </div>
    );
  }
}

function DocumentNumComments() {
  const navigate = useNavigate();

  const documentId = useAppSelector((state) => state.reportView.documentId);

  const [numComments, setNumComments] = useState(0);

  const firestore = getFirestore();

  useEffect(() => {
    if (documentId !== "") {
      const metadataDocRef = doc(firestore, "metadata", documentId);
      getDoc(metadataDocRef).then((docSnap) => {
        if (docSnap.exists() && docSnap.data().hasOwnProperty("numComments")) {
          setNumComments(docSnap.data()["numComments"]);
        }
      });
    }
  }, [documentId]);

  const onClick = () => {
    navigate(`/read/${documentId}`);
    navigate(0);
  };

  return (
    <div className="ReportView-DocumentNumComments">
      <button onClick={onClick}>
        <img src={comment} />
        <p>{numComments}</p>
      </button>
    </div>
  );
}

function DocumentNumTokens() {
  const [numTokens, setNumTokens] = useState(0);

  return (
    <div className="ReportView-DocumentNumTokens">
      <button>
        <p>TOKEN</p>
        <ComingSoon />
      </button>
    </div>
  );
}

function ComingSoon() {
  return (
    <div className="ReportView-ComingSoon">
      <button>
        <img src={comingSoon} />
      </button>
    </div>
  );
}
