import { useAppDispatch, useAppSelector } from "../../module/hook";
import { Loading } from "../loading/loading";
import { Header } from "../header";
import { loginActions } from "../../module/login";
import { useNavigate } from "react-router-dom";
import { coreActions } from "../../module/core";
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";
import "./login.css";
import logo from "../../asset/image/logo.png";
import logoTransparent from "../../asset/image/logo_transparent.png";
import user from "../../asset/image/user.png";
import lock from "../../asset/image/lock.png";
import horizontalLine from "../../asset/image/horizontal_line.png";
import verticalLine from "../../asset/image/vertical_line.png";
import naver from "../../asset/image/naver.png";
import kakao from "../../asset/image/kakao.png";
import google from "../../asset/image/google.png";
import facebook from "../../asset/image/facebook.png";
import apple from "../../asset/image/apple.png";

export function Login() {
  const isLoading = useAppSelector((state) => state.core.isLoading);

  return <div className="Login">{isLoading ? <Loading /> : <Body />}</div>;
}

function Body() {
  return (
    <div className="Login-Body">
      <Header />
      <Title />
      <Background />
      <EmailInput />
      <PasswordInput />
      <Help />
      <SignIn />
      <SignUp />
      <EasySignInTitle />
      <EasySignIn />
    </div>
  );
}

function Title() {
  return (
    <div className="Login-Title">
      <img src={logo} />
    </div>
  );
}

function Background() {
  return (
    <div className="Login-Background">
      <img src={logoTransparent} />
    </div>
  );
}

function EmailInput() {
  const email = useAppSelector((state) => state.login.email);

  const dispatch = useAppDispatch();

  const onChange = (e: any) => {
    dispatch(loginActions.setEmail(e.target.value));
  };

  return (
    <div className="Login-Email">
      <img src={user} />
      <form>
        <input
          type="text"
          placeholder="이메일을 입력해주세요"
          required
          value={email}
          onChange={onChange}
        />
      </form>
    </div>
  );
}

function PasswordInput() {
  const password = useAppSelector((state) => state.login.password);

  const dispatch = useAppDispatch();

  const onChange = (e: any) => {
    dispatch(loginActions.setPassword(e.target.value));
  };

  return (
    <div className="Login-Password">
      <form>
        <img src={lock} />
        <input
          type="password"
          placeholder="비밀번호를 입력해주세요"
          required
          value={password}
          onChange={onChange}
        />
      </form>
    </div>
  );
}

function SignIn() {
  const auth = getAuth();

  const navigate = useNavigate();

  const email = useAppSelector((state) => state.login.email);
  const password = useAppSelector((state) => state.login.password);

  const dispatch = useAppDispatch();

  const onClick = () => {
    dispatch(coreActions.setIsLoading(true));

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;

        if (user.emailVerified) {
          dispatch(coreActions.setIsLoading(false));

          navigate("/");
        } else {
          dispatch(coreActions.setIsLoading(false));

          signOut(auth);

          alert("이메일 인증을 완료해주세요");
        }
      })
      .catch((error) => {
        const errorCode = error.code;

        console.log(errorCode);

        if (errorCode === "auth/invalid-credential") {
          alert(
            "해당 유저를 찾을 수 없습니다. 아이디와 비밀번호를 다시 확인해주세요.",
          );
        } else if (errorCode === "auth/invalid-email") {
          alert("이메일 주소가 올바르지 않습니다.");
        } else if (errorCode === "auth/missing-password") {
          alert("비밀번호를 입력해주세요.");
        } else {
          alert("서버와의 통신에 실패하였습니다.");
        }

        dispatch(coreActions.setIsLoading(false));
      });
  };

  return (
    <div className="Login-SignIn">
      <button onClick={onClick}>로그인</button>
    </div>
  );
}

function Help() {
  return (
    <div className="Login-Help">
      <EmailVerification />
      <img src={verticalLine} />
      <PasswordReset />
    </div>
  );
}

function EmailVerification() {
  const navigate = useNavigate();

  const onClick = () => {
    navigate("/email_verification");
  };

  return (
    <div className="Login-EmailVerification">
      <button onClick={onClick}>이메일 재인증</button>
    </div>
  );
}

function PasswordReset() {
  const navigate = useNavigate();

  const onClick = () => {
    navigate("/password_reset");
  };

  return (
    <div className="Login-PasswordReset">
      <button onClick={onClick}>비밀번호 재설정</button>
    </div>
  );
}

function SignUp() {
  const navigate = useNavigate();

  const onClick = () => {
    navigate("/eula");
  };

  return (
    <div className="Login-SignUp">
      <p>처음이신가요?</p>
      <button onClick={onClick}>회원 가입</button>
    </div>
  );
}

function EasySignInTitle() {
  return (
    <div className="Login-EasySignInTitle">
      <img src={horizontalLine} />
      <p>간편로그인</p>
      <img src={horizontalLine} />
    </div>
  );
}

function EasySignIn() {
  return (
    <div className="Login-EasySignIn">
      <Naver />
      <Kakao />
      <Google />
      <Facebook />
      <Apple />
    </div>
  );
}

function Naver() {
  const onClick = () => {};

  return (
    <div className="Login-Naver">
      <img src={naver} onClick={onClick} />
    </div>
  );
}

function Kakao() {
  const onClick = () => {};

  return (
    <div className="Login-Kakao">
      <img src={kakao} onClick={onClick} />
    </div>
  );
}

function Google() {
  const onClick = () => {};

  return (
    <div className="Login-Google">
      <img src={google} onClick={onClick} />
    </div>
  );
}

function Facebook() {
  const onClick = () => {};

  return (
    <div className="Login-Facebook">
      <img src={facebook} onClick={onClick} />
    </div>
  );
}

function Apple() {
  const onClick = () => {};

  return (
    <div className="Login-Apple">
      <img src={apple} onClick={onClick} />
    </div>
  );
}
