import { coreActions } from "../../module/core";
import { useAppDispatch, useAppSelector } from "../../module/hook";
import {
  doc,
  getFirestore,
  increment,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { Loading } from "../loading/loading";
import { useNavigate } from "react-router-dom";
import { useEffect, useMemo, useRef } from "react";
import "./write.css";
import { Header } from "../header";
import { Sidebar } from "../sidebar";
import {
  deleteObject,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
} from "firebase/storage";
import ReactQuill from "react-quill";
import { writeActions } from "../../module/write";
import { getAuth } from "firebase/auth";
import uuid from "react-uuid";
import hashtag from "../../asset/image/hashtag.png";
import LinearProgress from "@mui/material/LinearProgress";

export function Write() {
  const navigate = useNavigate();

  const isLoading = useAppSelector((state) => state.core.isLoading);

  const auth = getAuth();

  useEffect(() => {
    if (auth.currentUser === null) {
      navigate("/login");
    }
  }, []);

  return <div className="Write">{isLoading ? <Loading /> : <Body />}</div>;
}

function Body() {
  const quillRef = useRef<ReactQuill>(null);

  const content = useAppSelector((state) => state.write.content);

  const dispatch = useAppDispatch();

  const imageHandler = () => {
    const editor = quillRef.current!.getEditor();

    const storage = getStorage();

    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.setAttribute("multiple", "multiple");
    input.click();

    input.addEventListener("change", async () => {
      const range = editor.getSelection(true);
      try {
        if (input.files && range) {
          dispatch(writeActions.setIsImageLoading(true));

          const uploadPromises = Array.from(input.files).map(async (file) => {
            const fileId = `${Date.now()}-${uuid()}`;
            const storageRef = ref(storage, `image/${fileId}`);
            await uploadBytes(storageRef, file);
            const url = await getDownloadURL(storageRef);

            editor.insertEmbed(range.index, "image", url);
            editor.setSelection(range.index + 1, 0);
          });
          await Promise.all(uploadPromises);

          dispatch(writeActions.setIsImageLoading(false));
        }
      } catch (error) {
        const auth = getAuth();

        if (auth.currentUser) {
          alert("서버와의 통신에 실패하였습니다.");
        } else {
          alert("글 쓰기는 로그인하여야 가능합니다.");
        }

        dispatch(writeActions.setIsImageLoading(false));
      }
    });

    editor.on("text-change", async (_, oldDelta, source) => {
      if (source !== "user") {
        return;
      }

      const imageUrls = editor
        .getContents()
        .diff(oldDelta)
        .ops?.filter((i) => i.insert && i.insert.image)
        .map((i) => i.insert.image);

      for (let i = 0; i < imageUrls!.length; i++) {
        const imageUrl = imageUrls![i];

        const baseUrl =
          "https://firebasestorage.googleapis.com/v0/b/giggles-62f17.appspot.com/o/";

        let imagePath: string = imageUrl.replace(baseUrl, "");

        const indexOfEndPath = imagePath.indexOf("?");

        imagePath = imagePath.substring(0, indexOfEndPath);

        imagePath = imagePath.replace("%2F", "/");

        const storageRef = ref(storage, imagePath);

        getDownloadURL(storageRef)
          .then((_) => {
            deleteObject(storageRef);
          })
          .catch((_) => {});
      }
    });
  };

  const toolbarOptions = useMemo(
    () => ({
      toolbar: {
        container: [[], [{}], ["image", "video", "link"]],
        handlers: {
          image: imageHandler,
        },
      },
    }),
    [],
  );

  return (
    <div className="Write-Body">
      <Header />
      <Sidebar />
      <Title />
      <HashtagInput />
      <Hashtags />
      <ReactQuill
        className="Write-Quill"
        ref={quillRef}
        modules={toolbarOptions}
        value={content}
        onChange={(content) => dispatch(writeActions.setContent(content))}
      />
      <Submit />
    </div>
  );
}

function Title() {
  const isImageLoading = useAppSelector((state) => state.write.isImageLoading);

  if (isImageLoading) {
    return (
      <div className="Write-Title">
        <h1>게시글 작성</h1>
        <LinearProgress />
      </div>
    );
  } else {
    return (
      <div className="Write-Title">
        <h1>게시글 작성</h1>
      </div>
    );
  }
}

function HashtagInput() {
  const hashtags = useAppSelector((state) => state.write.hashtags);

  const dispatch = useAppDispatch();

  const addHashtag = (event: any) => {
    const inputVal = event.target.value;

    if (
      event.key === "Enter" &&
      inputVal !== "" &&
      !hashtags.includes(inputVal)
    ) {
      if (hashtags.length > 2) {
        alert("해시태그는 최대 3개까지만 지정할 수 있습니다.");

        event.target.value = "";
      } else {
        if (inputVal[0] !== "#") {
          if (inputVal.length < 10) {
            dispatch(writeActions.setHashtags([...hashtags, "#" + inputVal]));
            event.target.value = "";
          } else {
            alert("해시태그는 최대 10자까지 입력할 수 있습니다.");
          }
        } else {
          if (inputVal.length < 11) {
            dispatch(writeActions.setHashtags([...hashtags, inputVal]));
            event.target.value = "";
          } else {
            alert("해시태그는 최대 10자까지 입력할 수 있습니다.");
          }
        }
      }
    }
  };

  return (
    <div className="Write-HashtagInput">
      <img src={hashtag} />
      {
        <input
          type="text"
          onKeyUp={(e) => addHashtag(e)}
          placeholder="태그입력(10자 미만, 최대 3개)"
        />
      }
    </div>
  );
}

function Hashtags() {
  const hashtags = useAppSelector((state) => state.write.hashtags);

  const dispatch = useAppDispatch();

  const removeHashtag = (indexToRemove: any) => {
    const filter = hashtags.filter((_, index) => index !== indexToRemove);
    dispatch(writeActions.setHashtags(filter));
  };

  return (
    <div className="Write-Hashtags">
      {
        <ul>
          {hashtags.map((hashtag, index) => (
            <li key={index}>
              <span
                className="Write-Hashtags-tag"
                onClick={() => removeHashtag(index)}
              >
                {hashtag}
              </span>
            </li>
          ))}
        </ul>
      }
    </div>
  );
}

function Submit() {
  const navigate = useNavigate();

  const content = useAppSelector((state) => state.write.content);
  const hashtags = useAppSelector((state) => state.write.hashtags);

  const dispatch = useAppDispatch();

  const onClick = async () => {
    dispatch(coreActions.setIsLoading(true));

    const auth = getAuth();
    const firestore = getFirestore();

    const timestamp = Date.now();

    const id = uuid();

    const documentId = timestamp.toString() + "-" + id;

    await updateDoc(doc(firestore, "user", auth.currentUser!.uid), {
      numPosts: increment(1),
    });

    await setDoc(
      doc(firestore, "user", auth.currentUser!.uid, "post", documentId),
      {
        documentId: documentId,
        timestamp: timestamp,
      },
    );

    await setDoc(doc(firestore, "board", documentId), {
      documentId: documentId,
      authorUid: auth.currentUser!.uid,
      content: content,
      timestamp: timestamp,
      hashtags: hashtags.join(","),
    });

    await setDoc(doc(firestore, "metadata", documentId), {
      documentId: documentId,
      authorUid: auth.currentUser!.uid,
      timestamp: timestamp,
      numUps: 0,
      numDowns: 0,
      numComments: 0,
    });

    for (const hashtag of hashtags) {
      await setDoc(doc(firestore, "hashtag", hashtag, "post", documentId), {
        documentId: documentId,
        hashtag: hashtag,
        timestamp: timestamp,
      });
    }

    navigate("/");

    dispatch(coreActions.setIsLoading(false));
  };

  return (
    <div className="Write-Submit">
      <button onClick={onClick}>글 쓰기</button>
    </div>
  );
}
