import React from "react";
import "./App.css";
import { isMobile } from "react-device-detect";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Home } from "./desktop/home/home";
import { Write } from "./desktop/write/write";
import { Provider } from "react-redux";
import { store } from "./module";
import "./firebase";
import { Login } from "./desktop/login/login";
import { Profile } from "./desktop/profile/profile";
import { ProfileView } from "./desktop/profile_view/profile_view";
import { MobileLogin } from "./mobile/login/mobile_login";
import { Eula } from "./desktop/eula/eula";
import { MobileEula } from "./mobile/eula/mobile_eula";
import { Read } from "./desktop/read/read";
import { SignUp } from "./desktop/sign_up/sign_up";
import { PasswordReset } from "./desktop/password_reset/password_reset";
import { EmailVerification } from "./desktop/email_verification/email_verification";
import { Following } from "./desktop/following/following";
import { Bookmark } from "./desktop/bookmark/bookmark";
import { Search } from "./desktop/search/search";
import { SearchQuery } from "./desktop/search_query/search_query";
import { Notification } from "./desktop/notification/notification";
import { NotificationView } from "./desktop/notification_view/notification_view";
import { Block } from "./desktop/block/block";
import { Report } from "./desktop/report/report";
import { Admin } from "./desktop/admin/admin";
import { ReportView } from "./desktop/report_view/report_view";

function App() {
  if (!isMobile) {
    return (
      <BrowserRouter>
        <div className="App">
          <Provider store={store}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/following" element={<Following />} />
              <Route path="/bookmark" element={<Bookmark />} />
              <Route path="/read/:slug" element={<Read />} />
              <Route path="/login" element={<Login />} />
              <Route path="/eula" element={<Eula />} />
              <Route path="/sign_up" element={<SignUp />} />
              <Route path="/password_reset" element={<PasswordReset />} />
              <Route
                path="/email_verification"
                element={<EmailVerification />}
              />
              <Route path="/profile" element={<Profile />} />
              <Route path="/profile_view/:slug" element={<ProfileView />} />
              <Route path="/write" element={<Write />} />
              <Route path="/search" element={<Search />} />
              <Route path="/search_query/:slug" element={<SearchQuery />} />
              <Route path="/notification" element={<Notification />} />
              <Route path="/notification_view" element={<NotificationView />} />
              <Route path="/block" element={<Block />} />
              <Route path="/report" element={<Report />} />
              <Route path="/admin" element={<Admin />} />
              <Route path="/report_view" element={<ReportView />} />
            </Routes>
          </Provider>
        </div>
      </BrowserRouter>
    );
  } else {
    return (
      <BrowserRouter>
        <div className="App">
          <Provider store={store}>
            <Routes>
              <Route path="/login" element={<MobileLogin />} />
              <Route path="/eula" element={<MobileEula />} />
            </Routes>
          </Provider>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
