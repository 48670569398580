import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MessageState } from "./message";
import { ReplyState } from "./reply";

export interface NotificationViewState {
  documentId: string;
  commentId: string;
  replyId: string;
  message: MessageState;
  replies: ReplyState[];
}

const initialState: NotificationViewState = {
  documentId: "",
  commentId: "",
  replyId: "",
  message: {
    messageType: "",

    documentId: "",
    documentAuthorUid: "",
    documentAuthorAvatarUrl: "",
    documentAuthorNickname: "",
    documentTimestamp: 0,
    documentContent: "",
    documentThumbnailContent: [],
    documentThumbnailImageSizes: [],
    documentHashtags: [],
    documentNumUps: 0,
    documentNumDowns: 0,
    documentNumComments: 0,
    documentNumTokens: 0,

    commentId: "",
    commentAuthorUid: "",
    commentAuthorAvatarUrl: "",
    commentAuthorNickname: "",
    commentTimestamp: 0,
    commentContent: "",
    commentNumUps: 0,
    commentNumDowns: 0,

    replyId: "",
  },
  replies: [],
};

export const notificationView = createSlice({
  name: "notificationView",
  initialState,
  reducers: {
    setDocumentId(state, action: PayloadAction<string>) {
      state.documentId = action.payload;
    },

    setCommentId(state, action: PayloadAction<string>) {
      state.commentId = action.payload;
    },

    setReplyId(state, action: PayloadAction<string>) {
      state.replyId = action.payload;
    },

    resetMessage(state) {
      state.message = {
        messageType: "",

        documentId: "",
        documentAuthorUid: "",
        documentAuthorAvatarUrl: "",
        documentAuthorNickname: "",
        documentTimestamp: 0,
        documentContent: "",
        documentThumbnailContent: [],
        documentThumbnailImageSizes: [],
        documentHashtags: [],
        documentNumUps: 0,
        documentNumDowns: 0,
        documentNumComments: 0,
        documentNumTokens: 0,

        commentId: "",
        commentAuthorUid: "",
        commentAuthorAvatarUrl: "",
        commentAuthorNickname: "",
        commentTimestamp: 0,
        commentContent: "",
        commentNumUps: 0,
        commentNumDowns: 0,

        replyId: "",
      };
    },

    setMessage(state, action: PayloadAction<MessageState>) {
      state.message = action.payload;
    },

    resetReplies(state) {
      state.replies = [];
    },

    appendReply(state, action: PayloadAction<ReplyState>) {
      const isEqualTimestamp = (reply: ReplyState) =>
        reply.timestamp === action.payload.timestamp;

      if (state.replies.findIndex(isEqualTimestamp) >= 0) {
        return;
      } else {
        state.replies = [...state.replies, action.payload].sort((a, b) =>
          a.timestamp < b.timestamp ? 1 : -1,
        );
      }
    },
  },
});

export const notificationViewActions = notificationView.actions;
export default notificationView.reducer;
