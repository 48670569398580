import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import "./loading.css";

export function Loading() {
  return (
    <div className="Loading">
      <Box sx={{ display: "flex" }}>
        <CircularProgress />
      </Box>
    </div>
  );
}
