import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReportMessageState } from "./report_message";

export interface AdminState {
  focus: string;
  reportDocumentMessages: ReportMessageState[];
  reportCommentMessages: ReportMessageState[];
  reportReplyMessages: ReportMessageState[];
}

const initialState: AdminState = {
  focus: "document",
  reportDocumentMessages: [],
  reportCommentMessages: [],
  reportReplyMessages: [],
};

export const admin = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setFocus: (state, action: PayloadAction<string>) => {
      state.focus = action.payload;
    },

    resetReportDocumentMessages(state) {
      state.reportDocumentMessages = [];
    },

    appendReportDocumentMessage(
      state,
      action: PayloadAction<ReportMessageState>,
    ) {
      const isEqualTimestamp = (reportDocumentMessage: ReportMessageState) =>
        reportDocumentMessage.timestamp === action.payload.timestamp;

      if (state.reportDocumentMessages.findIndex(isEqualTimestamp) >= 0) {
        return;
      } else {
        state.reportDocumentMessages = [
          ...state.reportDocumentMessages,
          action.payload,
        ].sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1));
      }
    },

    resetReportCommentMessages(state) {
      state.reportCommentMessages = [];
    },

    appendReportCommentMessage(
      state,
      action: PayloadAction<ReportMessageState>,
    ) {
      const isEqualTimestamp = (reportCommentMessage: ReportMessageState) =>
        reportCommentMessage.timestamp === action.payload.timestamp;

      if (state.reportCommentMessages.findIndex(isEqualTimestamp) >= 0) {
        return;
      } else {
        state.reportCommentMessages = [
          ...state.reportCommentMessages,
          action.payload,
        ].sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1));
      }
    },

    resetReportReplyMessages(state) {
      state.reportReplyMessages = [];
    },

    appendReportReplyMessage(state, action: PayloadAction<ReportMessageState>) {
      const isEqualTimestamp = (reportReplyMessage: ReportMessageState) =>
        reportReplyMessage.timestamp === action.payload.timestamp;

      if (state.reportReplyMessages.findIndex(isEqualTimestamp) >= 0) {
        return;
      } else {
        state.reportReplyMessages = [
          ...state.reportReplyMessages,
          action.payload,
        ].sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1));
      }
    },
  },
});

export const adminActions = admin.actions;
export default admin.reducer;
