import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getFirestore,
  increment,
  limit,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  startAfter,
  updateDoc,
} from "firebase/firestore";
import { Sidebar } from "../sidebar";
import { getAuth } from "firebase/auth";
import "./read.css";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import moment from "moment/moment";
import parse from "html-react-parser";
import { DocumentState } from "../../module/document";
import { readActions } from "../../module/read";
import { CommentState } from "../../module/comment";
import { useAppDispatch, useAppSelector } from "../../module/hook";
import { Header } from "../header";
import defaultAvatar from "../../asset/image/default_avatar.png";
import heart from "../../asset/image/heart.png";
import { Loading } from "../loading/loading";
import { coreActions } from "../../module/core";
import uuid from "react-uuid";
import threeDots from "../../asset/image/three_dots.png";
import comment from "../../asset/image/comment.png";
import bookmark from "../../asset/image/bookmark.png";
import bookmarkFocus from "../../asset/image/bookmark_focus.png";
import reply from "../../asset/image/reply.png";
import { ReplyState } from "../../module/reply";
import back from "../../asset/image/back.png";
import hashtag from "../../asset/image/hashtag.png";
import { DotsThreeOutline } from "@phosphor-icons/react";
import horizontalLine from "../../asset/image/horizontal_line.png";
import upFocus from "../../asset/image/up_focus.png";
import up from "../../asset/image/up.png";
import down from "../../asset/image/down.png";
import downFocus from "../../asset/image/down_focus.png";
import comingSoon from "../../asset/image/coming_soon.png";
import more from "../../asset/image/more.png";

export function Read() {
  const { slug } = useParams();

  const navigate = useNavigate();

  const firestore = getFirestore();

  const isLoading = useAppSelector((state) => state.core.isLoading);
  const documents = useAppSelector((state) => state.read.documents);

  const dispatch = useAppDispatch();

  const [documentsLastVisible, setDocumentsLastVisible] =
    useState<any>(undefined);
  const [documentImages, setDocumentImages] = useState(new Map());

  async function reactiveLength(content: any) {
    if (Array.isArray(content) === true) {
      let length = 0;
      for (let child of content) {
        if (Array.isArray(child.props.children)) {
          length += await reactiveLength(child);
        } else {
          if (child.props.children && child.props.children.type === "img") {
            let img;
            if (documentImages.has(child.props.children.props.src)) {
              img = documentImages.get(child.props.children.props.src);
            } else {
              img = new Image();

              img.src = child.props.children.props.src;
              await img.decode();

              documentImages.set(child.props.children.props.src, img);
            }

            if (img.naturalHeight) {
              length += img.naturalHeight;
            } else {
              length += 1;
            }
          } else if (child.props.children && child.props.children.length) {
            length += child.props.children.length;
          } else {
            length += 1;
          }
        }
      }

      setDocumentImages(documentImages);
      return length;
    } else if (
      content.hasOwnProperty("props") &&
      content.props.hasOwnProperty("children") &&
      Array.isArray(content.props.children)
    ) {
      let length = 0;
      for (let child of content.props.children) {
        if (
          child.hasOwnProperty("props") &&
          child.props.hasOwnProperty("children") &&
          Array.isArray(child.props.children)
        ) {
          length += await reactiveLength(child);
        } else {
          if (child.type === "img") {
            let img;
            if (documentImages.has(child.props.src)) {
              img = documentImages.get(child.props.src);
            } else {
              img = new Image();

              img.src = child.props.src;
              await img.decode();

              documentImages.set(child.props.src, img);
            }

            if (img.naturalHeight) {
              length += img.naturalHeight;
            } else {
              length += 1;
            }
          }

          length += 1;
        }
      }

      setDocumentImages(documentImages);
      return length;
    } else if (
      content.hasOwnProperty("props") &&
      content.props.hasOwnProperty("children") &&
      content.props.children &&
      content.props.children.type === "img"
    ) {
      let img;
      if (documentImages.has(content.props.children.props.src)) {
        img = documentImages.get(content.props.children.props.src);
      } else {
        img = new Image();

        img.src = content.props.children.props.src;
        await img.decode();

        documentImages.set(content.props.children.props.src, img);
        setDocumentImages(documentImages);
      }

      if (img.naturalHeight) {
        return img.naturalHeight;
      } else {
        return 1;
      }
    } else {
      return 1;
    }
  }

  async function extractContent(
    content: any,
    pos: number,
    accum: number,
  ): Promise<any> {
    if (Array.isArray(content) === true) {
      for (let i = 0; i < content.length; i++) {
        const child = content[i];

        if (accum <= pos && accum + (await reactiveLength(child)) > pos) {
          return extractContent(child, pos, accum);
        } else if (accum > pos) {
          return;
        } else {
          accum += await reactiveLength(child);
        }
      }
    } else if (
      content.hasOwnProperty("props") &&
      content.props.hasOwnProperty("children") &&
      Array.isArray(content.props.children) === true
    ) {
      for (let i = 0; i < content.props.children.length; i++) {
        const child = content.props.children[i];

        if (accum <= pos && accum + (await reactiveLength(child)) > pos) {
          return extractContent(child, pos, accum);
        } else if (accum > pos) {
          return;
        } else {
          accum += await reactiveLength(child);
        }
      }
    } else {
      if (pos === accum) {
        return content;
      }
    }
  }

  const getDocuments = async () => {
    let q;
    if (documentsLastVisible === -1) {
      return;
    } else if (documentsLastVisible !== undefined) {
      q = query(
        collection(firestore, "board"),
        orderBy("timestamp", "desc"),
        limit(10),
        startAfter(documentsLastVisible),
      );
    } else {
      q = query(
        collection(firestore, "board"),
        orderBy("timestamp", "desc"),
        limit(10),
      );
    }

    onSnapshot(q, async (snapshot) => {
      const newDocuments = snapshot.docs.map((elem) => elem.data());

      for (const elem of newDocuments) {
        let authorAvatarUrl = "";
        let authorNickname = "";

        const userDocRef = doc(firestore, "user", elem.authorUid);

        const docSnap = await getDoc(userDocRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          authorAvatarUrl = data.avatarUrl || "";
          authorNickname = data.nickname || "";
        }

        const parsedContent = parse(elem.content);

        const lengthLimit = 700;
        const length = await reactiveLength(parsedContent);

        let thumbnailContent: any[] = [];
        let thumbnailImageSizes: any[] = [];

        if (length >= 100) {
          let index = 0;
          let extractLength = 0;

          let batchedContent = [];

          while (index < 5 && extractLength < lengthLimit) {
            let ec = await extractContent(parsedContent, index, 0);

            if (
              ec &&
              ec.props !== undefined &&
              ec.props.children !== undefined
            ) {
              if (ec.props.children.type === "img") {
                let img;
                if (documentImages.has(ec.props.children.props.src)) {
                  img = documentImages.get(ec.props.children.props.src);
                } else {
                  img = new Image();

                  img.src = ec.props.children.props.src;
                  await img.decode();

                  documentImages.set(ec.props.children.props.src, img);
                  setDocumentImages(documentImages);
                }

                if (img.naturalHeight) {
                  if (extractLength + img.naturalHeight > lengthLimit) {
                    thumbnailImageSizes = [
                      ...thumbnailImageSizes,
                      {
                        src: ec.props.children.props.src,
                        width: img.naturalWidth,
                        height: lengthLimit - extractLength,
                      },
                    ];

                    extractLength += lengthLimit - extractLength;
                  } else {
                    thumbnailImageSizes = [
                      ...thumbnailImageSizes,
                      {
                        src: ec.props.children.props.src,
                        width: img.naturalWidth,
                        height: img.naturalHeight,
                      },
                    ];

                    extractLength += img.naturalHeight;
                  }
                } else {
                  extractLength += 1;
                }
              } else if (ec.props.children.length) {
                extractLength += ec.props.children.length;
              } else {
                extractLength += 1;
              }

              batchedContent.push(ec);
            } else if (ec && ec.type === "img") {
              let img;
              if (documentImages.has(ec.props!.src)) {
                img = documentImages.get(ec.props.src);
              } else {
                img = new Image();

                img.src = ec.props.src;
                await img.decode();

                documentImages.set(ec.props.src, img);
                setDocumentImages(documentImages);
              }

              if (img.naturalHeight) {
                if (extractLength + img.naturalHeight > lengthLimit) {
                  thumbnailImageSizes = [
                    ...thumbnailImageSizes,
                    {
                      src: ec.props.src,
                      width: img.naturalWidth,
                      height: lengthLimit - extractLength,
                    },
                  ];

                  extractLength += lengthLimit - extractLength;
                } else {
                  thumbnailImageSizes = [
                    ...thumbnailImageSizes,
                    {
                      src: ec.props.src,
                      width: img.naturalWidth,
                      height: img.naturalHeight,
                    },
                  ];

                  extractLength += img.naturalHeight;
                }
              } else {
                extractLength += 1;
              }

              batchedContent.push(ec);
            } else if (ec) {
              extractLength += 1;

              batchedContent.push(ec);
            } else {
              extractLength += 1;
            }

            index += 1;
          }

          thumbnailContent = batchedContent;
        } else {
          thumbnailContent = [parsedContent];
        }

        const document: DocumentState = {
          documentId: elem.documentId,
          authorUid: elem.authorUid,
          authorAvatarUrl: authorAvatarUrl,
          authorNickname: authorNickname,
          timestamp: elem.timestamp,
          content: elem.content,
          thumbnailContent: thumbnailContent,
          thumbnailImageSizes: thumbnailImageSizes,
          hashtags: elem.hashtags.split(","),
          numUps: 0,
          numDowns: 0,
          numComments: 0,
          numTokens: 0,
        };

        dispatch(readActions.appendDocument(document));
      }

      if (snapshot.docs.length === 0) {
        setDocumentsLastVisible(-1);
      } else {
        setDocumentsLastVisible(snapshot.docs[snapshot.docs.length - 1]);
      }
    });
  };

  useBottomScrollListener(getDocuments);

  useEffect(() => {
    dispatch(readActions.resetDocument());
    setDocumentsLastVisible(undefined);
    dispatch(readActions.resetDocuments());

    const boardDocRef = doc(firestore, "board", slug!);
    getDoc(boardDocRef).then(async (docSnap) => {
      let authorAvatarUrl = "";
      let authorNickname = "";
      let numUps = 0;
      let numDowns = 0;
      let numComments = 0;
      let numTokens = 0;

      const userDocRef = doc(firestore, "user", docSnap.data()!.authorUid);

      await getDoc(userDocRef).then((userDocSnap) => {
        if (userDocSnap.exists()) {
          const data = userDocSnap.data();

          authorAvatarUrl = data.avatarUrl || "";
          authorNickname = data.nickname || "";
        }
      });

      const metadataDocRef = doc(firestore, "metadata", slug!);
      getDoc(metadataDocRef).then((docSnap) => {
        if (docSnap.exists() && docSnap.data().hasOwnProperty("numUps")) {
          numUps = docSnap.data()["numUps"];
        }

        if (docSnap.exists() && docSnap.data().hasOwnProperty("numDowns")) {
          numDowns = docSnap.data()["numDowns"];
        }

        if (docSnap.exists() && docSnap.data().hasOwnProperty("numComments")) {
          numComments = docSnap.data()["numComments"];
        }

        if (docSnap.exists() && docSnap.data().hasOwnProperty("numTokens")) {
          numTokens = docSnap.data()["numTokens"];
        }
      });

      const document: DocumentState = {
        documentId: docSnap.data()!.documentId,
        authorUid: docSnap.data()!.authorUid,
        authorAvatarUrl: authorAvatarUrl,
        authorNickname: authorNickname,
        timestamp: docSnap.data()!.timestamp,
        content: docSnap.data()!.content,
        thumbnailContent: [],
        thumbnailImageSizes: [],
        hashtags: docSnap.data()!.hashtags.split(","),
        numUps: numUps,
        numDowns: numDowns,
        numComments: numComments,
        numTokens: numTokens,
      };

      dispatch(readActions.setDocument(document));
    });

    getDocuments();
  }, [slug!]);

  return (
    <div className="Read">
      {isLoading ? <Loading /> : <Main documents={documents} />}
    </div>
  );
}

function Main(props: { documents: DocumentState[] }) {
  return (
    <div className="Read-Main">
      <Header />
      <Sidebar />
      <Title />
      <Body />
      <Split />
      <NextDocuments documents={props.documents} />
    </div>
  );
}

function Title() {
  const navigate = useNavigate();

  const onClick = () => {
    navigate("/");
  };

  return (
    <div className="Read-Title">
      <img src={back} onClick={onClick} />
      <h1>게시물</h1>
    </div>
  );
}

function Body() {
  return (
    <div className="Read-Body">
      <Document />
      <CommentInput />
      <Comments />
    </div>
  );
}

function Document() {
  const document = useAppSelector((state) => state.read.document);

  if (document.hashtags.length > 0 && document.hashtags[0] !== "") {
    return (
      <div className="Read-Document">
        <DocumentHeader />
        <Content />
        <Hashtags />
        <Summary />
      </div>
    );
  } else {
    return (
      <div className="Read-Document">
        <DocumentHeader />
        <Content />
        <Summary />
      </div>
    );
  }
}

function DocumentHeader() {
  return (
    <div className="Read-DocumentHeader">
      <Avatar />
      <Nickname />
      <Time />
      <Bookmark />
      <Report />
    </div>
  );
}

function Avatar() {
  const navigate = useNavigate();

  const authorUid = useAppSelector((state) => state.read.document.authorUid);
  const authorAvatarUrl = useAppSelector(
    (state) => state.read.document.authorAvatarUrl,
  );

  const onClick = () => {
    if (authorUid !== "") {
      navigate(`/profile_view/${authorUid}`);
    }
  };

  if (authorAvatarUrl !== "") {
    return (
      <div className="Read-Avatar">
        <img src={authorAvatarUrl} onClick={onClick} />
      </div>
    );
  } else {
    return (
      <div className="Read-Avatar">
        <img src={defaultAvatar} onClick={onClick} />
      </div>
    );
  }
}

function Nickname() {
  const navigate = useNavigate();

  const authorUid = useAppSelector((state) => state.read.document.authorUid);
  const authorNickname = useAppSelector(
    (state) => state.read.document.authorNickname,
  );

  const onClick = () => {
    if (authorUid !== "") {
      navigate(`/profile_view/${authorUid}`);
    }
  };

  if (authorNickname !== "") {
    return (
      <div className="Read-Nickname">
        <button onClick={onClick}>{authorNickname}</button>
      </div>
    );
  } else {
    return (
      <div className="Read-Nickname">
        <button onClick={onClick}>닉네임 없음</button>
      </div>
    );
  }
}

function Time() {
  const timestamp = useAppSelector((state) => state.read.document.timestamp);

  const endTimestamp = Date.now();

  const beginDate = moment(timestamp);
  const endDate = moment(endTimestamp);
  const diff = endDate.diff(beginDate);
  const diffDuration = moment.duration(diff);

  if (diffDuration.minutes() < 1) {
    return (
      <div className="Read-Time">
        <p>방금 전</p>
      </div>
    );
  } else if (diffDuration.hours() < 1) {
    return (
      <div className="Read-Time">
        <p>{diffDuration.minutes()} 분 전</p>
      </div>
    );
  } else if (diffDuration.days() < 1) {
    return (
      <div className="Read-Time">
        <p>{diffDuration.hours()} 시간 전</p>
      </div>
    );
  } else {
    return (
      <div className="Read-Time">
        <p>{beginDate.format("YYYY-MM-DD")}</p>
      </div>
    );
  }
}

function Bookmark() {
  const navigate = useNavigate();

  const documentId = useAppSelector((state) => state.read.document.documentId);

  const [click, setClick] = useState(false);

  const auth = getAuth();

  useEffect(() => {
    if (documentId !== "" && auth.currentUser !== null) {
      const firestore = getFirestore();

      const bookmarkDocRef = doc(
        firestore,
        "user",
        auth.currentUser.uid,
        "bookmark",
        documentId,
      );
      getDoc(bookmarkDocRef).then((docSnap) => {
        if (docSnap.exists()) {
          setClick(true);
        }
      });
    }
  }, [documentId]);

  if (documentId !== "" && auth.currentUser !== null) {
    const firestore = getFirestore();

    const bookmarkDocRef = doc(
      firestore,
      "user",
      auth.currentUser.uid,
      "bookmark",
      documentId,
    );

    const onClick = async () => {
      if (click) {
        setClick(false);

        await updateDoc(doc(firestore, "user", auth.currentUser!.uid), {
          numBookmarks: increment(-1),
        });

        await deleteDoc(bookmarkDocRef);
      } else {
        setClick(true);

        await updateDoc(doc(firestore, "user", auth.currentUser!.uid), {
          numBookmarks: increment(1),
        });

        const timestamp = Date.now();

        await setDoc(bookmarkDocRef, {
          documentId: documentId,
          uid: auth.currentUser!.uid,
          timestamp: timestamp,
        });
      }
    };

    if (click) {
      return (
        <div className="Read-Bookmark">
          <button onClick={onClick}>
            <img src={bookmarkFocus} />
          </button>
        </div>
      );
    } else {
      return (
        <div className="Read-Bookmark">
          <button onClick={onClick}>
            <img src={bookmark} />
          </button>
        </div>
      );
    }
  } else {
    const onClick = () => {
      navigate("/login");
    };

    return (
      <div className="Read-Bookmark">
        <button onClick={onClick}>
          <img src={bookmark} />
        </button>
      </div>
    );
  }
}

function Report() {
  const onClick = () => {};

  return (
    <div className="Read-Report">
      <button onClick={onClick}>
        <img src={threeDots} />
      </button>
    </div>
  );
}

function Content() {
  const content = useAppSelector((state) => state.read.document.content);

  const parsedContent = parse(content);

  return <div className="Read-Content">{parsedContent}</div>;
}

function Hashtags() {
  const hashtags = useAppSelector((state) => state.read.document.hashtags);

  return (
    <div className="Read-Hashtags">
      {
        <ul>
          {hashtags.map((hashtag, index) => (
            <li key={index}>
              <Hashtag hashtag={hashtag} />
            </li>
          ))}
        </ul>
      }
    </div>
  );
}

function Hashtag(props: { hashtag: string }) {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(`/search/${props.hashtag.slice(1)}`);
  };

  return (
    <div className="Read-Hashtag">
      <img src={hashtag} />
      <button className="Read-Hashtag-tag" onClick={onClick}>
        {props.hashtag.slice(1)}
      </button>
    </div>
  );
}

function Summary() {
  return (
    <div className="Read-Summary">
      <NumUps />
      <NumDowns />
      <NumComments />
      <NumTokens />
    </div>
  );
}

function NumUps() {
  const navigate = useNavigate();

  const numUps = useAppSelector((state) => state.read.document.numUps);
  const documentId = useAppSelector((state) => state.read.document.documentId);

  const [click, setClick] = useState(false);

  const dispatch = useAppDispatch();

  const auth = getAuth();

  const firestore = getFirestore();

  useEffect(() => {
    if (documentId !== "") {
      const metadataDocRef = doc(firestore, "metadata", documentId);

      getDoc(metadataDocRef).then((docSnap) => {
        if (docSnap.exists() && docSnap.data().hasOwnProperty("numUps")) {
          dispatch(readActions.setNumUps(docSnap.data()["numUps"]));
        }
      });
    }
  }, [documentId]);

  if (documentId !== "" && auth.currentUser !== null) {
    const upDocRef = doc(
      firestore,
      "metadata",
      documentId,
      "up",
      auth.currentUser.uid,
    );

    getDoc(upDocRef).then((docSnap) => {
      if (docSnap.exists()) {
        setClick(true);
      }
    });

    const metadataDocRef = doc(firestore, "metadata", documentId);

    const onClick = async () => {
      if (click) {
        dispatch(readActions.setNumUps(numUps - 1));
        setClick(false);

        await deleteDoc(upDocRef);

        await updateDoc(metadataDocRef, {
          numUps: increment(-1),
        });
      } else {
        dispatch(readActions.setNumUps(numUps + 1));
        setClick(true);

        const upTimestamp = Date.now();

        await setDoc(upDocRef, {
          uid: auth.currentUser!.uid,
          timestamp: upTimestamp,
        });

        await updateDoc(metadataDocRef, {
          numUps: increment(1),
        });
      }
    };

    if (click) {
      return (
        <div className="Read-NumUps-Focus">
          <button onClick={onClick}>
            <img src={upFocus} />
            <p>{numUps}</p>
          </button>
        </div>
      );
    } else {
      return (
        <div className="Read-NumUps">
          <button onClick={onClick}>
            <img src={up} />
            <p>{numUps}</p>
          </button>
        </div>
      );
    }
  } else {
    const onClick = () => {
      navigate("/login");
    };

    return (
      <div className="Read-NumUps">
        <button onClick={onClick}>
          <img src={up} />
          <p>{numUps}</p>
        </button>
      </div>
    );
  }
}

function NumDowns() {
  const navigate = useNavigate();

  const numDowns = useAppSelector((state) => state.read.document.numDowns);
  const documentId = useAppSelector((state) => state.read.document.documentId);

  const [click, setClick] = useState(false);

  const dispatch = useAppDispatch();

  const auth = getAuth();

  const firestore = getFirestore();

  useEffect(() => {
    if (documentId !== "") {
      const metadataDocRef = doc(firestore, "metadata", documentId);

      getDoc(metadataDocRef).then((docSnap) => {
        if (docSnap.exists() && docSnap.data().hasOwnProperty("numDowns")) {
          dispatch(readActions.setNumDowns(docSnap.data()["numDowns"]));
        }
      });
    }
  }, [documentId]);

  if (documentId !== "" && auth.currentUser !== null) {
    const downDocRef = doc(
      firestore,
      "metadata",
      documentId,
      "down",
      auth.currentUser.uid,
    );
    getDoc(downDocRef).then((docSnap) => {
      if (docSnap.exists()) {
        setClick(true);
      }
    });

    const metadataDocRef = doc(firestore, "metadata", documentId);

    const onClick = async () => {
      if (click) {
        dispatch(readActions.setNumDowns(numDowns - 1));
        setClick(false);

        await deleteDoc(downDocRef);

        await updateDoc(metadataDocRef, {
          numDowns: increment(-1),
        });
      } else {
        dispatch(readActions.setNumDowns(numDowns + 1));
        setClick(true);

        const upTimestamp = Date.now();

        await setDoc(downDocRef, {
          uid: auth.currentUser!.uid,
          timestamp: upTimestamp,
        });

        await updateDoc(metadataDocRef, {
          numDowns: increment(1),
        });
      }
    };

    if (click) {
      return (
        <div className="Read-NumDowns-Focus">
          <button onClick={onClick}>
            <img src={downFocus} />
            <p>{numDowns}</p>
          </button>
        </div>
      );
    } else {
      return (
        <div className="Read-NumDowns">
          <button onClick={onClick}>
            <img src={down} />
            <p>{numDowns}</p>
          </button>
        </div>
      );
    }
  } else {
    const onClick = () => {
      navigate("/login");
    };

    return (
      <div className="Read-NumDowns">
        <button onClick={onClick}>
          <img src={down} />
          <p>{numDowns}</p>
        </button>
      </div>
    );
  }
}

function NumComments() {
  const navigate = useNavigate();

  const documentId = useAppSelector((state) => state.read.document.documentId);
  const numComments = useAppSelector(
    (state) => state.read.document.numComments,
  );

  const dispatch = useAppDispatch();

  const firestore = getFirestore();

  useEffect(() => {
    if (documentId !== "") {
      const metadataDocRef = doc(firestore, "metadata", documentId);

      getDoc(metadataDocRef).then((docSnap) => {
        if (docSnap.exists() && docSnap.data().hasOwnProperty("numComments")) {
          dispatch(readActions.setNumComments(docSnap.data()["numComments"]));
        }
      });
    }
  }, [documentId]);

  const onClick = () => {
    navigate(`/read/${documentId}`);
    navigate(0);
  };

  return (
    <div className="Read-NumComments">
      <button onClick={onClick}>
        <img src={comment} />
        <p>{numComments}</p>
      </button>
    </div>
  );
}

function NumTokens() {
  const [numTokens, setNumTokens] = useState(0);

  return (
    <div className="Read-NumTokens">
      <button>
        <p>TOKEN</p>
        <ComingSoon />
      </button>
    </div>
  );
}

function ComingSoon() {
  return (
    <div className="Read-ComingSoon">
      <button>
        <img src={comingSoon} />
      </button>
    </div>
  );
}

function CommentInput() {
  return (
    <div className="Read-CommentInput">
      <CommentInputAvatar />
      <CommentInputBody />
    </div>
  );
}

function CommentInputAvatar() {
  const [avatarUrl, setAvatarUrl] = useState("");

  useEffect(() => {
    const auth = getAuth();

    const firestore = getFirestore();

    if (auth.currentUser !== null) {
      const docRef = doc(firestore, "user", auth.currentUser.uid);

      getDoc(docRef).then((docSnap) => {
        if (docSnap.exists()) {
          const data = docSnap.data();

          setAvatarUrl(data.avatarUrl || "");
        }
      });
    }
  }, []);

  if (avatarUrl !== "") {
    return (
      <div className="Read-CommentInputAvatar">
        <img src={avatarUrl} />
      </div>
    );
  } else {
    return (
      <div className="Read-CommentInputAvatar">
        <img src={defaultAvatar} />
      </div>
    );
  }
}

function CommentInputBody() {
  const documentId = useAppSelector((state) => state.read.document.documentId);
  const authorUid = useAppSelector((state) => state.read.document.authorUid);

  const dispatch = useAppDispatch();

  const [content, setContent] = useState("");

  const onChange = (e: any) => {
    if (e.target.value.length < 200) {
      setContent(e.target.value);
    } else {
      alert("댓글 글자수는 200자로 제한됩니다.");
    }
  };

  const onClick = async () => {
    if (content !== "") {
      dispatch(coreActions.setIsLoading(true));

      const auth = getAuth();
      const firestore = getFirestore();

      const timestamp = Date.now();

      const id = uuid();

      const commentId = timestamp.toString() + "-" + id;

      if (auth.currentUser !== null) {
        await updateDoc(doc(firestore, "metadata", documentId), {
          numComments: increment(1),
        });

        await setDoc(
          doc(firestore, "board", documentId, "comment", commentId),
          {
            documentId: documentId,
            commentId: commentId,
            authorUid: auth.currentUser.uid,
            content: content,
            timestamp: timestamp,
          },
        );

        await setDoc(
          doc(firestore, "metadata", documentId, "comment", commentId),
          {
            documentId: documentId,
            commentId: commentId,
            authorUid: auth.currentUser.uid,
            timestamp: timestamp,
            numUps: 0,
            numDowns: 0,
            numReplies: 0,
          },
        );

        await setDoc(
          doc(firestore, "user", authorUid, "notification", commentId),
          {
            messageType: "comment",
            documentId: documentId,
            commentId: commentId,
            replyId: "",
            authorUid: auth.currentUser!.uid,
            timestamp: timestamp,
          },
        );
      } else {
        alert("댓글 쓰기는 로그인하여야 가능합니다.");
      }

      setContent("");

      dispatch(coreActions.setIsLoading(false));
    }
  };

  return (
    <div className="Read-CommentInputBody">
      <form>
        <textarea
          placeholder={"댓글을 남겨주세요!"}
          wrap="soft"
          value={content}
          onChange={onChange}
        />
      </form>
      <button onClick={onClick}>등록</button>
    </div>
  );
}

function Comments() {
  const { slug } = useParams();

  const documentId = useAppSelector((state) => state.read.document.documentId);
  const comments = useAppSelector((state) => state.read.comments);

  const dispatch = useAppDispatch();

  const [commentsLastVisible, setCommentsLastVisible] =
    useState<any>(undefined);
  const [isEnd, setIsEnd] = useState(true);

  const firestore = getFirestore();

  const getComments = () => {
    if (slug! !== "") {
      let q;
      if (commentsLastVisible === -1) {
        return;
      } else if (commentsLastVisible !== undefined) {
        q = query(
          collection(firestore, "board", slug!, "comment"),
          orderBy("timestamp", "desc"),
          limit(10),
          startAfter(commentsLastVisible),
        );
      } else {
        q = query(
          collection(firestore, "board", slug!, "comment"),
          orderBy("timestamp", "desc"),
          limit(5),
        );
      }

      onSnapshot(q, async (snapshot) => {
        const newComments = snapshot.docs.map((elem) => elem.data());

        for (const elem of newComments) {
          let authorAvatarUrl = "";
          let authorNickname = "";

          const userDocRef = doc(firestore, "user", elem.authorUid);

          const docSnap = await getDoc(userDocRef);
          if (docSnap.exists()) {
            const data = docSnap.data();
            authorAvatarUrl = data.avatarUrl || "";
            authorNickname = data.nickname || "";
          }

          const comment: CommentState = {
            commentId: elem.commentId,
            authorUid: elem.authorUid,
            authorAvatarUrl: authorAvatarUrl,
            authorNickname: authorNickname,
            timestamp: elem.timestamp,
            content: elem.content,
            numUps: 0,
            numDowns: 0,
            numReplies: 0,
            numTokens: 0,
          };

          dispatch(readActions.appendComment(comment));
        }

        if (snapshot.docs.length === 0) {
          setCommentsLastVisible(-1);
          setIsEnd(true);
        } else {
          setCommentsLastVisible(snapshot.docs[snapshot.docs.length - 1]);
          setIsEnd(false);
        }
      });
    }
  };

  useEffect(() => {
    setCommentsLastVisible(undefined);
    dispatch(readActions.resetComments());
    dispatch(readActions.unsetClickedComment());
    getComments();
  }, [slug!, documentId]);

  const c = comments.map((item, _) => {
    return <Comment comment={item} />;
  });

  const onClick = () => {
    getComments();
  };

  if (!isEnd) {
    return (
      <div className="Read-Comments">
        {c}
        <div className="Read-ReadMoreComments">
          <button onClick={onClick}>댓글 더보기</button>
        </div>
      </div>
    );
  } else {
    return <div className="Read-Comments">{c}</div>;
  }
}

function Comment(props: { comment: CommentState }) {
  const clickedComment = useAppSelector((state) => state.read.clickedComment);

  const [isClicked, setisClicked] = useState(false);

  useEffect(() => {
    if (clickedComment === props.comment.commentId) {
      setisClicked(true);
    } else {
      setisClicked(false);
    }
  }, [clickedComment]);

  if (isClicked) {
    return (
      <div className="Read-Comment">
        <CommentHeader comment={props.comment} />
        <CommentBody comment={props.comment} />
        <CommentSummary comment={props.comment} />
        <ReplyInput comment={props.comment} />
        <Replies />
      </div>
    );
  } else {
    return (
      <div className="Read-Comment">
        <CommentHeader comment={props.comment} />
        <CommentBody comment={props.comment} />
        <CommentSummary comment={props.comment} />
      </div>
    );
  }
}

function CommentHeader(props: { comment: CommentState }) {
  return (
    <div className="Read-CommentHeader">
      <CommentAvatar comment={props.comment} />
      <CommentNickname comment={props.comment} />
      <CommentTime comment={props.comment} />
      <CommentReport comment={props.comment} />
    </div>
  );
}

function CommentAvatar(props: { comment: CommentState }) {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(`/profile_view/${props.comment.authorUid}`);
  };

  if (props.comment.authorAvatarUrl !== "") {
    return (
      <div className="Read-CommentAvatar">
        <img src={props.comment.authorAvatarUrl} onClick={onClick} />
      </div>
    );
  } else {
    return (
      <div className="Read-CommentAvatar">
        <img src={defaultAvatar} onClick={onClick} />
      </div>
    );
  }
}

function CommentTime(props: { comment: CommentState }) {
  const endTimestamp = Date.now();

  const beginDate = moment(props.comment.timestamp);
  const endDate = moment(endTimestamp);
  const diff = endDate.diff(beginDate);
  const diffDuration = moment.duration(diff);

  if (diffDuration.minutes() < 1) {
    return (
      <div className="Read-CommentTime">
        <p>방금 전</p>
      </div>
    );
  } else if (diffDuration.hours() < 1) {
    return (
      <div className="Read-CommentTime">
        <p>{diffDuration.minutes()} 분 전</p>
      </div>
    );
  } else if (diffDuration.days() < 1) {
    return (
      <div className="Read-CommentTime">
        <p>{diffDuration.hours()} 시간 전</p>
      </div>
    );
  } else {
    return (
      <div className="Read-CommentTime">
        <p>{beginDate.format("YYYY-MM-DD")}</p>
      </div>
    );
  }
}

function CommentNickname(props: { comment: CommentState }) {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(`/profile_view/${props.comment.authorUid}`);
  };

  if (props.comment.authorNickname !== "") {
    return (
      <div className="Read-CommentNickname">
        <button onClick={onClick}>{props.comment.authorNickname}</button>
      </div>
    );
  } else {
    return (
      <div className="Read-CommentNickname">
        <button onClick={onClick}>닉네임 없음</button>
      </div>
    );
  }
}

function CommentReport(props: { comment: CommentState }) {
  const onClick = () => {};

  return (
    <div className="Read-CommentReport">
      <button onClick={onClick}>
        <img src={threeDots} />
      </button>
    </div>
  );
}

function CommentBody(props: { comment: CommentState }) {
  return (
    <div className="Read-CommentBody">
      <p>{props.comment.content}</p>
    </div>
  );
}

function CommentSummary(props: { comment: CommentState }) {
  return (
    <div className="Read-CommentSummary">
      <CommentNumUps comment={props.comment} />
      <CommentNumDowns comment={props.comment} />
      <CommentNumReplies comment={props.comment} />
      <CommentNumTokens comment={props.comment} />
    </div>
  );
}

function CommentNumUps(props: { comment: CommentState }) {
  const navigate = useNavigate();

  const documentId = useAppSelector((state) => state.read.document.documentId);

  const [numUps, setNumUps] = useState(0);
  const [click, setClick] = useState(false);

  const auth = getAuth();

  const firestore = getFirestore();

  useEffect(() => {
    if (documentId !== "") {
      const commentDocRef = doc(
        firestore,
        "metadata",
        documentId,
        "comment",
        props.comment.commentId,
      );
      getDoc(commentDocRef).then((docSnap) => {
        if (docSnap.exists() && docSnap.data().hasOwnProperty("numUps")) {
          setNumUps(docSnap.data()["numUps"]);
        }
      });
    }
  }, [documentId, props]);

  if (documentId !== "" && auth.currentUser !== null) {
    const upDocRef = doc(
      firestore,
      "metadata",
      documentId,
      "comment",
      props.comment.commentId,
      "up",
      auth.currentUser.uid,
    );
    getDoc(upDocRef).then((docSnap) => {
      if (docSnap.exists()) {
        setClick(true);
      }
    });

    const commentDocRef = doc(
      firestore,
      "metadata",
      documentId,
      "comment",
      props.comment.commentId,
    );

    const onClick = async () => {
      if (click) {
        setNumUps(numUps - 1);
        setClick(false);

        await deleteDoc(upDocRef);

        await updateDoc(commentDocRef, {
          numUps: increment(-1),
        });
      } else {
        setNumUps(numUps + 1);
        setClick(true);

        const timestamp = Date.now();

        await setDoc(upDocRef, {
          uid: auth.currentUser!.uid,
          timestamp: timestamp,
        });

        await updateDoc(commentDocRef, {
          numUps: increment(1),
        });
      }
    };

    if (click) {
      return (
        <div className="Read-CommentNumUps-Focus">
          <button onClick={onClick}>
            <img src={upFocus} />
            <p>{numUps}</p>
          </button>
        </div>
      );
    } else {
      return (
        <div className="Read-CommentNumUps">
          <button onClick={onClick}>
            <img src={up} />
            <p>{numUps}</p>
          </button>
        </div>
      );
    }
  } else {
    const onClick = () => {
      navigate("/login");
    };

    return (
      <div className="Read-CommentNumUps">
        <button onClick={onClick}>
          <img src={up} />
          <p>{numUps}</p>
        </button>
      </div>
    );
  }
}

function CommentNumDowns(props: { comment: CommentState }) {
  const navigate = useNavigate();

  const documentId = useAppSelector((state) => state.read.document.documentId);

  const [numDowns, setNumDowns] = useState(0);
  const [click, setClick] = useState(false);

  const auth = getAuth();

  const firestore = getFirestore();

  useEffect(() => {
    if (documentId !== "") {
      const commentDocRef = doc(
        firestore,
        "metadata",
        documentId,
        "comment",
        props.comment.commentId,
      );
      getDoc(commentDocRef).then((docSnap) => {
        if (docSnap.exists() && docSnap.data().hasOwnProperty("numDowns")) {
          setNumDowns(docSnap.data()["numDowns"]);
        }
      });
    }
  }, [documentId, props]);

  if (documentId !== "" && auth.currentUser !== null) {
    const downDocRef = doc(
      firestore,
      "metadata",
      documentId,
      "comment",
      props.comment.commentId,
      "down",
      auth.currentUser.uid,
    );
    getDoc(downDocRef).then((docSnap) => {
      if (docSnap.exists()) {
        setClick(true);
      }
    });

    const commentDocRef = doc(
      firestore,
      "metadata",
      documentId,
      "comment",
      props.comment.commentId,
    );

    const onClick = async () => {
      if (click) {
        setNumDowns(numDowns - 1);
        setClick(false);

        await deleteDoc(downDocRef);

        await updateDoc(commentDocRef, {
          numDowns: increment(-1),
        });
      } else {
        setNumDowns(numDowns + 1);
        setClick(true);

        const timestamp = Date.now();

        await setDoc(downDocRef, {
          uid: auth.currentUser!.uid,
          timestamp: timestamp,
        });

        await updateDoc(commentDocRef, {
          numDowns: increment(1),
        });
      }
    };

    if (click) {
      return (
        <div className="Read-CommentNumDowns-Focus">
          <button onClick={onClick}>
            <img src={downFocus} />
            <p>{numDowns}</p>
          </button>
        </div>
      );
    } else {
      return (
        <div className="Read-CommentNumDowns">
          <button onClick={onClick}>
            <img src={down} />
            <p>{numDowns}</p>
          </button>
        </div>
      );
    }
  } else {
    const onClick = () => {
      navigate("/login");
    };

    return (
      <div className="Read-CommentNumDowns">
        <button onClick={onClick}>
          <img src={down} />
          <p>{numDowns}</p>
        </button>
      </div>
    );
  }
}

function CommentNumReplies(props: { comment: CommentState }) {
  const documentId = useAppSelector((state) => state.read.document.documentId);

  const dispatch = useAppDispatch();

  const [numReplies, setNumReplies] = useState(0);
  const [click, setClick] = useState(false);

  const firestore = getFirestore();

  useEffect(() => {
    if (documentId !== "") {
      const commentDocRef = doc(
        firestore,
        "metadata",
        documentId,
        "comment",
        props.comment.commentId,
      );
      getDoc(commentDocRef).then((docSnap) => {
        if (docSnap.exists() && docSnap.data().hasOwnProperty("numReplies")) {
          setNumReplies(docSnap.data()["numReplies"]);
        }
      });
    }
  }, [documentId, props]);

  const onClick = () => {
    if (click) {
      setClick(false);

      dispatch(readActions.unsetClickedComment());
    } else {
      setClick(true);

      dispatch(readActions.setClickedComment(props.comment.commentId));
    }
  };

  return (
    <div className="Read-CommentNumReplies">
      <button onClick={onClick}>
        <img src={comment} />
        <p>{numReplies}</p>
      </button>
    </div>
  );
}

function CommentNumTokens(props: { comment: CommentState }) {
  const documentId = useAppSelector((state) => state.read.document.documentId);

  const [numTokens, setNumTokens] = useState(0);

  return (
    <div className="Read-CommentNumTokens">
      <button>
        <p>TOKEN</p>
        <ComingSoon />
      </button>
    </div>
  );
}

function ReplyInput(props: { comment: CommentState }) {
  return (
    <div className="Read-ReplyInput">
      <ReplyImage />
      <ReplyInputAvatar />
      <ReplyInputBody comment={props.comment} />
    </div>
  );
}

function ReplyImage() {
  return (
    <div className="Read-ReplyImage">
      <img src={reply} />
    </div>
  );
}

function ReplyInputAvatar() {
  const [avatarUrl, setAvatarUrl] = useState("");

  useEffect(() => {
    const auth = getAuth();

    const firestore = getFirestore();

    if (auth.currentUser !== null) {
      const userDocRef = doc(firestore, "user", auth.currentUser.uid);

      getDoc(userDocRef).then((docSnap) => {
        if (docSnap.exists()) {
          const data = docSnap.data();

          setAvatarUrl(data.avatarUrl || "");
        }
      });
    }
  }, []);

  if (avatarUrl !== "") {
    return (
      <div className="Read-ReplyInputAvatar">
        <img src={avatarUrl} />
      </div>
    );
  } else {
    return (
      <div className="Read-ReplyInputAvatar">
        <img src={defaultAvatar} />
      </div>
    );
  }
}

function ReplyInputBody(props: { comment: CommentState }) {
  const documentId = useAppSelector((state) => state.read.document.documentId);
  const authorUid = useAppSelector((state) => state.read.document.authorUid);

  const dispatch = useAppDispatch();

  const [content, setContent] = useState("");

  const onChange = (e: any) => {
    if (e.target.value.length < 200) {
      setContent(e.target.value);
    } else {
      alert("답글 글자수는 200자로 제한됩니다.");
    }
  };

  const onClick = async () => {
    if (content !== "") {
      if (content !== "") {
        dispatch(coreActions.setIsLoading(true));

        const auth = getAuth();
        const firestore = getFirestore();

        const timestamp = Date.now();

        const id = uuid();

        const replyId = timestamp.toString() + "-" + id;

        if (auth.currentUser !== null) {
          await updateDoc(
            doc(
              firestore,
              "metadata",
              documentId,
              "comment",
              props.comment.commentId,
            ),
            {
              numReplies: increment(1),
            },
          );

          await setDoc(
            doc(
              firestore,
              "board",
              documentId,
              "comment",
              props.comment.commentId,
              "reply",
              replyId,
            ),
            {
              documentId: documentId,
              commentId: props.comment.commentId,
              replyId: replyId,
              authorUid: auth.currentUser.uid,
              content: content,
              timestamp: timestamp,
              numUps: 0,
              numDowns: 0,
            },
          );

          await setDoc(
            doc(
              firestore,
              "metadata",
              documentId,
              "comment",
              props.comment.commentId,
              "reply",
              replyId,
            ),
            {
              documentId: documentId,
              commentId: props.comment.commentId,
              replyId: replyId,
              authorUid: auth.currentUser.uid,
              timestamp: timestamp,
            },
          );

          await setDoc(
            doc(
              firestore,
              "user",
              props.comment.authorUid,
              "notification",
              replyId,
            ),
            {
              messageType: "reply",
              documentId: documentId,
              commentId: props.comment.commentId,
              replyId: replyId,
              authorUid: auth.currentUser!.uid,
              timestamp: timestamp,
            },
          );
        } else {
          alert("답글 쓰기는 로그인하여야 가능합니다.");
        }

        setContent("");
        dispatch(coreActions.setIsLoading(false));
      }
    }
  };

  return (
    <div className="Read-ReplyInputBody">
      <form>
        <textarea
          placeholder={"답글을 남겨주세요!"}
          wrap="soft"
          value={content}
          onChange={onChange}
        />
      </form>
      <button onClick={onClick}>등록</button>
    </div>
  );
}

function Replies() {
  const { slug } = useParams();

  const dispatch = useAppDispatch();

  const documentId = useAppSelector((state) => state.read.document.documentId);
  const clickedComment = useAppSelector((state) => state.read.clickedComment);
  const replies = useAppSelector((state) => state.read.replies);

  const firestore = getFirestore();

  const [repliesLastVisible, setRepliesLastVisible] = useState<any>(undefined);
  const [isEnd, setIsEnd] = useState(true);

  const getReplies = () => {
    if (slug! !== "" && clickedComment !== null) {
      let q;
      if (repliesLastVisible === -1) {
        return;
      } else if (repliesLastVisible !== undefined) {
        q = query(
          collection(
            firestore,
            "board",
            slug!,
            "comment",
            clickedComment,
            "reply",
          ),
          orderBy("timestamp", "desc"),
          limit(10),
          startAfter(repliesLastVisible),
        );
      } else {
        q = query(
          collection(
            firestore,
            "board",
            slug!,
            "comment",
            clickedComment,
            "reply",
          ),
          orderBy("timestamp", "desc"),
          limit(10),
        );
      }

      onSnapshot(q, async (snapshot) => {
        const newReplies = snapshot.docs.map((elem) => elem.data());

        for (const elem of newReplies) {
          let authorAvatarUrl = "";
          let authorNickname = "";

          const userDocRef = doc(firestore, "user", elem.authorUid);

          const docSnap = await getDoc(userDocRef);
          if (docSnap.exists()) {
            const data = docSnap.data();
            authorAvatarUrl = data.avatarUrl || "";
            authorNickname = data.nickname || "";
          }

          const reply: ReplyState = {
            replyId: elem.replyId,
            authorUid: elem.authorUid,
            authorAvatarUrl: authorAvatarUrl,
            authorNickname: authorNickname,
            timestamp: elem.timestamp,
            content: elem.content,
            numUps: 0,
            numDowns: 0,
          };

          dispatch(readActions.appendReply(reply));
        }

        if (snapshot.docs.length === 0) {
          setRepliesLastVisible(-1);
          setIsEnd(true);
        } else {
          setRepliesLastVisible(snapshot.docs[snapshot.docs.length - 1]);
          setIsEnd(false);
        }
      });
    }
  };

  useEffect(() => {
    setRepliesLastVisible(undefined);
    dispatch(readActions.resetReplies());
    getReplies();
  }, [slug!, documentId, clickedComment]);

  const onClick = () => {
    getReplies();
  };

  if (!isEnd) {
    return (
      <div className="Read-Replies">
        {replies.map((item, _) => {
          return <Reply reply={item} />;
        })}
        <div className="Read-ReadMoreReplies">
          <button onClick={onClick}>답글 더보기</button>
        </div>
      </div>
    );
  } else {
    return (
      <div className="Read-Replies">
        {replies.map((item, _) => {
          return <Reply reply={item} />;
        })}
      </div>
    );
  }
}

function Reply(props: { reply: ReplyState }) {
  return (
    <div className="Read-Reply">
      <ReplyHeader reply={props.reply} />
      <ReplyBody reply={props.reply} />
      <ReplySummary reply={props.reply} />
    </div>
  );
}

function ReplyHeader(props: { reply: ReplyState }) {
  return (
    <div className="Read-CommentHeader">
      <ReplyImage />
      <ReplyAvatar reply={props.reply} />
      <ReplyNickname reply={props.reply} />
      <ReplyTime reply={props.reply} />
      <ReplyReport reply={props.reply} />
    </div>
  );
}

function ReplyAvatar(props: { reply: ReplyState }) {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(`/profile_view/${props.reply.authorUid}`);
  };

  if (props.reply.authorAvatarUrl !== "") {
    return (
      <div className="Read-ReplyAvatar">
        <img src={props.reply.authorAvatarUrl} onClick={onClick} />
      </div>
    );
  } else {
    return (
      <div className="Read-ReplyAvatar">
        <img src={defaultAvatar} onClick={onClick} />
      </div>
    );
  }
}

function ReplyTime(props: { reply: ReplyState }) {
  const endTimestamp = Date.now();

  const beginDate = moment(props.reply.timestamp);
  const endDate = moment(endTimestamp);
  const diff = endDate.diff(beginDate);
  const diffDuration = moment.duration(diff);

  if (diffDuration.minutes() < 1) {
    return (
      <div className="Read-ReplyTime">
        <p>방금 전</p>
      </div>
    );
  } else if (diffDuration.hours() < 1) {
    return (
      <div className="Read-ReplyTime">
        <p>{diffDuration.minutes()} 분 전</p>
      </div>
    );
  } else if (diffDuration.days() < 1) {
    return (
      <div className="Read-ReplyTime">
        <p>{diffDuration.hours()} 시간 전</p>
      </div>
    );
  } else {
    return (
      <div className="Read-ReplyTime">
        <p>{beginDate.format("YYYY-MM-DD")}</p>
      </div>
    );
  }
}

function ReplyNickname(props: { reply: ReplyState }) {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(`/profile_view/${props.reply.authorUid}`);
  };

  if (props.reply.authorNickname !== "") {
    return (
      <div className="Read-ReplyNickname">
        <button onClick={onClick}>{props.reply.authorNickname}</button>
      </div>
    );
  } else {
    return (
      <div className="Read-ReplyNickname">
        <button onClick={onClick}>닉네임 없음</button>
      </div>
    );
  }
}

function ReplyReport(props: { reply: ReplyState }) {
  const onClick = () => {};

  return (
    <div className="Read-ReplyReport">
      <button onClick={onClick}>
        <img src={threeDots} />
      </button>
    </div>
  );
}

function ReplyBody(props: { reply: ReplyState }) {
  return (
    <div className="Read-ReplyBody">
      <p>{props.reply.content}</p>
    </div>
  );
}

function ReplySummary(props: { reply: ReplyState }) {
  return (
    <div className="Read-ReplySummary">
      <ReplyNumUps reply={props.reply} />
      <ReplyNumDowns reply={props.reply} />
    </div>
  );
}

function ReplyNumUps(props: { reply: ReplyState }) {
  const documentId = useAppSelector((state) => state.read.document.documentId);
  const clickedComment = useAppSelector((state) => state.read.clickedComment);

  const navigate = useNavigate();

  const [numUps, setNumUps] = useState(0);
  const [click, setClick] = useState(false);

  const auth = getAuth();

  const firestore = getFirestore();

  useEffect(() => {
    if (documentId !== "" && clickedComment !== null) {
      const replyDocRef = doc(
        firestore,
        "metadata",
        documentId,
        "comment",
        clickedComment!,
        "reply",
        props.reply.replyId,
      );
      getDoc(replyDocRef).then((docSnap) => {
        if (docSnap.exists() && docSnap.data().hasOwnProperty("numUps")) {
          setNumUps(docSnap.data()["numUps"]);
        }
      });
    }
  }, [props]);

  if (
    documentId !== "" &&
    auth.currentUser !== null &&
    clickedComment !== null
  ) {
    const upDocRef = doc(
      firestore,
      "metadata",
      documentId,
      "comment",
      clickedComment!,
      "reply",
      props.reply.replyId,
      "up",
      auth.currentUser.uid,
    );
    getDoc(upDocRef).then((docSnap) => {
      if (docSnap.exists()) {
        setClick(true);
      }
    });

    const replyDocRef = doc(
      firestore,
      "metadata",
      documentId,
      "comment",
      clickedComment!,
      "reply",
      props.reply.replyId,
    );

    const onClick = async () => {
      if (click) {
        setNumUps(numUps - 1);
        setClick(false);

        await deleteDoc(upDocRef);

        await updateDoc(replyDocRef, {
          numUps: increment(-1),
        });
      } else {
        setNumUps(numUps + 1);
        setClick(true);

        const timestamp = Date.now();

        await setDoc(upDocRef, {
          uid: auth.currentUser!.uid,
          timestamp: timestamp,
        });

        await updateDoc(replyDocRef, {
          numUps: increment(1),
        });
      }
    };

    if (click) {
      return (
        <div className="Read-ReplyNumUps-Focus">
          <button onClick={onClick}>
            <img src={upFocus} />
            <p>{numUps}</p>
          </button>
        </div>
      );
    } else {
      return (
        <div className="Read-ReplyNumUps">
          <button onClick={onClick}>
            <img src={up} />
            <p>{numUps}</p>
          </button>
        </div>
      );
    }
  } else {
    const onClick = () => {
      navigate("/login");
    };

    return (
      <div className="Read-ReplyNumUps">
        <button onClick={onClick}>
          <img src={up} />
          <p>{numUps}</p>
        </button>
      </div>
    );
  }
}

function ReplyNumDowns(props: { reply: ReplyState }) {
  const documentId = useAppSelector((state) => state.read.document.documentId);
  const clickedComment = useAppSelector((state) => state.read.clickedComment);

  const navigate = useNavigate();

  const [numDowns, setNumDowns] = useState(0);
  const [click, setClick] = useState(false);

  const auth = getAuth();

  const firestore = getFirestore();

  useEffect(() => {
    if (documentId !== "" && clickedComment !== null) {
      const replyDocRef = doc(
        firestore,
        "metadata",
        documentId,
        "comment",
        clickedComment!,
        "reply",
        props.reply.replyId,
      );
      getDoc(replyDocRef).then((docSnap) => {
        if (docSnap.exists() && docSnap.data().hasOwnProperty("numDowns")) {
          setNumDowns(docSnap.data()["numDowns"]);
        }
      });
    }
  }, [props]);

  if (
    documentId !== "" &&
    auth.currentUser !== null &&
    clickedComment !== null
  ) {
    const downDocRef = doc(
      firestore,
      "metadata",
      documentId,
      "comment",
      clickedComment!,
      "reply",
      props.reply.replyId,
      "down",
      auth.currentUser.uid,
    );
    getDoc(downDocRef).then((docSnap) => {
      if (docSnap.exists()) {
        setClick(true);
      }
    });

    const replyDocRef = doc(
      firestore,
      "metadata",
      documentId,
      "comment",
      clickedComment!,
      "reply",
      props.reply.replyId,
    );

    const onClick = async () => {
      if (click) {
        setNumDowns(numDowns - 1);
        setClick(false);

        await deleteDoc(downDocRef);

        await updateDoc(replyDocRef, {
          numDowns: increment(-1),
        });
      } else {
        setNumDowns(numDowns + 1);
        setClick(true);

        const timestamp = Date.now();

        await setDoc(downDocRef, {
          uid: auth.currentUser!.uid,
          timestamp: timestamp,
        });

        await updateDoc(replyDocRef, {
          numDowns: increment(1),
        });
      }
    };

    if (click) {
      return (
        <div className="Read-ReplyNumDowns-Focus">
          <button onClick={onClick}>
            <img src={downFocus} />
            <p>{numDowns}</p>
          </button>
        </div>
      );
    } else {
      return (
        <div className="Read-ReplyNumDowns">
          <button onClick={onClick}>
            <img src={down} />
            <p>{numDowns}</p>
          </button>
        </div>
      );
    }
  } else {
    const onClick = () => {
      navigate("/login");
    };

    return (
      <div className="Read-ReplyNumDowns">
        <button onClick={onClick}>
          <img src={down} />
          <p>{numDowns}</p>
        </button>
      </div>
    );
  }
}

function Split() {
  return (
    <div className="Read-Split">
      <img src={horizontalLine} />
    </div>
  );
}

function NextDocuments(props: { documents: DocumentState[] }) {
  const document = useAppSelector((state) => state.read.document);

  const documents = props.documents.map((item, _) => {
    if (item.timestamp < document.timestamp) {
      return <NextDocument document={item} />;
    }
  });

  return <div className="Read-NextDocuments">{documents}</div>;
}

function NextDocument(props: { document: DocumentState }) {
  if (props.document.hashtags.length > 0 && props.document.hashtags[0] !== "") {
    return (
      <div className="Read-NextDocument">
        <NextDocumentHeader document={props.document} />
        <NextThumbnailContent document={props.document} />
        <NextHashtags document={props.document} />
        <NextReadMore document={props.document} />
        <NextSummary document={props.document} />
      </div>
    );
  } else {
    return (
      <div className="Read-NextDocument">
        <NextDocumentHeader document={props.document} />
        <NextThumbnailContent document={props.document} />
        <NextReadMore document={props.document} />
        <NextSummary document={props.document} />
      </div>
    );
  }
}

function NextDocumentHeader(props: { document: DocumentState }) {
  return (
    <div className="Read-NextDocumentHeader">
      <NextAvatar document={props.document} />
      <NextNickname document={props.document} />
      <NextTime document={props.document} />
      <NextBookmark document={props.document} />
      <NextReport document={props.document} />
    </div>
  );
}

function NextAvatar(props: { document: DocumentState }) {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(`/profile_view/${props.document.authorUid}`);
  };

  if (props.document.authorAvatarUrl !== "") {
    return (
      <div className="Read-NextAvatar">
        <img src={props.document.authorAvatarUrl} onClick={onClick} />
      </div>
    );
  } else {
    return (
      <div className="Read-NextAvatar">
        <img src={defaultAvatar} onClick={onClick} />
      </div>
    );
  }
}

function NextNickname(props: { document: DocumentState }) {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(`/profile_view/${props.document.authorUid}`);
  };

  if (props.document.authorNickname !== "") {
    return (
      <div className="Read-NextNickname">
        <button onClick={onClick}>{props.document.authorNickname}</button>
      </div>
    );
  } else {
    return (
      <div className="Read-NextNickname">
        <button onClick={onClick}>닉네임 없음</button>
      </div>
    );
  }
}

function NextTime(props: { document: DocumentState }) {
  const endTimestamp = Date.now();

  const beginDate = moment(props.document.timestamp);
  const endDate = moment(endTimestamp);
  const diff = endDate.diff(beginDate);
  const diffDuration = moment.duration(diff);

  if (diffDuration.minutes() < 1) {
    return (
      <div className="Read-NextTime">
        <p>방금 전</p>
      </div>
    );
  } else if (diffDuration.hours() < 1) {
    return (
      <div className="Read-NextTime">
        <p>{diffDuration.minutes()} 분 전</p>
      </div>
    );
  } else if (diffDuration.days() < 1) {
    return (
      <div className="Read-NextTime">
        <p>{diffDuration.hours()} 시간 전</p>
      </div>
    );
  } else {
    return (
      <div className="Read-NextTime">
        <p>{beginDate.format("YYYY-MM-DD")}</p>
      </div>
    );
  }
}

function NextBookmark(props: { document: DocumentState }) {
  const navigate = useNavigate();

  const [click, setClick] = useState(false);

  const auth = getAuth();

  useEffect(() => {
    if (auth.currentUser !== null) {
      const firestore = getFirestore();

      const bookmarkDocRef = doc(
        firestore,
        "user",
        auth.currentUser.uid,
        "bookmark",
        props.document.documentId,
      );
      getDoc(bookmarkDocRef).then((docSnap) => {
        if (docSnap.exists()) {
          setClick(true);
        }
      });
    }
  }, []);

  if (auth.currentUser !== null) {
    const firestore = getFirestore();

    const bookmarkDocRef = doc(
      firestore,
      "user",
      auth.currentUser.uid,
      "bookmark",
      props.document.documentId,
    );

    const onClick = async () => {
      if (click) {
        setClick(false);

        await updateDoc(doc(firestore, "user", auth.currentUser!.uid), {
          numBookmarks: increment(-1),
        });

        await deleteDoc(bookmarkDocRef);
      } else {
        setClick(true);

        await updateDoc(doc(firestore, "user", auth.currentUser!.uid), {
          numBookmarks: increment(1),
        });

        const timestamp = Date.now();

        await setDoc(bookmarkDocRef, {
          documentId: props.document.documentId,
          uid: auth.currentUser!.uid,
          timestamp: timestamp,
        });
      }
    };

    if (click) {
      return (
        <div className="Read-NextBookmark">
          <button onClick={onClick}>
            <img src={bookmarkFocus} />
          </button>
        </div>
      );
    } else {
      return (
        <div className="Read-NextBookmark">
          <button onClick={onClick}>
            <img src={bookmark} />
          </button>
        </div>
      );
    }
  } else {
    const onClick = () => {
      navigate("/login");
    };

    return (
      <div className="Read-NextBookmark">
        <button onClick={onClick}>
          <img src={bookmark} />
        </button>
      </div>
    );
  }
}

function NextReport(props: { document: DocumentState }) {
  const onClick = () => {};

  return (
    <div className="Read-NextReport">
      <button onClick={onClick}>
        <img src={threeDots} />
      </button>
    </div>
  );
}

function NextThumbnailContent(props: { document: DocumentState }) {
  const navigate = useNavigate();

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );

  const onClick = () => {
    navigate(`/read/${props.document.documentId}`);
    navigate(0);
  };

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="Read-NextThumbnailContent">
      <button onClick={onClick}>
        {props.document.thumbnailContent.map((item, _) => {
          if (item.props !== undefined && item.props.children !== undefined) {
            if (item.props.children.type === "img") {
              for (let imageSize of props.document.thumbnailImageSizes) {
                if (imageSize.src === item.props.children.props.src) {
                  if (
                    0.99 * 0.44 * windowDimensions.width - 20 <
                    imageSize.width
                  ) {
                    return (
                      <img
                        style={{
                          width: 0.85 * 0.44 * windowDimensions.width - 20,
                          maxHeight: imageSize.height,
                          objectFit: "cover",
                          objectPosition: "0% 0%",
                          backgroundColor: "transparent",
                        }}
                        src={item.props.children.props.src}
                      />
                    );
                  } else {
                    return (
                      <img
                        style={{
                          width: imageSize.width,
                          maxHeight: imageSize.height,
                          objectFit: "cover",
                          objectPosition: "0% 0%",
                          backgroundColor: "transparent",
                        }}
                        src={item.props.children.props.src}
                      />
                    );
                  }
                }
              }

              return item;
            } else {
              return item;
            }
          } else if (item.type === "img" && item.props !== undefined) {
            for (let imageSize of props.document.thumbnailImageSizes) {
              if (imageSize.src === item.props.src) {
                if (
                  0.99 * 0.44 * windowDimensions.width - 20 <
                  imageSize.width
                ) {
                  return (
                    <img
                      style={{
                        width: 0.85 * 0.44 * windowDimensions.width - 20,
                        maxHeight: imageSize.height,
                        objectFit: "cover",
                        objectPosition: "0% 0%",
                        backgroundColor: "transparent",
                      }}
                      src={item.props.src}
                    />
                  );
                } else {
                  return (
                    <img
                      style={{
                        width: imageSize.width,
                        maxHeight: imageSize.height,
                        objectFit: "cover",
                        objectPosition: "0% 0%",
                        backgroundColor: "transparent",
                      }}
                      src={item.props.src}
                    />
                  );
                }
              }
            }

            return item;
          } else {
            return item;
          }
        })}
      </button>
    </div>
  );
}

function NextHashtags(props: { document: DocumentState }) {
  return (
    <div className="Read-NextHashtags">
      {
        <ul>
          {props.document.hashtags.map((hashtag, index) => (
            <li key={index}>
              <NextHashtag hashtag={hashtag} />
            </li>
          ))}
        </ul>
      }
    </div>
  );
}

function NextHashtag(props: { hashtag: string }) {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(`/search_query/${props.hashtag.slice(1)}`);
  };

  return (
    <div className="Read-NextHashtag">
      <img src={hashtag} />
      <button className="Read-NextHashtag-tag" onClick={onClick}>
        {props.hashtag.slice(1)}
      </button>
    </div>
  );
}

function NextReadMore(props: { document: DocumentState }) {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(`/read/${props.document.documentId}`);
    navigate(0);
  };

  return (
    <div className="Read-NextReadMore">
      <button onClick={onClick}>
        <img src={more}/>
      </button>
    </div>
  );
}

function NextSummary(props: { document: DocumentState }) {
  return (
    <div className="Read-NextSummary">
      <NextNumUps document={props.document} />
      <NextNumDowns document={props.document} />
      <NextNumComments document={props.document} />
      <NextNumTokens document={props.document} />
    </div>
  );
}

function NextNumUps(props: { document: DocumentState }) {
  const navigate = useNavigate();

  const [numUps, setNumUps] = useState(props.document.numUps);
  const [click, setClick] = useState(false);

  const auth = getAuth();

  const firestore = getFirestore();

  useEffect(() => {
    const metadataDocRef = doc(
      firestore,
      "metadata",
      props.document.documentId,
    );

    getDoc(metadataDocRef).then((docSnap) => {
      if (docSnap.exists() && docSnap.data().hasOwnProperty("numUps")) {
        setNumUps(docSnap.data()["numUps"]);
      }
    });
  }, []);

  if (auth.currentUser !== null) {
    const upDocRef = doc(
      firestore,
      "metadata",
      props.document.documentId,
      "up",
      auth.currentUser.uid,
    );

    getDoc(upDocRef).then((docSnap) => {
      if (docSnap.exists()) {
        setClick(true);
      }
    });

    const summaryDocRef = doc(firestore, "metadata", props.document.documentId);

    const onClick = async () => {
      if (click) {
        setNumUps(numUps - 1);
        setClick(false);

        await deleteDoc(upDocRef);

        await updateDoc(summaryDocRef, {
          numUps: increment(-1),
        });
      } else {
        setNumUps(numUps + 1);
        setClick(true);

        const upTimestamp = Date.now();

        await setDoc(upDocRef, {
          uid: auth.currentUser!.uid,
          timestamp: upTimestamp,
        });

        await updateDoc(summaryDocRef, {
          numUps: increment(1),
        });
      }
    };

    if (click) {
      return (
        <div className="Read-NextNumUps-Focus">
          <button onClick={onClick}>
            <img src={up} />
            <p>{numUps}</p>
          </button>
        </div>
      );
    } else {
      return (
        <div className="Read-NextNumUps">
          <button onClick={onClick}>
            <img src={up} />
            <p>{numUps}</p>
          </button>
        </div>
      );
    }
  } else {
    const onClick = () => {
      navigate("/login");
    };

    return (
      <div className="Read-NextNumUps">
        <button onClick={onClick}>
          <img src={up} />
          <p>{numUps}</p>
        </button>
      </div>
    );
  }
}

function NextNumDowns(props: { document: DocumentState }) {
  const navigate = useNavigate();

  const [numDowns, setNumDowns] = useState(props.document.numDowns);
  const [click, setClick] = useState(false);

  const auth = getAuth();

  const firestore = getFirestore();

  useEffect(() => {
    const metadataDocRef = doc(
      firestore,
      "metadata",
      props.document.documentId,
    );

    getDoc(metadataDocRef).then((docSnap) => {
      if (docSnap.exists() && docSnap.data().hasOwnProperty("numDowns")) {
        setNumDowns(docSnap.data()["numDowns"]);
      }
    });
  }, []);

  if (auth.currentUser !== null) {
    const downDocRef = doc(
      firestore,
      "metadata",
      props.document.documentId,
      "down",
      auth.currentUser.uid,
    );

    getDoc(downDocRef).then((docSnap) => {
      if (docSnap.exists()) {
        setClick(true);
      }
    });

    const metadataDocRef = doc(
      firestore,
      "metadata",
      props.document.documentId,
    );

    const onClick = async () => {
      if (click) {
        setNumDowns(numDowns - 1);
        setClick(false);

        await deleteDoc(downDocRef);

        await updateDoc(metadataDocRef, {
          numDowns: increment(-1),
        });
      } else {
        setNumDowns(numDowns + 1);
        setClick(true);

        const upTimestamp = Date.now();

        await setDoc(downDocRef, {
          uid: auth.currentUser!.uid,
          timestamp: upTimestamp,
        });

        await updateDoc(metadataDocRef, {
          numDowns: increment(1),
        });
      }
    };

    if (click) {
      return (
        <div className="Read-NextNumDowns-Focus">
          <button onClick={onClick}>
            <img src={downFocus} />
            <p>{numDowns}</p>
          </button>
        </div>
      );
    } else {
      return (
        <div className="Read-NextNumDowns">
          <button onClick={onClick}>
            <img src={down} />
            <p>{numDowns}</p>
          </button>
        </div>
      );
    }
  } else {
    const onClick = () => {
      navigate("/login");
    };

    return (
      <div className="Read-NextNumDowns">
        <button onClick={onClick}>
          <img src={down} />
          <p>{numDowns}</p>
        </button>
      </div>
    );
  }
}

function NextNumComments(props: { document: DocumentState }) {
  const navigate = useNavigate();

  const [numComments, setNumComments] = useState(0);

  const firestore = getFirestore();

  const metadataDocRef = doc(firestore, "metadata", props.document.documentId);
  getDoc(metadataDocRef).then((docSnap) => {
    if (docSnap.exists() && docSnap.data().hasOwnProperty("numComments")) {
      setNumComments(docSnap.data()["numComments"]);
    }
  });

  const onClick = () => {
    navigate(`/read/${props.document.documentId}`);
    navigate(0);
  };

  return (
    <div className="Read-NextNumComments">
      <button onClick={onClick}>
        <img src={comment} />
        <p>{numComments}</p>
      </button>
    </div>
  );
}

function NextNumTokens(props: { document: DocumentState }) {
  const [numTokens, setNumTokens] = useState(0);

  return (
    <div className="Read-NextNumTokens">
      <button>
        <p>TOKEN</p>
        <ComingSoon />
      </button>
    </div>
  );
}
