import { useAppDispatch, useAppSelector } from "../../module/hook";
import { MobileLoading } from "../loading/mobile_loading";
import { loginActions } from "../../module/login";
import { useNavigate } from "react-router-dom";
import { coreActions } from "../../module/core";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import "./mobile_login.css";
import logo from "../../asset/image/logo.png";

export function MobileLogin() {
  const isLoading = useAppSelector((state) => state.core.isLoading);

  return (
    <div className="MobileLogin">
      {isLoading ? <MobileLoading /> : <Body />}
    </div>
  );
}

function Body() {
  return (
    <div className="MobileLogin-Body">
      <Title />
      <EmailInput />
      <PasswordInput />
      <EmailVerification />
      <PasswordReset />
      <SignIn />
      <SignUp />
    </div>
  );
}

function Title() {
  return (
    <div className="MobileLogin-Title">
      <img src={logo} />
      <h1>로그인</h1>
    </div>
  );
}

function EmailInput() {
  const email = useAppSelector((state) => state.login.email);

  const dispatch = useAppDispatch();

  const onEmailChange = (e: any) => {
    const {
      target: { name, value },
    } = e;

    dispatch(loginActions.setEmail(value));
  };

  return (
    <div className="MobileLogin-Email">
      <form>
        <input
          type="text"
          placeholder="아이디(이메일 주소)"
          required
          value={email}
          onChange={onEmailChange}
        />
      </form>
    </div>
  );
}

function PasswordInput() {
  const password = useAppSelector((state) => state.login.password);

  const dispatch = useAppDispatch();

  const onPasswordChange = (e: any) => {
    const {
      target: { name, value },
    } = e;

    dispatch(loginActions.setPassword(value));
  };

  return (
    <div className="MobileLogin-Password">
      <form>
        <input
          type="password"
          placeholder="비밀번호"
          required
          value={password}
          onChange={onPasswordChange}
        />
      </form>
    </div>
  );
}

function EmailVerification() {
  const navigate = useNavigate();

  const onClick = () => {
    navigate("/email_verification");
  };

  return (
    <div className="MobileLogin-EmailVerification">
      <button onClick={onClick}>이메일 재인증</button>
    </div>
  );
}

function PasswordReset() {
  const navigate = useNavigate();

  const onClick = () => {
    navigate("/password_reset");
  };

  return (
    <div className="MobileLogin-PasswordReset">
      <button onClick={onClick}>비밀번호 재설정</button>
    </div>
  );
}

function SignIn() {
  const auth = getAuth();

  const navigate = useNavigate();

  const email = useAppSelector((state) => state.login.email);
  const password = useAppSelector((state) => state.login.password);

  const dispatch = useAppDispatch();

  const onClick = () => {
    dispatch(coreActions.setIsLoading(true));

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;

        dispatch(coreActions.setIsLoading(false));

        navigate("/");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;

        if (errorCode === "auth/invalid-credential") {
          alert(
            "해당 유저를 찾을 수 없습니다. 아이디와 비밀번호를 다시 확인해주세요.",
          );
        } else if (errorCode === "auth/invalid-email") {
          alert("이메일 주소가 올바르지 않습니다.");
        } else if (errorCode === "auth/missing-password") {
          alert("비밀번호를 입력해주세요.");
        } else {
          alert("서버와의 통신에 실패하였습니다.");
        }

        dispatch(coreActions.setIsLoading(false));
      });
  };

  return (
    <div className="MobileLogin-SignIn">
      <button onClick={onClick}>로그인</button>
    </div>
  );
}

function SignUp() {
  const navigate = useNavigate();

  const onClick = () => {
    navigate("/eula");
  };

  return (
    <div className="MobileLogin-SignUp">
      <p>처음이신가요?</p>
      <button onClick={onClick}>회원 가입</button>
    </div>
  );
}
