import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DocumentState } from "./document";

export interface SearchState {
  hashtags: string[];
  documents: DocumentState[];
}

const initialState: SearchState = {
  hashtags: [],
  documents: [],
};

export const search = createSlice({
  name: "search",
  initialState,
  reducers: {
    resetHashtags(state) {
      state.hashtags = [];
    },

    setHashtags(state, action: PayloadAction<string[]>) {
      state.hashtags = action.payload;
    },

    resetDocuments(state) {
      state.documents = [];
    },

    appendDocument(state, action: PayloadAction<DocumentState>) {
      const isEqualTimestamp = (document: DocumentState) =>
        document.timestamp === action.payload.timestamp;

      if (state.documents.findIndex(isEqualTimestamp) >= 0) {
        return;
      } else {
        state.documents = [...state.documents, action.payload].sort((a, b) =>
          a.timestamp < b.timestamp ? 1 : -1,
        );
      }
    },
  },
});

export const searchActions = search.actions;
export default search.reducer;
