import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface PasswordResetState {
  email: string;
}

const initialState: PasswordResetState = {
  email: "",
};

export const passwordReset = createSlice({
  name: "passwordReset",
  initialState,
  reducers: {
    setEmail(state, action: PayloadAction<string>) {
      state.email = action.payload;
    },
  },
});

export const passwordResetActions = passwordReset.actions;
export default passwordReset.reducer;
