import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface WriteState {
  isImageLoading: boolean;
  content: string;
  hashtags: string[];
}

const initialState: WriteState = {
  isImageLoading: false,
  content: "",
  hashtags: [],
};

export const write = createSlice({
  name: "write",
  initialState,
  reducers: {
    setIsImageLoading(state, action: PayloadAction<boolean>) {
      state.isImageLoading = action.payload;
    },

    setContent(state, action: PayloadAction<string>) {
      state.content = action.payload;
    },

    setHashtags(state, action: PayloadAction<string[]>) {
      state.hashtags = action.payload;
    },
  },
});

export const writeActions = write.actions;
export default write.reducer;
