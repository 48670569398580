import { useAppDispatch, useAppSelector } from "../../module/hook";
import { Header } from "../header";
import { Sidebar } from "../sidebar";
import { Loading } from "../loading/loading";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getFirestore,
  increment,
  limit,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  startAfter,
  updateDoc,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { profileViewActions } from "../../module/profile_view";
import defaultAvatar from "../../asset/image/default_avatar.png";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import "./profile_view.css";
import underscore from "../../asset/image/underscore.png";
import { DocumentState } from "../../module/document";
import { UserState } from "../../module/user";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import { coreActions } from "../../module/core";
import parse from "html-react-parser";
import moment from "moment";
import threeDots from "../../asset/image/three_dots.png";
import comment from "../../asset/image/comment.png";
import bookmark from "../../asset/image/bookmark.png";
import bookmarkFocus from "../../asset/image/bookmark_focus.png";
import hashtag from "../../asset/image/hashtag.png";
import heart from "../../asset/image/heart.png";
import { DotsThreeOutline } from "@phosphor-icons/react";
import back from "../../asset/image/back.png";
import upFocus from "../../asset/image/up_focus.png";
import up from "../../asset/image/up.png";
import down from "../../asset/image/down.png";
import downFocus from "../../asset/image/down_focus.png";
import more from "../../asset/image/more.png";
import comingSoon from "../../asset/image/coming_soon.png";

export function ProfileView() {
  const { slug } = useParams();

  const isLoading = useAppSelector((state) => state.core.isLoading);

  const auth = getAuth();
  const firestore = getFirestore();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (slug! !== undefined) {
      dispatch(coreActions.setFocus("profile"));
      dispatch(profileViewActions.setUid(slug!));

      const userDocRef = doc(firestore, "user", slug!);

      getDoc(userDocRef).then((docSnap) => {
        if (docSnap.exists()) {
          if (docSnap.data().hasOwnProperty("avatarUrl")) {
            dispatch(
              profileViewActions.setAvatarUrl(docSnap.data()["avatarUrl"]),
            );
          } else {
            dispatch(profileViewActions.setAvatarUrl(""));
          }

          if (docSnap.data().hasOwnProperty("name")) {
            dispatch(profileViewActions.setName(docSnap.data()["name"]));
          } else {
            dispatch(profileViewActions.setName(""));
          }

          if (docSnap.data().hasOwnProperty("nickname")) {
            dispatch(
              profileViewActions.setNickname(docSnap.data()["nickname"]),
            );
          } else {
            dispatch(profileViewActions.setNickname(""));
          }

          if (docSnap.data().hasOwnProperty("numPosts")) {
            dispatch(
              profileViewActions.setNumPosts(docSnap.data()["numPosts"]),
            );
          } else {
            dispatch(profileViewActions.setNumPosts(0));
          }

          if (docSnap.data().hasOwnProperty("bio")) {
            dispatch(profileViewActions.setBio(docSnap.data()["bio"]));
          } else {
            dispatch(profileViewActions.setBio(""));
          }
        }

        const profileDocRef = doc(firestore, "user", slug!, "profile", slug!);

        getDoc(profileDocRef).then((docSnap) => {
          if (docSnap.exists()) {
            if (docSnap.data().hasOwnProperty("numFollowers")) {
              dispatch(
                profileViewActions.setNumFollowers(
                  docSnap.data()["numFollowers"],
                ),
              );
            } else {
              dispatch(profileViewActions.setNumFollowers(0));
            }

            if (docSnap.data().hasOwnProperty("numFollowings")) {
              dispatch(
                profileViewActions.setNumFollowings(
                  docSnap.data()["numFollowings"],
                ),
              );
            } else {
              dispatch(profileViewActions.setNumFollowings(0));
            }
          }
        });
      });

      if (auth.currentUser !== null) {
        const followingDocRef = doc(
          firestore,
          "user",
          auth.currentUser!.uid,
          "following",
          slug!,
        );

        getDoc(followingDocRef).then((docSnap) => {
          if (docSnap.exists()) {
            dispatch(profileViewActions.setIsFollowing(true));
          } else {
            dispatch(profileViewActions.setIsFollowing(false));
          }
        });
      } else {
        dispatch(profileViewActions.setIsFollowing(false));
      }
    }
  }, [slug!]);

  return (
    <div className="ProfileView">{isLoading ? <Loading /> : <Body />}</div>
  );
}

function Body() {
  return (
    <div className="ProfileView-Body">
      <Header />
      <Sidebar />
      <Title />
      <Main />
    </div>
  );
}

function Title() {
  const nickname = useAppSelector((state) => state.profileView.nickname);

  let title;
  if (nickname !== "") {
    title = `${nickname}의 프로필`;
  } else {
    title = "이름없음의 프로필";
  }

  const navigate = useNavigate();

  const onClick = () => {
    navigate(-1);
  };

  return (
    <div className="ProfileView-Title">
      <img src={back} onClick={onClick} />
      <h1>{title}</h1>
    </div>
  );
}

function Main() {
  return (
    <div className="ProfileView-Main">
      <ProfileMain />
      <Details />
    </div>
  );
}

function ProfileMain() {
  return (
    <div className="ProfileView-ProfileMain">
      <ProfileHeader />
      <Bio />
      <FollowOrUnfollow />
      <Tab />
    </div>
  );
}

function ProfileHeader() {
  return (
    <div className="ProfileView-ProfileHeader">
      <Avatar />
      <Names />
    </div>
  );
}

function Avatar() {
  const avatarUrl = useAppSelector((state) => state.profileView.avatarUrl);

  if (avatarUrl !== "") {
    return (
      <div className="ProfileView-Avatar">
        <img src={avatarUrl} />
      </div>
    );
  } else {
    return (
      <div className="ProfileView-Avatar">
        <img src={defaultAvatar} />
      </div>
    );
  }
}

function Names() {
  return (
    <div className="ProfileView-Names">
      <Nickname />
      <Name />
      <Status />
    </div>
  );
}

function Nickname() {
  const nickname = useAppSelector((state) => state.profileView.nickname);

  if (nickname !== "") {
    return (
      <div className="ProfileView-Nickname">
        <p>{nickname}</p>
      </div>
    );
  } else {
    return (
      <div className="ProfileView-Nickname">
        <p>닉네임 없음</p>
      </div>
    );
  }
}

function Name() {
  const name = useAppSelector((state) => state.profileView.name);

  if (name !== "") {
    return (
      <div className="ProfileView-Name">
        <p>{"@" + name}</p>
      </div>
    );
  } else {
    return (
      <div className="ProfileView-Name">
        <p>닉네임 없음</p>
      </div>
    );
  }
}

function Status() {
  return (
    <div className="ProfileView-Status">
      <NumFollowers />
      <NumFollowings />
      <NumPosts />
    </div>
  );
}

function NumFollowers() {
  const numFollowers = useAppSelector(
    (state) => state.profileView.numFollowers,
  );

  return (
    <div className="ProfileView-NumFollowers">
      <h1>팔로워</h1>
      <p>{numFollowers}</p>
    </div>
  );
}

function NumFollowings() {
  const numFollowings = useAppSelector(
    (state) => state.profileView.numFollowings,
  );

  return (
    <div className="ProfileView-NumFollowings">
      <h1>팔로잉</h1>
      <p>{numFollowings}</p>
    </div>
  );
}

function NumPosts() {
  const numPosts = useAppSelector((state) => state.profileView.numPosts);

  return (
    <div className="ProfileView-NumPosts">
      <h1>게시글</h1>
      <p>{numPosts}</p>
    </div>
  );
}

function Bio() {
  const bio = useAppSelector((state) => state.profileView.bio);

  if (bio !== "") {
    return (
      <div className="ProfileView-Bio">
        <p>{bio}</p>
      </div>
    );
  } else {
    return (
      <div className="ProfileView-Bio">
        <p>자기소개 없음</p>
      </div>
    );
  }
}

function FollowOrUnfollow() {
  const uid = useAppSelector((state) => state.profileView.uid);
  const numFollowers = useAppSelector(
    (state) => state.profileView.numFollowers,
  );
  const isFollowing = useAppSelector((state) => state.profileView.isFollowing);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const auth = getAuth();
  const firestore = getFirestore();

  const onClick = async () => {
    if (auth.currentUser !== null) {
      if (auth.currentUser!.uid === uid) {
        alert("나 자신을 팔로우 할 수 없습니다");
      } else {
        dispatch(coreActions.setIsLoading(true));

        if (isFollowing) {
          const followingRef = doc(
            firestore,
            "user",
            auth.currentUser!.uid,
            "following",
            uid,
          );

          const followerRef = doc(
            firestore,
            "user",
            uid,
            "follower",
            auth.currentUser!.uid,
          );

          const numFollowingsRef = doc(
            firestore,
            "user",
            auth.currentUser!.uid,
            "profile",
            auth.currentUser!.uid,
          );
          const numFollowersRef = doc(firestore, "user", uid, "profile", uid);

          await deleteDoc(followingRef);
          await deleteDoc(followerRef);

          await getDoc(numFollowingsRef).then(async (docSnap) => {
            if (docSnap.exists()) {
              await updateDoc(numFollowingsRef, {
                numFollowings: increment(-1),
              });
            }
          });

          await getDoc(numFollowersRef).then(async (docSnap) => {
            if (docSnap.exists()) {
              await updateDoc(numFollowersRef, {
                numFollowers: increment(-1),
              });
            }
          });

          dispatch(profileViewActions.setNumFollowers(numFollowers - 1));
          dispatch(profileViewActions.setIsFollowing(false));
        } else {
          const followingRef = doc(
            firestore,
            "user",
            auth.currentUser!.uid,
            "following",
            uid,
          );

          const followerRef = doc(
            firestore,
            "user",
            uid,
            "follower",
            auth.currentUser!.uid,
          );

          const numFollowingsRef = doc(
            firestore,
            "user",
            auth.currentUser!.uid,
            "profile",
            auth.currentUser!.uid,
          );
          const numFollowersRef = doc(firestore, "user", uid, "profile", uid);

          const timestamp = Date.now();

          await setDoc(followingRef, {
            uid: uid,
            timestamp: timestamp,
          });

          await setDoc(followerRef, {
            uid: auth.currentUser!.uid,
            timestamp: timestamp,
          });

          await getDoc(numFollowingsRef).then(async (docSnap) => {
            if (docSnap.exists()) {
              await updateDoc(numFollowingsRef, {
                numFollowings: increment(1),
              });
            } else {
              await setDoc(numFollowingsRef, {
                numFollowings: 1,
              });
            }
          });

          await getDoc(numFollowersRef).then(async (docSnap) => {
            if (docSnap.exists()) {
              await updateDoc(numFollowersRef, {
                numFollowers: increment(1),
              });
            } else {
              await setDoc(numFollowersRef, {
                numFollowers: 1,
              });
            }
          });

          dispatch(profileViewActions.setNumFollowers(numFollowers + 1));
          dispatch(profileViewActions.setIsFollowing(true));
        }

        dispatch(coreActions.setIsLoading(false));
      }
    } else {
      navigate("/login");
    }
  };

  if (isFollowing) {
    return (
      <div className="ProfileView-FollowOrUnfollow">
        <button onClick={onClick}>팔로우 중단</button>
      </div>
    );
  } else {
    return (
      <div className="ProfileView-FollowOrUnfollow">
        <button onClick={onClick}>팔로우</button>
      </div>
    );
  }
}

function Tab() {
  return (
    <div className="ProfileView-Tab">
      <TabFollowers />
      <TabFollowings />
      <TabPosts />
    </div>
  );
}

function TabFollowers() {
  const tab = useAppSelector((state) => state.profileView.tab);

  const dispatch = useAppDispatch();

  const onClick = () => {
    dispatch(profileViewActions.setTab("followers"));
  };

  if (tab === "followers") {
    return (
      <div className="ProfileView-TabFollowers-Focus">
        <button onClick={onClick}>팔로워</button>
        <img src={underscore} />
      </div>
    );
  } else {
    return (
      <div className="ProfileView-TabFollowers">
        <button onClick={onClick}>팔로워</button>
      </div>
    );
  }
}

function TabFollowings() {
  const tab = useAppSelector((state) => state.profileView.tab);

  const dispatch = useAppDispatch();

  const onClick = () => {
    dispatch(profileViewActions.setTab("followings"));
  };

  if (tab === "followings") {
    return (
      <div className="ProfileView-TabFollowings-Focus">
        <button onClick={onClick}>팔로잉</button>
        <img src={underscore} />
      </div>
    );
  } else {
    return (
      <div className="ProfileView-TabFollowings">
        <button onClick={onClick}>팔로잉</button>
      </div>
    );
  }
}

function TabPosts() {
  const tab = useAppSelector((state) => state.profileView.tab);

  const dispatch = useAppDispatch();

  const onClick = () => {
    dispatch(profileViewActions.setTab("posts"));
  };

  if (tab === "posts") {
    return (
      <div className="ProfileView-TabPosts-Focus">
        <button onClick={onClick}>게시글</button>
        <img src={underscore} />
      </div>
    );
  } else {
    return (
      <div className="ProfileView-TabPosts">
        <button onClick={onClick}>게시글</button>
      </div>
    );
  }
}

function Details() {
  const tab = useAppSelector((state) => state.profileView.tab);

  if (tab === "followers") {
    return (
      <div className="ProfileView-Details">
        <Followers />
      </div>
    );
  } else if (tab === "followings") {
    return (
      <div className="ProfileView-Details">
        <Followings />
      </div>
    );
  } else {
    return (
      <div className="ProfileView-Details">
        <Posts />
      </div>
    );
  }
}

function Followers() {
  const uid = useAppSelector((state) => state.profileView.uid);

  const followers = useAppSelector((state) => state.profileView.followers);

  const dispatch = useAppDispatch();

  const auth = getAuth();
  const firestore = getFirestore();

  const [lastVisible, setLastVisible] = useState<any>(undefined);

  const getFollowers = () => {
    if (uid !== "") {
      let q;
      if (lastVisible === -1) {
        return;
      } else if (lastVisible !== undefined) {
        q = query(
          collection(firestore, "user", uid, "follower"),
          orderBy("timestamp", "desc"),
          limit(10),
          startAfter(lastVisible),
        );
      } else {
        q = query(
          collection(firestore, "user", uid, "follower"),
          orderBy("timestamp", "desc"),
          limit(10),
        );
      }

      onSnapshot(q, async (snapshot) => {
        const newFollowers = snapshot.docs.map((elem) => elem.data());

        for (const elem of newFollowers) {
          let avatarUrl = "";
          let name = "";
          let nickname = "";
          let isFollowing = false;

          const userDocRef = doc(firestore, "user", elem.uid);

          const docSnap = await getDoc(userDocRef);
          if (docSnap.exists()) {
            const data = docSnap.data();
            avatarUrl = data.avatarUrl || "";
            name = data.name || "";
            nickname = data.nickname || "";
          }

          if (auth.currentUser !== null) {
            const followingDocRef = doc(
              firestore,
              "user",
              auth.currentUser!.uid,
              "following",
              elem.uid,
            );

            await getDoc(followingDocRef).then((docSnap) => {
              if (docSnap.exists()) {
                isFollowing = true;
              }
            });
          }

          const follower: UserState = {
            uid: elem.uid,
            timestamp: elem.timestamp,
            avatarUrl: avatarUrl,
            name: name,
            nickname: nickname,
            isFollowing: isFollowing,
          };

          dispatch(profileViewActions.appendFollower(follower));
        }

        if (snapshot.docs.length === 0) {
          setLastVisible(-1);
        } else {
          setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
        }
      });
    }
  };

  useEffect(() => {
    dispatch(profileViewActions.resetFollowers());

    setLastVisible(undefined);
    getFollowers();
  }, [uid]);

  useBottomScrollListener(getFollowers);

  const f = followers.map((item, _) => {
    return <Follower follower={item} />;
  });

  if (f.length > 0) {
    return <div className="ProfileView-Followers">{f}</div>;
  } else {
    return (
      <div className="ProfileView-Followers-Void">
        <p>팔로워가 없습니다.</p>
      </div>
    );
  }
}

function Follower(props: { follower: UserState }) {
  return (
    <div className="ProfileView-Follower">
      <UserHeader user={props.follower} />
    </div>
  );
}

function Followings() {
  const uid = useAppSelector((state) => state.profileView.uid);
  const followings = useAppSelector((state) => state.profileView.followings);

  const dispatch = useAppDispatch();

  const auth = getAuth();
  const firestore = getFirestore();

  const [lastVisible, setLastVisible] = useState<any>(undefined);

  const getFollowings = () => {
    if (uid !== "") {
      let q;
      if (lastVisible === -1) {
        return;
      } else if (lastVisible !== undefined) {
        q = query(
          collection(firestore, "user", uid, "following"),
          orderBy("timestamp", "desc"),
          limit(10),
          startAfter(lastVisible),
        );
      } else {
        q = query(
          collection(firestore, "user", uid, "following"),
          orderBy("timestamp", "desc"),
          limit(10),
        );
      }

      onSnapshot(q, async (snapshot) => {
        const newFollowings = snapshot.docs.map((elem) => elem.data());

        for (const elem of newFollowings) {
          let avatarUrl = "";
          let name = "";
          let nickname = "";
          let isFollowing = false;

          const userDocRef = doc(firestore, "user", elem.uid);

          const docSnap = await getDoc(userDocRef);
          if (docSnap.exists()) {
            const data = docSnap.data();
            avatarUrl = data.avatarUrl || "";
            name = data.name || "";
            nickname = data.nickname || "";
          }

          if (auth.currentUser !== null) {
            const followingDocRef = doc(
              firestore,
              "user",
              elem.uid,
              "following",
              auth.currentUser!.uid,
            );

            await getDoc(followingDocRef).then((docSnap) => {
              if (docSnap.exists()) {
                isFollowing = true;
              }
            });
          }

          const following: UserState = {
            uid: elem.uid,
            timestamp: elem.timestamp,
            avatarUrl: avatarUrl,
            name: name,
            nickname: nickname,
            isFollowing: isFollowing,
          };

          dispatch(profileViewActions.appendFollowing(following));
        }

        if (snapshot.docs.length === 0) {
          setLastVisible(-1);
        } else {
          setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
        }
      });
    }
  };

  useEffect(() => {
    dispatch(profileViewActions.resetFollowings());

    setLastVisible(undefined);
    getFollowings();
  }, [uid]);

  useBottomScrollListener(getFollowings);

  const f = followings.map((item, _) => {
    return <Following following={item} />;
  });

  if (f.length > 0) {
    return <div className="ProfileView-Followings">{f}</div>;
  } else {
    return (
      <div className="ProfileView-Followings-Void">
        <p>팔로잉이 없습니다.</p>
      </div>
    );
  }
}

function Following(props: { following: UserState }) {
  return (
    <div className="ProfileView-Following">
      <UserHeader user={props.following} />
    </div>
  );
}

function UserHeader(props: { user: UserState }) {
  return (
    <div className="ProfileView-UserHeader">
      <UserAvatar user={props.user} />
      <UserNames user={props.user} />
      <UserFollowOrUnfollow user={props.user} />
    </div>
  );
}

function UserAvatar(props: { user: UserState }) {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(`/profile_view/${props.user.uid}`);
  };

  if (props.user.avatarUrl !== "") {
    return (
      <div className="ProfileView-UserAvatar">
        <img src={props.user.avatarUrl} onClick={onClick} />
      </div>
    );
  } else {
    return (
      <div className="ProfileView-UserAvatar">
        <img src={defaultAvatar} onClick={onClick} />
      </div>
    );
  }
}

function UserNames(props: { user: UserState }) {
  return (
    <div className="ProfileView-UserNames">
      <UserName user={props.user} />
      <UserNickname user={props.user} />
    </div>
  );
}

function UserName(props: { user: UserState }) {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(`/profile_view/${props.user.uid}`);
  };

  if (props.user.name !== "") {
    return (
      <div className="ProfileView-UserName">
        <button onClick={onClick}>{props.user.name}</button>
      </div>
    );
  } else {
    return (
      <div className="ProfileView-UserName">
        <button onClick={onClick}>이름 없음</button>
      </div>
    );
  }
}

function UserNickname(props: { user: UserState }) {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(`/profile_view/${props.user.uid}`);
  };

  if (props.user.nickname !== "") {
    return (
      <div className="ProfileView-UserNickname">
        <button onClick={onClick}>{props.user.nickname}</button>
      </div>
    );
  } else {
    return (
      <div className="ProfileView-UserNickname">
        <button onClick={onClick}>닉네임 없음</button>
      </div>
    );
  }
}

function UserFollowOrUnfollow(props: { user: UserState }) {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const auth = getAuth();
  const firestore = getFirestore();

  const onClick = async () => {
    if (auth.currentUser !== null) {
      if (auth.currentUser!.uid === props.user.uid) {
        alert("나 자신을 팔로우 할 수 없습니다");
      } else {
        dispatch(coreActions.setIsLoading(true));

        if (props.user.isFollowing) {
          const followingRef = doc(
            firestore,
            "user",
            auth.currentUser!.uid,
            "following",
            props.user.uid,
          );

          const followerRef = doc(
            firestore,
            "user",
            props.user.uid,
            "follower",
            auth.currentUser!.uid,
          );

          const numFollowingsRef = doc(
            firestore,
            "user",
            auth.currentUser!.uid,
            "profile",
            auth.currentUser!.uid,
          );
          const numFollowersRef = doc(
            firestore,
            "user",
            props.user.uid,
            "profile",
            props.user.uid,
          );

          await deleteDoc(followingRef);
          await deleteDoc(followerRef);

          await getDoc(numFollowingsRef).then(async (docSnap) => {
            if (docSnap.exists()) {
              await updateDoc(numFollowingsRef, {
                numFollowings: increment(-1),
              });
            }
          });

          await getDoc(numFollowersRef).then(async (docSnap) => {
            if (docSnap.exists()) {
              await updateDoc(numFollowersRef, {
                numFollowers: increment(-1),
              });
            }
          });

          dispatch(profileViewActions.unsetFollowersFollowing(props.user));
        } else {
          const followingRef = doc(
            firestore,
            "user",
            auth.currentUser!.uid,
            "following",
            props.user.uid,
          );

          const followerRef = doc(
            firestore,
            "user",
            props.user.uid,
            "follower",
            auth.currentUser!.uid,
          );

          const numFollowingsRef = doc(
            firestore,
            "user",
            auth.currentUser!.uid,
            "profile",
            auth.currentUser!.uid,
          );
          const numFollowersRef = doc(
            firestore,
            "user",
            props.user.uid,
            "profile",
            props.user.uid,
          );

          const timestamp = Date.now();

          await setDoc(followingRef, {
            uid: props.user.uid,
            timestamp: timestamp,
          });

          await setDoc(followerRef, {
            uid: auth.currentUser!.uid,
            timestamp: timestamp,
          });

          await getDoc(numFollowingsRef).then(async (docSnap) => {
            if (docSnap.exists()) {
              await updateDoc(numFollowingsRef, {
                numFollowings: increment(1),
              });
            } else {
              await setDoc(numFollowingsRef, {
                numFollowings: 1,
              });
            }
          });

          await getDoc(numFollowersRef).then(async (docSnap) => {
            if (docSnap.exists()) {
              await updateDoc(numFollowersRef, {
                numFollowers: increment(1),
              });
            } else {
              await setDoc(numFollowersRef, {
                numFollowers: 1,
              });
            }
          });

          dispatch(profileViewActions.setFollowersFollowing(props.user));
        }

        dispatch(coreActions.setIsLoading(false));
      }
    } else {
      navigate("/login");
    }
  };

  if (props.user.isFollowing) {
    return (
      <div className="ProfileView-UserFollowOrUnfollow">
        <button onClick={onClick}>팔로잉 중단</button>
      </div>
    );
  } else {
    return (
      <div className="ProfileView-UserFollowOrUnfollow">
        <button onClick={onClick}>팔로잉</button>
      </div>
    );
  }
}

function Posts() {
  const uid = useAppSelector((state) => state.profileView.uid);
  const posts = useAppSelector((state) => state.profileView.posts);

  const dispatch = useAppDispatch();

  const firestore = getFirestore();

  const [images, setImages] = useState(new Map());
  const [lastVisible, setLastVisible] = useState<any>(undefined);

  async function reactiveLength(content: any) {
    if (Array.isArray(content) === true) {
      let length = 0;
      for (let child of content) {
        if (Array.isArray(child.props.children)) {
          length += await reactiveLength(child);
        } else {
          if (child.props.children && child.props.children.type === "img") {
            let img;
            if (images.has(child.props.children.props.src)) {
              img = images.get(child.props.children.props.src);
            } else {
              img = new Image();

              img.src = child.props.children.props.src;
              await img.decode();

              images.set(child.props.children.props.src, img);
            }

            if (img.naturalHeight) {
              length += img.naturalHeight;
            } else {
              length += 1;
            }
          } else if (child.props.children && child.props.children.length) {
            length += child.props.children.length;
          } else {
            length += 1;
          }
        }
      }

      setImages(images);
      return length;
    } else if (
      content.hasOwnProperty("props") &&
      content.props.hasOwnProperty("children") &&
      Array.isArray(content.props.children)
    ) {
      let length = 0;
      for (let child of content.props.children) {
        if (
          child.hasOwnProperty("props") &&
          child.props.hasOwnProperty("children") &&
          Array.isArray(child.props.children)
        ) {
          length += await reactiveLength(child);
        } else {
          if (child.type === "img") {
            let img;
            if (images.has(child.props.src)) {
              img = images.get(child.props.src);
            } else {
              img = new Image();

              img.src = child.props.src;
              await img.decode();

              images.set(child.props.src, img);
            }

            if (img.naturalHeight) {
              length += img.naturalHeight;
            } else {
              length += 1;
            }
          }

          length += 1;
        }
      }

      setImages(images);
      return length;
    } else if (
      content.hasOwnProperty("props") &&
      content.props.hasOwnProperty("children") &&
      content.props.children &&
      content.props.children.type === "img"
    ) {
      let img;
      if (images.has(content.props.children.props.src)) {
        img = images.get(content.props.children.props.src);
      } else {
        img = new Image();

        img.src = content.props.children.props.src;
        await img.decode();

        images.set(content.props.children.props.src, img);
        setImages(images);
      }

      if (img.naturalHeight) {
        return img.naturalHeight;
      } else {
        return 1;
      }
    } else {
      return 1;
    }
  }

  async function extractContent(
    content: any,
    pos: number,
    accum: number,
  ): Promise<any> {
    if (Array.isArray(content) === true) {
      for (let i = 0; i < content.length; i++) {
        const child = content[i];

        if (accum <= pos && accum + (await reactiveLength(child)) > pos) {
          return extractContent(child, pos, accum);
        } else if (accum > pos) {
          return;
        } else {
          accum += await reactiveLength(child);
        }
      }
    } else if (
      content.hasOwnProperty("props") &&
      content.props.hasOwnProperty("children") &&
      Array.isArray(content.props.children) === true
    ) {
      for (let i = 0; i < content.props.children.length; i++) {
        const child = content.props.children[i];

        if (accum <= pos && accum + (await reactiveLength(child)) > pos) {
          return extractContent(child, pos, accum);
        } else if (accum > pos) {
          return;
        } else {
          accum += await reactiveLength(child);
        }
      }
    } else {
      if (pos === accum) {
        return content;
      }
    }
  }

  const getPosts = async () => {
    if (uid !== "") {
      let q;
      if (lastVisible === -1) {
        return;
      } else if (lastVisible !== undefined) {
        q = query(
          collection(firestore, "user", uid, "post"),
          orderBy("timestamp", "desc"),
          limit(10),
          startAfter(lastVisible),
        );
      } else {
        q = query(
          collection(firestore, "user", uid, "post"),
          orderBy("timestamp", "desc"),
          limit(10),
        );
      }

      onSnapshot(q, async (snapshot) => {
        const newPosts = snapshot.docs.map((elem) => elem.data());

        for (const elem of newPosts) {
          const boardDocRef = doc(firestore, "board", elem.documentId);

          getDoc(boardDocRef).then(async (boardDocSnap) => {
            let authorAvatarUrl = "";
            let authorNickname = "";

            const boardData = boardDocSnap.data()!;

            const authorUId = boardData.authorUid;

            const userDocRef = doc(firestore, "user", authorUId);

            const userDocSnap = await getDoc(userDocRef);
            if (userDocSnap.exists()) {
              const userData = userDocSnap.data();
              authorAvatarUrl = userData.avatarUrl || "";
              authorNickname = userData.nickname || "";
            }

            const parsedContent = parse(boardData.content);

            const lengthLimit = 700;
            const length = await reactiveLength(parsedContent);

            let thumbnailContent: any[] = [];
            let thumbnailImageSizes: any[] = [];

            if (length >= 100) {
              let index = 0;
              let extractLength = 0;

              let batchedContent = [];

              while (index < 5 && extractLength < lengthLimit) {
                let ec = await extractContent(parsedContent, index, 0);

                if (
                  ec &&
                  ec.props !== undefined &&
                  ec.props.children !== undefined
                ) {
                  if (ec.props.children.type === "img") {
                    let img;
                    if (images.has(ec.props.children.props.src)) {
                      img = images.get(ec.props.children.props.src);
                    } else {
                      img = new Image();

                      img.src = ec.props.children.props.src;
                      await img.decode();

                      images.set(ec.props.children.props.src, img);
                      setImages(images);
                    }

                    if (img.naturalHeight) {
                      if (extractLength + img.naturalHeight > lengthLimit) {
                        thumbnailImageSizes = [
                          ...thumbnailImageSizes,
                          {
                            src: ec.props.children.props.src,
                            width: img.naturalWidth,
                            height: lengthLimit - extractLength,
                          },
                        ];

                        extractLength += lengthLimit - extractLength;
                      } else {
                        thumbnailImageSizes = [
                          ...thumbnailImageSizes,
                          {
                            src: ec.props.children.props.src,
                            width: img.naturalWidth,
                            height: img.naturalHeight,
                          },
                        ];

                        extractLength += img.naturalHeight;
                      }
                    } else {
                      extractLength += 1;
                    }
                  } else if (ec.props.children.length) {
                    extractLength += ec.props.children.length;
                  } else {
                    extractLength += 1;
                  }

                  batchedContent.push(ec);
                } else if (ec && ec.type === "img") {
                  let img;
                  if (images.has(ec.props!.src)) {
                    img = images.get(ec.props.src);
                  } else {
                    img = new Image();

                    img.src = ec.props.src;
                    await img.decode();

                    images.set(ec.props.src, img);
                    setImages(images);
                  }

                  if (img.naturalHeight) {
                    if (extractLength + img.naturalHeight > lengthLimit) {
                      thumbnailImageSizes = [
                        ...thumbnailImageSizes,
                        {
                          src: ec.props.src,
                          width: img.naturalWidth,
                          height: lengthLimit - extractLength,
                        },
                      ];

                      extractLength += lengthLimit - extractLength;
                    } else {
                      thumbnailImageSizes = [
                        ...thumbnailImageSizes,
                        {
                          src: ec.props.src,
                          width: img.naturalWidth,
                          height: img.naturalHeight,
                        },
                      ];

                      extractLength += img.naturalHeight;
                    }
                  } else {
                    extractLength += 1;
                  }

                  batchedContent.push(ec);
                } else if (ec) {
                  extractLength += 1;

                  batchedContent.push(ec);
                } else {
                  extractLength += 1;
                }

                index += 1;
              }

              thumbnailContent = batchedContent;
            } else {
              thumbnailContent = [parsedContent];
            }

            const document: DocumentState = {
              documentId: boardData.documentId,
              authorUid: boardData.authorUid,
              authorAvatarUrl: authorAvatarUrl,
              authorNickname: authorNickname,
              timestamp: boardData.timestamp,
              content: boardData.content,
              thumbnailContent: thumbnailContent,
              thumbnailImageSizes: thumbnailImageSizes,
              hashtags: boardData.hashtags.split(","),
              numUps: 0,
              numDowns: 0,
              numComments: 0,
              numTokens: 0,
            };

            dispatch(profileViewActions.appendPost(document));
          });
        }

        if (snapshot.docs.length === 0) {
          setLastVisible(-1);
        } else {
          setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
        }
      });
    }
  };

  useEffect(() => {
    dispatch(profileViewActions.resetPosts());

    setLastVisible(undefined);
    getPosts();
  }, [uid]);

  useBottomScrollListener(getPosts);

  if (posts.length > 0) {
    return (
      <div className="ProfileView-Posts">
        {posts.map((item, _) => {
          return <Document document={item} />;
        })}
      </div>
    );
  } else {
    return (
      <div className="ProfileView-Posts-Void">
        <p>게시글이 없습니다.</p>
      </div>
    );
  }
}

function Document(props: { document: DocumentState }) {
  if (props.document.hashtags.length > 0 && props.document.hashtags[0] !== "") {
    return (
      <div className="ProfileView-Document">
        <DocumentHeader document={props.document} />
        <ThumbnailContent document={props.document} />
        <Hashtags document={props.document} />
        <ReadMore document={props.document} />
        <Summary document={props.document} />
      </div>
    );
  } else {
    return (
      <div className="ProfileView-Document">
        <DocumentHeader document={props.document} />
        <ThumbnailContent document={props.document} />
        <ReadMore document={props.document} />
        <Summary document={props.document} />
      </div>
    );
  }
}

function DocumentHeader(props: { document: DocumentState }) {
  return (
    <div className="ProfileView-DocumentHeader">
      <DocumentAvatar document={props.document} />
      <DocumentNickname document={props.document} />
      <Time document={props.document} />
      <Bookmark document={props.document} />
      <Report document={props.document} />
    </div>
  );
}

function DocumentAvatar(props: { document: DocumentState }) {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(`/profile_view/${props.document.authorUid}`);
  };

  if (props.document.authorAvatarUrl !== "") {
    return (
      <div className="ProfileView-DocumentAvatar">
        <img src={props.document.authorAvatarUrl} onClick={onClick} />
      </div>
    );
  } else {
    return (
      <div className="ProfileView-DocumentAvatar">
        <img src={defaultAvatar} onClick={onClick} />
      </div>
    );
  }
}

function DocumentNickname(props: { document: DocumentState }) {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(`/profile_view/${props.document.authorUid}`);
  };

  if (props.document.authorNickname !== "") {
    return (
      <div className="ProfileView-DocumentNickname">
        <button onClick={onClick}>{props.document.authorNickname}</button>
      </div>
    );
  } else {
    return (
      <div className="ProfileView-DocumentNickname">
        <button onClick={onClick}>닉네임 없음</button>
      </div>
    );
  }
}

function Time(props: { document: DocumentState }) {
  const endTimestamp = Date.now();

  const beginDate = moment(props.document.timestamp);
  const endDate = moment(endTimestamp);
  const diff = endDate.diff(beginDate);
  const diffDuration = moment.duration(diff);

  if (diffDuration.minutes() < 1) {
    return (
      <div className="ProfileView-Time">
        <p>방금 전</p>
      </div>
    );
  } else if (diffDuration.hours() < 1) {
    return (
      <div className="ProfileView-Time">
        <p>{diffDuration.minutes()} 분 전</p>
      </div>
    );
  } else if (diffDuration.days() < 1) {
    return (
      <div className="ProfileView-Time">
        <p>{diffDuration.hours()} 시간 전</p>
      </div>
    );
  } else {
    return (
      <div className="ProfileView-Time">
        <p>{beginDate.format("YYYY-MM-DD")}</p>
      </div>
    );
  }
}

function Bookmark(props: { document: DocumentState }) {
  const navigate = useNavigate();

  const [click, setClick] = useState(false);

  const auth = getAuth();

  useEffect(() => {
    if (auth.currentUser !== null) {
      const firestore = getFirestore();

      const bookmarkDocRef = doc(
        firestore,
        "user",
        auth.currentUser.uid,
        "bookmark",
        props.document.documentId,
      );
      getDoc(bookmarkDocRef).then((docSnap) => {
        if (docSnap.exists()) {
          setClick(true);
        }
      });
    }
  }, []);

  if (auth.currentUser !== null) {
    const firestore = getFirestore();

    const bookmarkDocRef = doc(
      firestore,
      "user",
      auth.currentUser.uid,
      "bookmark",
      props.document.documentId,
    );

    const onClick = async () => {
      if (click) {
        setClick(false);

        await updateDoc(doc(firestore, "user", auth.currentUser!.uid), {
          numBookmarks: increment(-1),
        });

        await deleteDoc(bookmarkDocRef);
      } else {
        setClick(true);

        await updateDoc(doc(firestore, "user", auth.currentUser!.uid), {
          numBookmarks: increment(1),
        });

        const timestamp = Date.now();

        await setDoc(bookmarkDocRef, {
          documentId: props.document.documentId,
          uid: auth.currentUser!.uid,
          timestamp: timestamp,
        });
      }
    };

    if (click) {
      return (
        <div className="ProfileView-Bookmark">
          <button onClick={onClick}>
            <img src={bookmarkFocus} />
          </button>
        </div>
      );
    } else {
      return (
        <div className="ProfileView-Bookmark">
          <button onClick={onClick}>
            <img src={bookmark} />
          </button>
        </div>
      );
    }
  } else {
    const onClick = () => {
      navigate("/login");
    };

    return (
      <div className="ProfileView-Bookmark">
        <button onClick={onClick}>
          <img src={bookmark} />
        </button>
      </div>
    );
  }
}

function Report(props: { document: DocumentState }) {
  const onClick = () => {};

  return (
    <div className="ProfileView-Report">
      <button onClick={onClick}>
        <img src={threeDots} />
      </button>
    </div>
  );
}

function ThumbnailContent(props: { document: DocumentState }) {
  const navigate = useNavigate();

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );

  const onClick = () => {
    navigate(`/read/${props.document.documentId}`);
    navigate(0);
  };

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="ProfileView-ThumbnailContent">
      <button onClick={onClick}>
        {props.document.thumbnailContent.map((item, _) => {
          if (item.props !== undefined && item.props.children !== undefined) {
            if (item.props.children.type === "img") {
              for (let imageSize of props.document.thumbnailImageSizes) {
                if (imageSize.src === item.props.children.props.src) {
                  if (
                    0.9 * 0.44 * windowDimensions.width - 20 <
                    imageSize.width
                  ) {
                    return (
                      <img
                        style={{
                          width: 0.85 * 0.44 * windowDimensions.width - 20,
                          maxHeight: imageSize.height,
                          objectFit: "cover",
                          objectPosition: "0% 0%",
                          backgroundColor: "transparent",
                        }}
                        src={item.props.children.props.src}
                      />
                    );
                  } else {
                    return (
                      <img
                        style={{
                          width: imageSize.width,
                          maxHeight: imageSize.height,
                          objectFit: "cover",
                          objectPosition: "0% 0%",
                          backgroundColor: "transparent",
                        }}
                        src={item.props.children.props.src}
                      />
                    );
                  }
                }
              }

              return item;
            } else {
              return item;
            }
          } else if (item.type === "img" && item.props !== undefined) {
            for (let imageSize of props.document.thumbnailImageSizes) {
              if (imageSize.src === item.props.src) {
                if (
                  0.9 * 0.44 * windowDimensions.width - 20 <
                  imageSize.width
                ) {
                  return (
                    <img
                      style={{
                        width: 0.85 * 0.44 * windowDimensions.width - 20,
                        maxHeight: imageSize.height,
                        objectFit: "cover",
                        objectPosition: "0% 0%",
                        backgroundColor: "transparent",
                      }}
                      src={item.props.src}
                    />
                  );
                } else {
                  return (
                    <img
                      style={{
                        width: imageSize.width,
                        maxHeight: imageSize.height,
                        objectFit: "cover",
                        objectPosition: "0% 0%",
                        backgroundColor: "transparent",
                      }}
                      src={item.props.src}
                    />
                  );
                }
              }
            }

            return item;
          } else {
            return item;
          }
        })}
      </button>
    </div>
  );
}

function Hashtags(props: { document: DocumentState }) {
  return (
    <div className="ProfileView-Hashtags">
      {
        <ul>
          {props.document.hashtags.map((hashtag, index) => (
            <li key={index}>
              <Hashtag hashtag={hashtag} />
            </li>
          ))}
        </ul>
      }
    </div>
  );
}

function Hashtag(props: { hashtag: string }) {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(`/search_query/${props.hashtag.slice(1)}`);
  };

  return (
    <div className="ProfileView-Hashtag">
      <img src={hashtag} />
      <button className="ProfileView-Hashtag-tag" onClick={onClick}>
        {props.hashtag.slice(1)}
      </button>
    </div>
  );
}

function ReadMore(props: { document: DocumentState }) {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(`/read/${props.document.documentId}`);
    navigate(0);
  };

  return (
    <div className="ProfileView-ReadMore">
      <button onClick={onClick}>
        <img src={more}/>
      </button>
    </div>
  );
}

function Summary(props: { document: DocumentState }) {
  return (
    <div className="ProfileView-Summary">
      <NumUps document={props.document} />
      <NumDowns document={props.document} />
      <NumComments document={props.document} />
      <NumTokens document={props.document} />
    </div>
  );
}

function NumUps(props: { document: DocumentState }) {
  const navigate = useNavigate();

  const [numUps, setNumUps] = useState(props.document.numUps);
  const [click, setClick] = useState(false);

  const auth = getAuth();

  const firestore = getFirestore();

  useEffect(() => {
    const metadataDocRef = doc(
      firestore,
      "metadata",
      props.document.documentId,
    );
    getDoc(metadataDocRef).then((docSnap) => {
      if (docSnap.exists() && docSnap.data().hasOwnProperty("numUps")) {
        setNumUps(docSnap.data()["numUps"]);
      }
    });
  }, []);

  if (auth.currentUser !== null) {
    const upDocRef = doc(
      firestore,
      "metadata",
      props.document.documentId,
      "up",
      auth.currentUser.uid,
    );
    getDoc(upDocRef).then((docSnap) => {
      if (docSnap.exists()) {
        setClick(true);
      }
    });

    const summaryDocRef = doc(firestore, "metadata", props.document.documentId);

    const onClick = async () => {
      if (click) {
        setNumUps(numUps - 1);
        setClick(false);

        await deleteDoc(upDocRef);

        await updateDoc(summaryDocRef, {
          numUps: increment(-1),
        });
      } else {
        setNumUps(numUps + 1);
        setClick(true);

        const upTimestamp = Date.now();

        await setDoc(upDocRef, {
          uid: auth.currentUser!.uid,
          timestamp: upTimestamp,
        });

        await updateDoc(summaryDocRef, {
          numUps: increment(1),
        });
      }
    };

    if (click) {
      return (
        <div className="ProfileView-NumUps-Focus">
          <button onClick={onClick}>
            <img src={upFocus} />
            <p>{numUps}</p>
          </button>
        </div>
      );
    } else {
      return (
        <div className="ProfileView-NumUps">
          <button onClick={onClick}>
            <img src={up} />
            <p>{numUps}</p>
          </button>
        </div>
      );
    }
  } else {
    const onClick = () => {
      navigate("/login");
    };

    return (
      <div className="ProfileView-NumUps">
        <button onClick={onClick}>
          <img src={up} />
          <p>{numUps}</p>
        </button>
      </div>
    );
  }
}

function NumDowns(props: { document: DocumentState }) {
  const navigate = useNavigate();

  const [numDowns, setNumDowns] = useState(props.document.numDowns);
  const [click, setClick] = useState(false);

  const auth = getAuth();

  const firestore = getFirestore();

  useEffect(() => {
    const metadataDocRef = doc(
      firestore,
      "metadata",
      props.document.documentId,
    );
    getDoc(metadataDocRef).then((docSnap) => {
      if (docSnap.exists() && docSnap.data().hasOwnProperty("numDowns")) {
        setNumDowns(docSnap.data()["numDowns"]);
      }
    });
  }, []);

  if (auth.currentUser !== null) {
    const downDocRef = doc(
      firestore,
      "metadata",
      props.document.documentId,
      "down",
      auth.currentUser.uid,
    );
    getDoc(downDocRef).then((docSnap) => {
      if (docSnap.exists()) {
        setClick(true);
      }
    });

    const metadataDocRef = doc(
      firestore,
      "metadata",
      props.document.documentId,
    );

    const onClick = async () => {
      if (click) {
        setNumDowns(numDowns - 1);
        setClick(false);

        await deleteDoc(downDocRef);

        await updateDoc(metadataDocRef, {
          numDowns: increment(-1),
        });
      } else {
        setNumDowns(numDowns + 1);
        setClick(true);

        const upTimestamp = Date.now();

        await setDoc(downDocRef, {
          uid: auth.currentUser!.uid,
          timestamp: upTimestamp,
        });

        await updateDoc(metadataDocRef, {
          numDowns: increment(1),
        });
      }
    };

    if (click) {
      return (
        <div className="ProfileView-NumDowns-Focus">
          <button onClick={onClick}>
            <img src={downFocus} />
            <p>{numDowns}</p>
          </button>
        </div>
      );
    } else {
      return (
        <div className="ProfileView-NumDowns">
          <button onClick={onClick}>
            <img src={down} />
            <p>{numDowns}</p>
          </button>
        </div>
      );
    }
  } else {
    const onClick = () => {
      navigate("/login");
    };

    return (
      <div className="ProfileView-NumDowns">
        <button onClick={onClick}>
          <img src={down} />
          <p>{numDowns}</p>
        </button>
      </div>
    );
  }
}

function NumComments(props: { document: DocumentState }) {
  const navigate = useNavigate();

  const [numComments, setNumComments] = useState(0);

  const firestore = getFirestore();

  const metadataDocRef = doc(firestore, "metadata", props.document.documentId);
  getDoc(metadataDocRef).then((docSnap) => {
    if (docSnap.exists() && docSnap.data().hasOwnProperty("numComments")) {
      setNumComments(docSnap.data()["numComments"]);
    }
  });

  const onClick = () => {
    navigate(`/read/${props.document.documentId}`);
    navigate(0);
  };

  return (
    <div className="ProfileView-NumComments">
      <button onClick={onClick}>
        <img src={comment} />
        <p>{numComments}</p>
      </button>
    </div>
  );
}

function NumTokens(props: { document: DocumentState }) {
  const [numTokens, setNumTokens] = useState(0);

  return (
    <div className="ProfileView-NumTokens">
      <button>
        <p>TOKEN</p>
        <ComingSoon />
      </button>
    </div>
  );
}

function ComingSoon() {
  return (
    <div className="ProfileView-ComingSoon">
      <button>
        <img src={comingSoon} />
      </button>
    </div>
  );
}
