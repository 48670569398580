import { DocumentState } from "../../module/document";
import { searchActions } from "../../module/search";
import { useAppDispatch, useAppSelector } from "../../module/hook";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getFirestore,
  increment,
  limit,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  startAfter,
  updateDoc,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import heart from "../../asset/image/heart.png";
import defaultAvatar from "../../asset/image/default_avatar.png";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import React, { useEffect, useState } from "react";
import "./search.css";
import { Header } from "../header";
import { Sidebar } from "../sidebar";
import moment from "moment";
import parse from "html-react-parser";
import { getAuth } from "firebase/auth";
import threeDots from "../../asset/image/three_dots.png";
import comment from "../../asset/image/comment.png";
import bookmark from "../../asset/image/bookmark.png";
import bookmarkFocus from "../../asset/image/bookmark_focus.png";
import { DotsThreeOutline } from "@phosphor-icons/react";
import hashtag from "../../asset/image/hashtag.png";
import { RightSidebar } from "../right_sidebar";
import upFocus from "../../asset/image/up_focus.png";
import up from "../../asset/image/up.png";
import down from "../../asset/image/down.png";
import downFocus from "../../asset/image/down_focus.png";
import more from "../../asset/image/more.png";
import comingSoon from "../../asset/image/coming_soon.png";

export function Search() {
  const firestore = getFirestore();

  const hashtags = useAppSelector((state) => state.search.hashtags);
  const documents = useAppSelector((state) => state.search.documents);

  const dispatch = useAppDispatch();

  const [images, setImages] = useState(new Map());
  const [lastVisibles, setLastVisibles] = useState(new Map<string, any>());

  async function reactiveLength(content: any) {
    if (Array.isArray(content) === true) {
      let length = 0;
      for (let child of content) {
        if (Array.isArray(child.props.children)) {
          length += await reactiveLength(child);
        } else {
          if (child.props.children && child.props.children.type === "img") {
            let img;
            if (images.has(child.props.children.props.src)) {
              img = images.get(child.props.children.props.src);
            } else {
              img = new Image();

              img.src = child.props.children.props.src;
              await img.decode();

              images.set(child.props.children.props.src, img);
            }

            if (img.naturalHeight) {
              length += img.naturalHeight;
            } else {
              length += 1;
            }
          } else if (child.props.children && child.props.children.length) {
            length += child.props.children.length;
          } else {
            length += 1;
          }
        }
      }

      setImages(images);
      return length;
    } else if (
      content.hasOwnProperty("props") &&
      content.props.hasOwnProperty("children") &&
      Array.isArray(content.props.children)
    ) {
      let length = 0;
      for (let child of content.props.children) {
        if (
          child.hasOwnProperty("props") &&
          child.props.hasOwnProperty("children") &&
          Array.isArray(child.props.children)
        ) {
          length += await reactiveLength(child);
        } else {
          if (child.type === "img") {
            let img;
            if (images.has(child.props.src)) {
              img = images.get(child.props.src);
            } else {
              img = new Image();

              img.src = child.props.src;
              await img.decode();

              images.set(child.props.src, img);
            }

            if (img.naturalHeight) {
              length += img.naturalHeight;
            } else {
              length += 1;
            }
          }

          length += 1;
        }
      }

      setImages(images);
      return length;
    } else if (
      content.hasOwnProperty("props") &&
      content.props.hasOwnProperty("children") &&
      content.props.children &&
      content.props.children.type === "img"
    ) {
      let img;
      if (images.has(content.props.children.props.src)) {
        img = images.get(content.props.children.props.src);
      } else {
        img = new Image();

        img.src = content.props.children.props.src;
        await img.decode();

        images.set(content.props.children.props.src, img);
        setImages(images);
      }

      if (img.naturalHeight) {
        return img.naturalHeight;
      } else {
        return 1;
      }
    } else {
      return 1;
    }
  }

  async function extractContent(
    content: any,
    pos: number,
    accum: number,
  ): Promise<any> {
    if (Array.isArray(content) === true) {
      for (let i = 0; i < content.length; i++) {
        const child = content[i];

        if (accum <= pos && accum + (await reactiveLength(child)) > pos) {
          return extractContent(child, pos, accum);
        } else if (accum > pos) {
          return;
        } else {
          accum += await reactiveLength(child);
        }
      }
    } else if (
      content.hasOwnProperty("props") &&
      content.props.hasOwnProperty("children") &&
      Array.isArray(content.props.children) === true
    ) {
      for (let i = 0; i < content.props.children.length; i++) {
        const child = content.props.children[i];

        if (accum <= pos && accum + (await reactiveLength(child)) > pos) {
          return extractContent(child, pos, accum);
        } else if (accum > pos) {
          return;
        } else {
          accum += await reactiveLength(child);
        }
      }
    } else {
      if (pos === accum) {
        return content;
      }
    }
  }

  const getDocuments = async () => {
    for (const hashtag of hashtags) {
      let q;
      if (lastVisibles.get(hashtag) === -1) {
        return;
      } else if (lastVisibles.get(hashtag) !== undefined) {
        q = query(
          collection(firestore, "hashtag", hashtag, "post"),
          orderBy("timestamp", "desc"),
          limit(10),
          startAfter(lastVisibles.get(hashtag)),
        );
      } else {
        q = query(
          collection(firestore, "hashtag", hashtag, "post"),
          orderBy("timestamp", "desc"),
          limit(10),
        );
      }

      onSnapshot(q, async (snapshot) => {
        const newDocuments = snapshot.docs.map((elem) => elem.data());

        for (const elem of newDocuments) {
          const docRef = doc(firestore, "board", elem.documentId);

          getDoc(docRef).then(async (docSnap) => {
            let authorAvatarUrl = "";
            let authorNickname = "";

            const userDocRef = doc(
              firestore,
              "user",
              docSnap.data()!.authorUid,
            );

            const userDocSnap = await getDoc(userDocRef);
            if (userDocSnap.exists()) {
              const data = userDocSnap.data();
              authorAvatarUrl = data.avatarUrl || "";
              authorNickname = data.nickname || "";
            }

            const parsedContent = parse(docSnap.data()!.content);

            const lengthLimit = 700;
            const length = await reactiveLength(parsedContent);

            let thumbnailContent: any[] = [];
            let thumbnailImageSizes: any[] = [];

            if (length >= 100) {
              let index = 0;
              let extractLength = 0;

              let batchedContent = [];

              while (index < 5 && extractLength < lengthLimit) {
                let ec = await extractContent(parsedContent, index, 0);

                if (
                  ec &&
                  ec.props !== undefined &&
                  ec.props.children !== undefined
                ) {
                  if (ec.props.children.type === "img") {
                    let img;
                    if (images.has(ec.props.children.props.src)) {
                      img = images.get(ec.props.children.props.src);
                    } else {
                      img = new Image();

                      img.src = ec.props.children.props.src;
                      await img.decode();

                      images.set(ec.props.children.props.src, img);
                      setImages(images);
                    }

                    if (img.naturalHeight) {
                      if (extractLength + img.naturalHeight > lengthLimit) {
                        thumbnailImageSizes = [
                          ...thumbnailImageSizes,
                          {
                            src: ec.props.children.props.src,
                            width: img.naturalWidth,
                            height: lengthLimit - extractLength,
                          },
                        ];

                        extractLength += lengthLimit - extractLength;
                      } else {
                        thumbnailImageSizes = [
                          ...thumbnailImageSizes,
                          {
                            src: ec.props.children.props.src,
                            width: img.naturalWidth,
                            height: img.naturalHeight,
                          },
                        ];

                        extractLength += img.naturalHeight;
                      }
                    } else {
                      extractLength += 1;
                    }
                  } else if (ec.props.children.length) {
                    extractLength += ec.props.children.length;
                  } else {
                    extractLength += 1;
                  }

                  batchedContent.push(ec);
                } else if (ec && ec.type === "img") {
                  let img;
                  if (images.has(ec.props!.src)) {
                    img = images.get(ec.props.src);
                  } else {
                    img = new Image();

                    img.src = ec.props.src;
                    await img.decode();

                    images.set(ec.props.src, img);
                    setImages(images);
                  }

                  if (img.naturalHeight) {
                    if (extractLength + img.naturalHeight > lengthLimit) {
                      thumbnailImageSizes = [
                        ...thumbnailImageSizes,
                        {
                          src: ec.props.src,
                          width: img.naturalWidth,
                          height: lengthLimit - extractLength,
                        },
                      ];

                      extractLength += lengthLimit - extractLength;
                    } else {
                      thumbnailImageSizes = [
                        ...thumbnailImageSizes,
                        {
                          src: ec.props.src,
                          width: img.naturalWidth,
                          height: img.naturalHeight,
                        },
                      ];

                      extractLength += img.naturalHeight;
                    }
                  } else {
                    extractLength += 1;
                  }

                  batchedContent.push(ec);
                } else if (ec) {
                  extractLength += 1;

                  batchedContent.push(ec);
                } else {
                  extractLength += 1;
                }

                index += 1;
              }

              thumbnailContent = batchedContent;
            } else {
              thumbnailContent = [parsedContent];
            }

            const document: DocumentState = {
              documentId: docSnap.data()!.documentId,
              authorUid: docSnap.data()!.authorUid,
              authorAvatarUrl: authorAvatarUrl,
              authorNickname: authorNickname,
              timestamp: docSnap.data()!.timestamp,
              content: docSnap.data()!.content,
              thumbnailContent: thumbnailContent,
              thumbnailImageSizes: thumbnailImageSizes,
              hashtags: docSnap.data()!.hashtags.split(","),
              numUps: 0,
              numDowns: 0,
              numComments: 0,
              numTokens: 0,
            };

            dispatch(searchActions.appendDocument(document));
          });

          if (snapshot.docs.length === 0) {
            lastVisibles.set(hashtag, -1);

            setLastVisibles(lastVisibles);
          } else {
            lastVisibles.set(hashtag, snapshot.docs[snapshot.docs.length - 1]);

            setLastVisibles(lastVisibles);
          }
        }
      });
    }
  };

  useEffect(() => {
    for (const hashtag of hashtags) {
      lastVisibles.set(hashtag, undefined);
      setLastVisibles(lastVisibles);
    }

    getDocuments();
  }, [hashtags]);

  useBottomScrollListener(getDocuments);

  return (
    <div className="Search">
      <Body documents={documents} />
    </div>
  );
}

function Body(props: { documents: DocumentState[] }) {
  return (
    <div className="Search-Body">
      <Header />
      <Sidebar />
      <Title />
      <SearchBar />
      <SearchHashtag />
      <Documents documents={props.documents} />
      <RightSidebar />
    </div>
  );
}

function Title() {
  return (
    <div className="Search-Title">
      <h1>검색</h1>
    </div>
  );
}

function SearchBar() {
  const hashtags = useAppSelector((state) => state.search.hashtags);

  const dispatch = useAppDispatch();

  const addHashtag = (event: any) => {
    dispatch(searchActions.resetHashtags());
    dispatch(searchActions.resetDocuments());

    const inputVal = event.target.value;

    if (
      event.key === "Enter" &&
      inputVal !== "" &&
      !hashtags.includes(inputVal)
    ) {
      if (hashtags.length > 1) {
        alert("해시태그는 최대 1개까지만 지정할 수 있습니다.");

        event.target.value = "";
      } else {
        if (inputVal[0] !== "#") {
          dispatch(searchActions.setHashtags([...hashtags, "#" + inputVal]));
        } else {
          dispatch(searchActions.setHashtags([...hashtags, inputVal]));
        }

        event.target.value = "";
      }
    }
  };

  return (
    <div className="Search-SearchBar">
      <img src={hashtag} />
      {
        <input
          type="text"
          onKeyUp={(e) => addHashtag(e)}
          placeholder="태그입력(최대 1개)"
        />
      }
    </div>
  );
}

function SearchHashtag() {
  const hashtags = useAppSelector((state) => state.search.hashtags);

  const dispatch = useAppDispatch();

  const removeHashtag = (indexToRemove: any) => {
    dispatch(searchActions.resetHashtags());
    dispatch(searchActions.resetDocuments());

    const filter = hashtags.filter((element, index) => index !== indexToRemove);
    dispatch(searchActions.setHashtags(filter));
  };

  return (
    <div className="Search-SearchHashtags">
      {
        <ul>
          {hashtags.map((hashtag, index) => (
            <li key={index}>
              <span
                className="Search-SearchHashtags-tag"
                onClick={() => removeHashtag(index)}
              >
                {hashtag}
              </span>
            </li>
          ))}
        </ul>
      }
    </div>
  );
}

function Documents(props: { documents: DocumentState[] }) {
  const documents = props.documents.map((item, _) => {
    return <Document document={item} />;
  });

  return <div className="Search-Documents">{documents}</div>;
}

function Document(props: { document: DocumentState }) {
  if (props.document.hashtags.length > 0 && props.document.hashtags[0] !== "") {
    return (
      <div className="Search-Document">
        <DocumentHeader document={props.document} />
        <ThumbnailContent document={props.document} />
        <Hashtags document={props.document} />
        <ReadMore document={props.document} />
        <Summary document={props.document} />
      </div>
    );
  } else {
    return (
      <div className="Search-Document">
        <DocumentHeader document={props.document} />
        <ThumbnailContent document={props.document} />
        <ReadMore document={props.document} />
        <Summary document={props.document} />
      </div>
    );
  }
}

function DocumentHeader(props: { document: DocumentState }) {
  return (
    <div className="Search-DocumentHeader">
      <Avatar document={props.document} />
      <Nickname document={props.document} />
      <Time document={props.document} />
      <Bookmark document={props.document} />
      <Report document={props.document} />
    </div>
  );
}

function Avatar(props: { document: DocumentState }) {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(`/profile_view/${props.document.authorUid}`);
  };

  if (props.document.authorAvatarUrl !== "") {
    return (
      <div className="Search-Avatar">
        <img src={props.document.authorAvatarUrl} onClick={onClick} />
      </div>
    );
  } else {
    return (
      <div className="Search-Avatar">
        <img src={defaultAvatar} onClick={onClick} />
      </div>
    );
  }
}

function Nickname(props: { document: DocumentState }) {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(`/profile_view/${props.document.authorUid}`);
  };

  if (props.document.authorNickname !== "") {
    return (
      <div className="Search-Nickname">
        <button onClick={onClick}>{props.document.authorNickname}</button>
      </div>
    );
  } else {
    return (
      <div className="Search-Nickname">
        <button onClick={onClick}>닉네임 없음</button>
      </div>
    );
  }
}

function Time(props: { document: DocumentState }) {
  const endTimestamp = Date.now();

  const beginDate = moment(props.document.timestamp);
  const endDate = moment(endTimestamp);
  const diff = endDate.diff(beginDate);
  const diffDuration = moment.duration(diff);

  if (diffDuration.minutes() < 1) {
    return (
      <div className="Search-Time">
        <p>방금 전</p>
      </div>
    );
  } else if (diffDuration.hours() < 1) {
    return (
      <div className="Search-Time">
        <p>{diffDuration.minutes()} 분 전</p>
      </div>
    );
  } else if (diffDuration.days() < 1) {
    return (
      <div className="Search-Time">
        <p>{diffDuration.hours()} 시간 전</p>
      </div>
    );
  } else {
    return (
      <div className="Search-Time">
        <p>{beginDate.format("YYYY-MM-DD")}</p>
      </div>
    );
  }
}

function Bookmark(props: { document: DocumentState }) {
  const navigate = useNavigate();

  const [click, setClick] = useState(false);

  const auth = getAuth();

  useEffect(() => {
    if (auth.currentUser !== null) {
      const firestore = getFirestore();

      const bookmarkDocRef = doc(
        firestore,
        "user",
        auth.currentUser.uid,
        "bookmark",
        props.document.documentId,
      );
      getDoc(bookmarkDocRef).then((docSnap) => {
        if (docSnap.exists()) {
          setClick(true);
        }
      });
    }
  }, []);

  if (auth.currentUser !== null) {
    const firestore = getFirestore();

    const bookmarkDocRef = doc(
      firestore,
      "user",
      auth.currentUser.uid,
      "bookmark",
      props.document.documentId,
    );

    const onClick = async () => {
      if (click) {
        setClick(false);

        await updateDoc(doc(firestore, "user", auth.currentUser!.uid), {
          numBookmarks: increment(-1),
        });

        await deleteDoc(bookmarkDocRef);
      } else {
        setClick(true);

        await updateDoc(doc(firestore, "user", auth.currentUser!.uid), {
          numBookmarks: increment(1),
        });

        const timestamp = Date.now();

        await setDoc(bookmarkDocRef, {
          documentId: props.document.documentId,
          uid: auth.currentUser!.uid,
          timestamp: timestamp,
        });
      }
    };

    if (click) {
      return (
        <div className="Search-Bookmark">
          <button onClick={onClick}>
            <img src={bookmarkFocus} />
          </button>
        </div>
      );
    } else {
      return (
        <div className="Search-Bookmark">
          <button onClick={onClick}>
            <img src={bookmark} />
          </button>
        </div>
      );
    }
  } else {
    const onClick = () => {
      navigate("/login");
    };

    return (
      <div className="Search-Bookmark">
        <button onClick={onClick}>
          <img src={bookmark} />
        </button>
      </div>
    );
  }
}

function Report(props: { document: DocumentState }) {
  const onClick = () => {};

  return (
    <div className="Search-Report">
      <button onClick={onClick}>
        <img src={threeDots} />
      </button>
    </div>
  );
}

function ThumbnailContent(props: { document: DocumentState }) {
  const navigate = useNavigate();

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );

  const onClick = () => {
    navigate(`/read/${props.document.documentId}`);
  };

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="Search-ThumbnailContent">
      <button onClick={onClick}>
        {props.document.thumbnailContent.map((item, _) => {
          if (item.props !== undefined && item.props.children !== undefined) {
            if (item.props.children.type === "img") {
              for (let imageSize of props.document.thumbnailImageSizes) {
                if (imageSize.src === item.props.children.props.src) {
                  if (
                    0.9 * 0.44 * windowDimensions.width - 20 <
                    imageSize.width
                  ) {
                    return (
                      <img
                        style={{
                          width: 0.85 * 0.44 * windowDimensions.width - 20,
                          maxHeight: imageSize.height,
                          objectFit: "cover",
                          objectPosition: "0% 0%",
                          backgroundColor: "transparent",
                        }}
                        src={item.props.children.props.src}
                      />
                    );
                  } else {
                    return (
                      <img
                        style={{
                          width: imageSize.width,
                          maxHeight: imageSize.height,
                          objectFit: "cover",
                          objectPosition: "0% 0%",
                          backgroundColor: "transparent",
                        }}
                        src={item.props.children.props.src}
                      />
                    );
                  }
                }
              }

              return item;
            } else {
              return item;
            }
          } else if (item.type === "img" && item.props !== undefined) {
            for (let imageSize of props.document.thumbnailImageSizes) {
              if (imageSize.src === item.props.src) {
                if (
                  0.9 * 0.44 * windowDimensions.width - 20 <
                  imageSize.width
                ) {
                  return (
                    <img
                      style={{
                        width: 0.85 * 0.44 * windowDimensions.width - 20,
                        maxHeight: imageSize.height,
                        objectFit: "cover",
                        objectPosition: "0% 0%",
                        backgroundColor: "transparent",
                      }}
                      src={item.props.src}
                    />
                  );
                } else {
                  return (
                    <img
                      style={{
                        width: imageSize.width,
                        maxHeight: imageSize.height,
                        objectFit: "cover",
                        objectPosition: "0% 0%",
                        backgroundColor: "transparent",
                      }}
                      src={item.props.src}
                    />
                  );
                }
              }
            }

            return item;
          } else {
            return item;
          }
        })}
      </button>
    </div>
  );
}

function Hashtags(props: { document: DocumentState }) {
  return (
    <div className="Search-Hashtags">
      {
        <ul>
          {props.document.hashtags.map((hashtag, index) => (
            <li key={index}>
              <Hashtag hashtag={hashtag} />
            </li>
          ))}
        </ul>
      }
    </div>
  );
}

function Hashtag(props: { hashtag: string }) {
  return (
    <div className="Search-Hashtag">
      <img src={hashtag} />
      <button className="Search-Hashtag-tag">{props.hashtag.slice(1)}</button>
    </div>
  );
}

function ReadMore(props: { document: DocumentState }) {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(`/read/${props.document.documentId}`);
  };

  return (
    <div className="Search-ReadMore">
      <button onClick={onClick}>
        <img src={more} />
      </button>
    </div>
  );
}

function Summary(props: { document: DocumentState }) {
  return (
    <div className="Search-Summary">
      <NumUps document={props.document} />
      <NumDowns document={props.document} />
      <NumComments document={props.document} />
      <NumTokens document={props.document} />
    </div>
  );
}

function NumUps(props: { document: DocumentState }) {
  const navigate = useNavigate();

  const [numUps, setNumUps] = useState(props.document.numUps);
  const [click, setClick] = useState(false);

  const auth = getAuth();

  const firestore = getFirestore();

  useEffect(() => {
    const metadataDocRef = doc(
      firestore,
      "metadata",
      props.document.documentId,
    );

    getDoc(metadataDocRef).then((docSnap) => {
      if (docSnap.exists() && docSnap.data().hasOwnProperty("numUps")) {
        setNumUps(docSnap.data()["numUps"]);
      }
    });
  }, []);

  if (auth.currentUser !== null) {
    const upDocRef = doc(
      firestore,
      "metadata",
      props.document.documentId,
      "up",
      auth.currentUser.uid,
    );

    getDoc(upDocRef).then((docSnap) => {
      if (docSnap.exists()) {
        setClick(true);
      }
    });

    const summaryDocRef = doc(firestore, "metadata", props.document.documentId);

    const onClick = async () => {
      if (click) {
        setNumUps(numUps - 1);
        setClick(false);

        await deleteDoc(upDocRef);

        await updateDoc(summaryDocRef, {
          numUps: increment(-1),
        });
      } else {
        setNumUps(numUps + 1);
        setClick(true);

        const upTimestamp = Date.now();

        await setDoc(upDocRef, {
          uid: auth.currentUser!.uid,
          timestamp: upTimestamp,
        });

        await updateDoc(summaryDocRef, {
          numUps: increment(1),
        });
      }
    };

    if (click) {
      return (
        <div className="Search-NumUps-Focus">
          <button onClick={onClick}>
            <img src={upFocus} />
            <p>{numUps}</p>
          </button>
        </div>
      );
    } else {
      return (
        <div className="Search-NumUps">
          <button onClick={onClick}>
            <img src={up} />
            <p>{numUps}</p>
          </button>
        </div>
      );
    }
  } else {
    const onClick = () => {
      navigate("/login");
    };

    return (
      <div className="Search-NumUps">
        <button onClick={onClick}>
          <img src={up} />
          <p>{numUps}</p>
        </button>
      </div>
    );
  }
}

function NumDowns(props: { document: DocumentState }) {
  const navigate = useNavigate();

  const [numDowns, setNumDowns] = useState(props.document.numDowns);
  const [click, setClick] = useState(false);

  const auth = getAuth();

  const firestore = getFirestore();

  useEffect(() => {
    const metadataDocRef = doc(
      firestore,
      "metadata",
      props.document.documentId,
    );

    getDoc(metadataDocRef).then((docSnap) => {
      if (docSnap.exists() && docSnap.data().hasOwnProperty("numDowns")) {
        setNumDowns(docSnap.data()["numDowns"]);
      }
    });
  }, []);

  if (auth.currentUser !== null) {
    const downDocRef = doc(
      firestore,
      "metadata",
      props.document.documentId,
      "down",
      auth.currentUser.uid,
    );

    getDoc(downDocRef).then((docSnap) => {
      if (docSnap.exists()) {
        setClick(true);
      }
    });

    const metadataDocRef = doc(
      firestore,
      "metadata",
      props.document.documentId,
    );

    const onClick = async () => {
      if (click) {
        setNumDowns(numDowns - 1);
        setClick(false);

        await deleteDoc(downDocRef);

        await updateDoc(metadataDocRef, {
          numDowns: increment(-1),
        });
      } else {
        setNumDowns(numDowns + 1);
        setClick(true);

        const upTimestamp = Date.now();

        await setDoc(downDocRef, {
          uid: auth.currentUser!.uid,
          timestamp: upTimestamp,
        });

        await updateDoc(metadataDocRef, {
          numDowns: increment(1),
        });
      }
    };

    if (click) {
      return (
        <div className="Search-NumDowns-Focus">
          <button onClick={onClick}>
            <img src={downFocus} />
            <p>{numDowns}</p>
          </button>
        </div>
      );
    } else {
      return (
        <div className="Search-NumDowns">
          <button onClick={onClick}>
            <img src={down} />
            <p>{numDowns}</p>
          </button>
        </div>
      );
    }
  } else {
    const onClick = () => {
      navigate("/login");
    };

    return (
      <div className="Search-NumDowns">
        <button onClick={onClick}>
          <img src={down} />
          <p>{numDowns}</p>
        </button>
      </div>
    );
  }
}

function NumComments(props: { document: DocumentState }) {
  const navigate = useNavigate();

  const [numComments, setNumComments] = useState(0);

  const firestore = getFirestore();

  const metadataDocRef = doc(firestore, "metadata", props.document.documentId);
  getDoc(metadataDocRef).then((docSnap) => {
    if (docSnap.exists() && docSnap.data().hasOwnProperty("numComments")) {
      setNumComments(docSnap.data()["numComments"]);
    }
  });

  const onClick = () => {
    navigate(`/read/${props.document.documentId}`);
  };

  return (
    <div className="Search-NumComments">
      <button onClick={onClick}>
        <img src={comment} />
        <p>{numComments}</p>
      </button>
    </div>
  );
}

function NumTokens(props: { document: DocumentState }) {
  const [numTokens, setNumTokens] = useState(0);

  return (
    <div className="Search-NumTokens">
      <button>
        <p>TOKEN</p>
        <ComingSoon />
      </button>
    </div>
  );
}

function ComingSoon() {
  return (
    <div className="Search-ComingSoon">
      <button>
        <img src={comingSoon} />
      </button>
    </div>
  );
}
