import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MessageSummaryState } from "./message_summary";

export interface NotificationState {
  messageSummaries: MessageSummaryState[];
}

const initialState: NotificationState = {
  messageSummaries: [],
};

export const notification = createSlice({
  name: "notification",
  initialState,
  reducers: {
    resetMessageSummaries(state) {
      state.messageSummaries = [];
    },

    appendMessageSummary(state, action: PayloadAction<MessageSummaryState>) {
      const isEqualTimestamp = (message: MessageSummaryState) =>
        message.timestamp === action.payload.timestamp;

      if (state.messageSummaries.findIndex(isEqualTimestamp) >= 0) {
        return;
      } else {
        state.messageSummaries = [
          ...state.messageSummaries,
          action.payload,
        ].sort((a, b) => {
          return a.timestamp < b.timestamp ? 1 : -1;
        });
      }
    },
  },
});

export const notificationActions = notification.actions;
export default notification.reducer;
