import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DocumentState } from "./document";
import { UserState } from "./user";

export interface ProfileState {
  isEditing: boolean;
  avatarUrl: string;
  tmpAvatarUrl: string;
  name: string;
  nickname: string;
  tmpNickname: string;
  numFollowers: number;
  numFollowings: number;
  numPosts: number;
  numBookmarks: number;
  bio: string;
  tmpBio: string;
  tab: string;
  followers: UserState[];
  followings: UserState[];
  posts: DocumentState[];
  bookmarks: DocumentState[];
}

const initialState: ProfileState = {
  isEditing: false,
  avatarUrl: "",
  tmpAvatarUrl: "",
  name: "",
  nickname: "",
  tmpNickname: "",
  numFollowers: 0,
  numFollowings: 0,
  numPosts: 0,
  numBookmarks: 0,
  bio: "",
  tmpBio: "",
  tab: "followers",
  followers: [],
  followings: [],
  posts: [],
  bookmarks: [],
};

export const profile = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setIsEditing(state, action: PayloadAction<boolean>) {
      state.isEditing = action.payload;
    },

    setAvatarUrl(state, action: PayloadAction<string>) {
      state.avatarUrl = action.payload;
    },

    setTmpAvatarUrl(state, action: PayloadAction<string>) {
      state.tmpAvatarUrl = action.payload;
    },

    setName(state, action: PayloadAction<string>) {
      state.name = action.payload;
    },

    setNickname(state, action: PayloadAction<string>) {
      state.nickname = action.payload;
    },

    setTmpNickname(state, action: PayloadAction<string>) {
      state.tmpNickname = action.payload;
    },

    setNumFollowers(state, action: PayloadAction<number>) {
      state.numFollowers = action.payload;
    },

    setNumFollowings(state, action: PayloadAction<number>) {
      state.numFollowings = action.payload;
    },

    setNumPosts(state, action: PayloadAction<number>) {
      state.numPosts = action.payload;
    },

    setNumBookmarks(state, action: PayloadAction<number>) {
      state.numBookmarks = action.payload;
    },

    setBio(state, action: PayloadAction<string>) {
      state.bio = action.payload;
    },

    setTmpBio(state, action: PayloadAction<string>) {
      state.tmpBio = action.payload;
    },

    setTab(state, action: PayloadAction<string>) {
      state.tab = action.payload;
    },

    resetFollowers(state) {
      state.followers = [];
    },

    appendFollower(state, action: PayloadAction<UserState>) {
      const isEqualTimestamp = (follower: UserState) =>
        follower.timestamp === action.payload.timestamp;

      if (state.followers.findIndex(isEqualTimestamp) >= 0) {
        return;
      } else {
        state.followers = [...state.followers, action.payload].sort((a, b) =>
          a.timestamp < b.timestamp ? 1 : -1,
        );
      }
    },

    resetFollowings(state) {
      state.followings = [];
    },

    appendFollowing(state, action: PayloadAction<UserState>) {
      const isEqualTimestamp = (following: UserState) =>
        following.timestamp === action.payload.timestamp;

      if (state.followings.findIndex(isEqualTimestamp) >= 0) {
        return;
      } else {
        state.followings = [...state.followings, action.payload].sort((a, b) =>
          a.timestamp < b.timestamp ? 1 : -1,
        );
      }
    },

    deleteFollowing(state, action: PayloadAction<UserState>) {
      const isEqualUid = (following: UserState) =>
        following.uid === action.payload.uid;

      const index = state.followings.findIndex(isEqualUid);

      if (index >= 0) {
        state.followings = [
          ...state.followings.slice(0, index),
          ...state.followings.slice(index + 1, state.followings.length),
        ];
      }
    },

    resetPosts(state) {
      state.posts = [];
    },

    appendPost(state, action: PayloadAction<DocumentState>) {
      const isEqualTimestamp = (post: DocumentState) =>
        post.timestamp === action.payload.timestamp;

      if (state.posts.findIndex(isEqualTimestamp) >= 0) {
        return;
      } else {
        state.posts = [...state.posts, action.payload].sort((a, b) =>
          a.timestamp < b.timestamp ? 1 : -1,
        );
      }
    },

    resetBookmarks(state) {
      state.bookmarks = [];
    },

    appendBookmark(state, action: PayloadAction<DocumentState>) {
      const isEqualTimestamp = (bookmark: DocumentState) =>
        bookmark.timestamp === action.payload.timestamp;

      if (state.bookmarks.findIndex(isEqualTimestamp) >= 0) {
        return;
      } else {
        state.bookmarks = [...state.bookmarks, action.payload].sort((a, b) =>
          a.timestamp < b.timestamp ? 1 : -1,
        );
      }
    },

    setFollowersFollowing(state, action: PayloadAction<UserState>) {
      const isEqualUid = (user: UserState) => user.uid === action.payload.uid;

      const index = state.followers.findIndex(isEqualUid);

      if (index >= 0) {
        state.followers[index] = {
          ...state.followers[index],
          isFollowing: true,
        };
      }
    },

    unsetFollowersFollowing(state, action: PayloadAction<UserState>) {
      const isEqualUid = (user: UserState) => user.uid === action.payload.uid;

      const index = state.followers.findIndex(isEqualUid);

      if (index >= 0) {
        state.followers[index] = {
          ...state.followers[index],
          isFollowing: false,
        };
      }
    },
  },
});

export const profileActions = profile.actions;
export default profile.reducer;
