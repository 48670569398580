import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DocumentState } from "./document";
import { CommentState } from "./comment";
import { ReplyState } from "./reply";

export interface ReadState {
  document: DocumentState;
  comments: CommentState[];
  documents: DocumentState[];
  clickedComment: string | null;
  replies: ReplyState[];
}

const initialState: ReadState = {
  document: {
    documentId: "",
    authorUid: "",
    authorAvatarUrl: "",
    authorNickname: "",
    timestamp: 0,
    content: "",
    thumbnailContent: [],
    thumbnailImageSizes: [],
    hashtags: [],
    numUps: 0,
    numDowns: 0,
    numComments: 0,
    numTokens: 0,
  },
  comments: [],
  documents: [],
  clickedComment: null,
  replies: [],
};

export const read = createSlice({
  name: "read",
  initialState,
  reducers: {
    resetDocument(state) {
      state.document = {
        documentId: "",
        authorUid: "",
        authorAvatarUrl: "",
        authorNickname: "",
        timestamp: 0,
        content: "",
        thumbnailContent: [],
        thumbnailImageSizes: [],
        hashtags: [],
        numUps: 0,
        numDowns: 0,
        numComments: 0,
        numTokens: 0,
      };
    },

    setDocument(state, action: PayloadAction<DocumentState>) {
      state.document = action.payload;
    },

    setNumUps(state, action: PayloadAction<number>) {
      state.document.numUps = action.payload;
    },

    setNumDowns(state, action: PayloadAction<number>) {
      state.document.numDowns = action.payload;
    },

    setNumComments(state, action: PayloadAction<number>) {
      state.document.numComments = action.payload;
    },

    appendComment(state, action: PayloadAction<CommentState>) {
      const isEqualTimestamp = (comment: CommentState) =>
        comment.timestamp === action.payload.timestamp;

      if (state.comments.findIndex(isEqualTimestamp) >= 0) {
        return;
      } else {
        state.comments = [...state.comments, action.payload].sort((a, b) =>
          a.timestamp < b.timestamp ? 1 : -1,
        );
      }
    },

    resetComments(state) {
      state.comments = [];
    },

    resetDocuments(state) {
      state.documents = [];
    },

    appendDocument(state, action: PayloadAction<DocumentState>) {
      const isEqualTimestamp = (document: DocumentState) =>
        document.timestamp === action.payload.timestamp;

      if (state.documents.findIndex(isEqualTimestamp) >= 0) {
        return;
      } else {
        state.documents = [...state.documents, action.payload].sort((a, b) =>
          a.timestamp < b.timestamp ? 1 : -1,
        );
      }
    },

    setClickedComment(state, action: PayloadAction<string>) {
      state.clickedComment = action.payload;
    },

    unsetClickedComment(state) {
      state.clickedComment = null;
    },

    resetReplies(state) {
      state.replies = [];
    },

    appendReply(state, action: PayloadAction<ReplyState>) {
      const isEqualTimestamp = (reply: ReplyState) =>
        reply.timestamp === action.payload.timestamp;

      if (state.replies.findIndex(isEqualTimestamp) >= 0) {
        return;
      } else {
        state.replies = [...state.replies, action.payload].sort((a, b) =>
          a.timestamp < b.timestamp ? 1 : -1,
        );
      }
    },
  },
});

export const readActions = read.actions;
export default read.reducer;
