import { ReplyState } from "../../module/reply";
import { notificationViewActions } from "../../module/notification_view";
import { coreActions } from "../../module/core";
import { useAppDispatch, useAppSelector } from "../../module/hook";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getFirestore,
  increment,
  limit,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  startAfter,
  updateDoc,
} from "firebase/firestore";
import { useLocation, useNavigate } from "react-router-dom";
import heart from "../../asset/image/heart.png";
import defaultAvatar from "../../asset/image/default_avatar.png";
import React, { useEffect, useState } from "react";
import "./notification_view.css";
import { Header } from "../header";
import { Sidebar } from "../sidebar";
import moment from "moment";
import { getAuth } from "firebase/auth";
import threeDots from "../../asset/image/three_dots.png";
import comment from "../../asset/image/comment.png";
import bookmark from "../../asset/image/bookmark.png";
import bookmarkFocus from "../../asset/image/bookmark_focus.png";
import { DotsThreeOutline } from "@phosphor-icons/react";
import hashtag from "../../asset/image/hashtag.png";
import { RightSidebar } from "../right_sidebar";
import reply from "../../asset/image/reply.png";
import uuid from "react-uuid";
import { Loading } from "../loading/loading";
import parse from "html-react-parser";
import { MessageState } from "../../module/message";
import upFocus from "../../asset/image/up_focus.png";
import up from "../../asset/image/up.png";
import down from "../../asset/image/down.png";
import downFocus from "../../asset/image/down_focus.png";
import more from "../../asset/image/more.png";
import comingSoon from "../../asset/image/coming_soon.png";

export function NotificationView() {
  const location = useLocation();

  const firestore = getFirestore();

  const isLoading = useAppSelector((state) => state.core.isLoading);

  const dispatch = useAppDispatch();

  const [images, setImages] = useState(new Map());

  async function reactiveLength(content: any) {
    if (Array.isArray(content) === true) {
      let length = 0;
      for (let child of content) {
        if (Array.isArray(child.props.children)) {
          length += await reactiveLength(child);
        } else {
          if (child.props.children && child.props.children.type === "img") {
            let img;
            if (images.has(child.props.children.props.src)) {
              img = images.get(child.props.children.props.src);
            } else {
              img = new Image();

              img.src = child.props.children.props.src;
              await img.decode();

              images.set(child.props.children.props.src, img);
            }

            if (img.naturalHeight) {
              length += img.naturalHeight;
            } else {
              length += 1;
            }
          } else if (child.props.children && child.props.children.length) {
            length += child.props.children.length;
          } else {
            length += 1;
          }
        }
      }

      setImages(images);
      return length;
    } else if (
      content.hasOwnProperty("props") &&
      content.props.hasOwnProperty("children") &&
      Array.isArray(content.props.children)
    ) {
      let length = 0;
      for (let child of content.props.children) {
        if (
          child.hasOwnProperty("props") &&
          child.props.hasOwnProperty("children") &&
          Array.isArray(child.props.children)
        ) {
          length += await reactiveLength(child);
        } else {
          if (child.type === "img") {
            let img;
            if (images.has(child.props.src)) {
              img = images.get(child.props.src);
            } else {
              img = new Image();

              img.src = child.props.src;
              await img.decode();

              images.set(child.props.src, img);
            }

            if (img.naturalHeight) {
              length += img.naturalHeight;
            } else {
              length += 1;
            }
          }

          length += 1;
        }
      }

      setImages(images);
      return length;
    } else if (
      content.hasOwnProperty("props") &&
      content.props.hasOwnProperty("children") &&
      content.props.children &&
      content.props.children.type === "img"
    ) {
      let img;
      if (images.has(content.props.children.props.src)) {
        img = images.get(content.props.children.props.src);
      } else {
        img = new Image();

        img.src = content.props.children.props.src;
        await img.decode();

        images.set(content.props.children.props.src, img);
        setImages(images);
      }

      if (img.naturalHeight) {
        return img.naturalHeight;
      } else {
        return 1;
      }
    } else {
      return 1;
    }
  }

  async function extractContent(
    content: any,
    pos: number,
    accum: number,
  ): Promise<any> {
    if (Array.isArray(content) === true) {
      for (let i = 0; i < content.length; i++) {
        const child = content[i];

        if (accum <= pos && accum + (await reactiveLength(child)) > pos) {
          return extractContent(child, pos, accum);
        } else if (accum > pos) {
          return;
        } else {
          accum += await reactiveLength(child);
        }
      }
    } else if (
      content.hasOwnProperty("props") &&
      content.props.hasOwnProperty("children") &&
      Array.isArray(content.props.children) === true
    ) {
      for (let i = 0; i < content.props.children.length; i++) {
        const child = content.props.children[i];

        if (accum <= pos && accum + (await reactiveLength(child)) > pos) {
          return extractContent(child, pos, accum);
        } else if (accum > pos) {
          return;
        } else {
          accum += await reactiveLength(child);
        }
      }
    } else {
      if (pos === accum) {
        return content;
      }
    }
  }

  const getMessage = async () => {
    let documentAuthorUid = "";
    let documentTimestamp = 0;
    let documentContent = "";
    let documentHashtags = [];

    const documentDocSnap = await getDoc(
      doc(firestore, "board", location.state.documentId),
    );
    if (documentDocSnap.exists()) {
      const data = documentDocSnap.data();
      documentAuthorUid = data.authorUid || "";
      documentTimestamp = data.timestamp || "";
      documentContent = data.content || "";
      documentHashtags = data.hashtags.split(",") || [];
    }

    let documentAuthorAvatarUrl = "";
    let documentAuthorNickname = "";

    const documentAuthorDocSnap = await getDoc(
      doc(firestore, "user", documentAuthorUid),
    );
    if (documentAuthorDocSnap.exists()) {
      const data = documentAuthorDocSnap.data();
      documentAuthorAvatarUrl = data.avatarUrl || "";
      documentAuthorNickname = data.nickname || "";
    }

    let commentAuthorUid = "";
    let commentTimestamp = 0;
    let commentContent = "";

    const commentDocSnap = await getDoc(
      doc(
        firestore,
        "board",
        location.state.documentId,
        "comment",
        location.state.commentId,
      ),
    );
    if (commentDocSnap.exists()) {
      const data = commentDocSnap.data();
      commentAuthorUid = data.authorUid || "";
      commentTimestamp = data.timestamp || "";
      commentContent = data.content || "";
    }

    let commentAuthorAvatarUrl = "";
    let commentAuthorNickname = "";

    const commentAuthorDocSnap = await getDoc(
      doc(firestore, "user", commentAuthorUid),
    );
    if (commentAuthorDocSnap.exists()) {
      const data = commentAuthorDocSnap.data();
      commentAuthorAvatarUrl = data.avatarUrl || "";
      commentAuthorNickname = data.nickname || "";
    }

    const parsedContent = parse(documentContent);

    const lengthLimit = 700;
    const length = await reactiveLength(parsedContent);

    let thumbnailContent: any[] = [];
    let thumbnailImageSizes: any[] = [];

    if (length >= 100) {
      let index = 0;
      let extractLength = 0;

      let batchedContent = [];

      while (index < 5 && extractLength < lengthLimit) {
        let ec = await extractContent(parsedContent, index, 0);

        if (ec && ec.props !== undefined && ec.props.children !== undefined) {
          if (ec.props.children.type === "img") {
            let img;
            if (images.has(ec.props.children.props.src)) {
              img = images.get(ec.props.children.props.src);
            } else {
              img = new Image();

              img.src = ec.props.children.props.src;
              await img.decode();

              images.set(ec.props.children.props.src, img);
              setImages(images);
            }

            if (img.naturalHeight) {
              if (extractLength + img.naturalHeight > lengthLimit) {
                thumbnailImageSizes = [
                  ...thumbnailImageSizes,
                  {
                    src: ec.props.children.props.src,
                    width: img.naturalWidth,
                    height: lengthLimit - extractLength,
                  },
                ];

                extractLength += lengthLimit - extractLength;
              } else {
                thumbnailImageSizes = [
                  ...thumbnailImageSizes,
                  {
                    src: ec.props.children.props.src,
                    width: img.naturalWidth,
                    height: img.naturalHeight,
                  },
                ];

                extractLength += img.naturalHeight;
              }
            } else {
              extractLength += 1;
            }
          } else if (ec.props.children.length) {
            extractLength += ec.props.children.length;
          } else {
            extractLength += 1;
          }

          batchedContent.push(ec);
        } else if (ec && ec.type === "img") {
          let img;
          if (images.has(ec.props!.src)) {
            img = images.get(ec.props.src);
          } else {
            img = new Image();

            img.src = ec.props.src;
            await img.decode();

            images.set(ec.props.src, img);
            setImages(images);
          }

          if (img.naturalHeight) {
            if (extractLength + img.naturalHeight > lengthLimit) {
              thumbnailImageSizes = [
                ...thumbnailImageSizes,
                {
                  src: ec.props.src,
                  width: img.naturalWidth,
                  height: lengthLimit - extractLength,
                },
              ];

              extractLength += lengthLimit - extractLength;
            } else {
              thumbnailImageSizes = [
                ...thumbnailImageSizes,
                {
                  src: ec.props.src,
                  width: img.naturalWidth,
                  height: img.naturalHeight,
                },
              ];

              extractLength += img.naturalHeight;
            }
          } else {
            extractLength += 1;
          }

          batchedContent.push(ec);
        } else if (ec) {
          extractLength += 1;

          batchedContent.push(ec);
        } else {
          extractLength += 1;
        }

        index += 1;
      }

      thumbnailContent = batchedContent;
    } else {
      thumbnailContent = [parsedContent];
    }

    const message: MessageState = {
      messageType: location.state.messageType,

      documentId: location.state.documentId,
      documentAuthorUid: documentAuthorUid,
      documentAuthorAvatarUrl: documentAuthorAvatarUrl,
      documentAuthorNickname: documentAuthorNickname,
      documentTimestamp: documentTimestamp,
      documentContent: documentContent,
      documentThumbnailContent: thumbnailContent,
      documentThumbnailImageSizes: thumbnailImageSizes,
      documentHashtags: documentHashtags,
      documentNumUps: 0,
      documentNumDowns: 0,
      documentNumComments: 0,
      documentNumTokens: 0,

      commentId: location.state.commentId,
      commentAuthorUid: commentAuthorUid,
      commentAuthorAvatarUrl: commentAuthorAvatarUrl,
      commentAuthorNickname: commentAuthorNickname,
      commentTimestamp: commentTimestamp,
      commentContent: commentContent,
      commentNumUps: 0,
      commentNumDowns: 0,

      replyId: location.state.replyId,
    };

    dispatch(notificationViewActions.setMessage(message));
  };

  useEffect(() => {
    dispatch(notificationViewActions.resetMessage());
    dispatch(notificationViewActions.setDocumentId(location.state.documentId));
    dispatch(notificationViewActions.setCommentId(location.state.commentId));
    dispatch(notificationViewActions.setReplyId(location.state.replyId));
    getMessage();
  }, []);

  return (
    <div className="NotificationView">{isLoading ? <Loading /> : <Body />}</div>
  );
}

function Body() {
  return (
    <div className="NotificationView-Body">
      <Header />
      <Sidebar />
      <Title />
      <Message />
      <RightSidebar />
    </div>
  );
}

function Title() {
  return (
    <div className="NotificationView-Title">
      <h1>알림 보기</h1>
    </div>
  );
}

function Message() {
  return (
    <div className="NotificationView-Message">
      <Document />
      <Comment />
    </div>
  );
}

function Document() {
  const message = useAppSelector((state) => state.notificationView.message);

  if (
    message.documentHashtags.length > 0 &&
    message.documentHashtags[0] !== ""
  ) {
    return (
      <div className="NotificationView-Document">
        <DocumentHeader />
        <ThumbnailContent />
        <Hashtags />
        <ReadMore />
        <Summary />
      </div>
    );
  } else {
    return (
      <div className="NotificationView-Document">
        <DocumentHeader />
        <ThumbnailContent />
        <ReadMore />
        <Summary />
      </div>
    );
  }
}

function DocumentHeader() {
  return (
    <div className="NotificationView-DocumentHeader">
      <Avatar />
      <Nickname />
      <Time />
      <Bookmark />
      <Report />
    </div>
  );
}

function Avatar() {
  const message = useAppSelector((state) => state.notificationView.message);

  const navigate = useNavigate();

  const onClick = () => {
    navigate(`/profile_view/${message.documentAuthorUid}`);
  };

  if (message.documentAuthorAvatarUrl !== "") {
    return (
      <div className="NotificationView-Avatar">
        <img src={message.documentAuthorAvatarUrl} onClick={onClick} />
      </div>
    );
  } else {
    return (
      <div className="NotificationView-Avatar">
        <img src={defaultAvatar} onClick={onClick} />
      </div>
    );
  }
}

function Nickname() {
  const navigate = useNavigate();

  const message = useAppSelector((state) => state.notificationView.message);

  const onClick = () => {
    navigate(`/profile_view/${message.documentAuthorUid}`);
  };

  if (message.documentAuthorNickname !== "") {
    return (
      <div className="NotificationView-Nickname">
        <button onClick={onClick}>{message.documentAuthorNickname}</button>
      </div>
    );
  } else {
    return (
      <div className="NotificationView-Nickname">
        <button onClick={onClick}>닉네임 없음</button>
      </div>
    );
  }
}

function Time() {
  const message = useAppSelector((state) => state.notificationView.message);

  const endTimestamp = Date.now();

  const beginDate = moment(message.documentTimestamp);
  const endDate = moment(endTimestamp);
  const diff = endDate.diff(beginDate);
  const diffDuration = moment.duration(diff);

  if (diffDuration.minutes() < 1) {
    return (
      <div className="NotificationView-Time">
        <p>방금 전</p>
      </div>
    );
  } else if (diffDuration.hours() < 1) {
    return (
      <div className="NotificationView-Time">
        <p>{diffDuration.minutes()} 분 전</p>
      </div>
    );
  } else if (diffDuration.days() < 1) {
    return (
      <div className="NotificationView-Time">
        <p>{diffDuration.hours()} 시간 전</p>
      </div>
    );
  } else {
    return (
      <div className="NotificationView-Time">
        <p>{beginDate.format("YYYY-MM-DD")}</p>
      </div>
    );
  }
}

function Bookmark() {
  const navigate = useNavigate();

  const message = useAppSelector((state) => state.notificationView.message);

  const [click, setClick] = useState(false);

  const auth = getAuth();

  useEffect(() => {
    if (message.documentId !== "" && auth.currentUser !== null) {
      const firestore = getFirestore();

      const bookmarkDocRef = doc(
        firestore,
        "user",
        auth.currentUser.uid,
        "bookmark",
        message.documentId,
      );
      getDoc(bookmarkDocRef).then((docSnap) => {
        if (docSnap.exists()) {
          setClick(true);
        }
      });
    }
  }, []);

  if (message.documentId !== "" && auth.currentUser !== null) {
    const firestore = getFirestore();

    const bookmarkDocRef = doc(
      firestore,
      "user",
      auth.currentUser.uid,
      "bookmark",
      message.documentId,
    );

    const onClick = async () => {
      if (click) {
        setClick(false);

        await updateDoc(doc(firestore, "user", auth.currentUser!.uid), {
          numBookmarks: increment(-1),
        });

        await deleteDoc(bookmarkDocRef);
      } else {
        setClick(true);

        await updateDoc(doc(firestore, "user", auth.currentUser!.uid), {
          numBookmarks: increment(1),
        });

        const timestamp = Date.now();

        await setDoc(bookmarkDocRef, {
          documentId: message.documentId,
          uid: auth.currentUser!.uid,
          timestamp: timestamp,
        });
      }
    };

    if (click) {
      return (
        <div className="NotificationView-Bookmark">
          <button onClick={onClick}>
            <img src={bookmarkFocus} />
          </button>
        </div>
      );
    } else {
      return (
        <div className="NotificationView-Bookmark">
          <button onClick={onClick}>
            <img src={bookmark} />
          </button>
        </div>
      );
    }
  } else {
    const onClick = () => {
      navigate("/login");
    };

    return (
      <div className="NotificationView-Bookmark">
        <button onClick={onClick}>
          <img src={bookmark} />
        </button>
      </div>
    );
  }
}

function Report() {
  const message = useAppSelector((state) => state.notificationView.message);

  const onClick = () => {};

  return (
    <div className="NotificationView-Report">
      <button onClick={onClick}>
        <img src={threeDots} />
      </button>
    </div>
  );
}

function ThumbnailContent() {
  const navigate = useNavigate();

  const message = useAppSelector((state) => state.notificationView.message);

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );

  const onClick = () => {
    navigate(`/read/${message.documentId}`);
    navigate(0);
  };

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="NotificationView-ThumbnailContent">
      <button onClick={onClick}>
        {message.documentThumbnailContent.map((item, _) => {
          if (item.props !== undefined && item.props.children !== undefined) {
            if (item.props.children.type === "img") {
              for (let imageSize of message.documentThumbnailImageSizes) {
                if (imageSize.src === item.props.children.props.src) {
                  if (
                    0.9 * 0.44 * windowDimensions.width - 20 <
                    imageSize.width
                  ) {
                    return (
                      <img
                        style={{
                          width: 0.85 * 0.44 * windowDimensions.width - 20,
                          maxHeight: imageSize.height,
                          objectFit: "cover",
                          objectPosition: "0% 0%",
                          backgroundColor: "transparent",
                        }}
                        src={item.props.children.props.src}
                      />
                    );
                  } else {
                    return (
                      <img
                        style={{
                          width: imageSize.width,
                          maxHeight: imageSize.height,
                          objectFit: "cover",
                          objectPosition: "0% 0%",
                          backgroundColor: "transparent",
                        }}
                        src={item.props.children.props.src}
                      />
                    );
                  }
                }
              }

              return item;
            } else {
              return item;
            }
          } else if (item.type === "img" && item.props !== undefined) {
            for (let imageSize of message.documentThumbnailImageSizes) {
              if (imageSize.src === item.props.src) {
                if (
                  0.9 * 0.44 * windowDimensions.width - 20 <
                  imageSize.width
                ) {
                  return (
                    <img
                      style={{
                        width: 0.85 * 0.44 * windowDimensions.width - 20,
                        maxHeight: imageSize.height,
                        objectFit: "cover",
                        objectPosition: "0% 0%",
                        backgroundColor: "transparent",
                      }}
                      src={item.props.src}
                    />
                  );
                } else {
                  return (
                    <img
                      style={{
                        width: imageSize.width,
                        maxHeight: imageSize.height,
                        objectFit: "cover",
                        objectPosition: "0% 0%",
                        backgroundColor: "transparent",
                      }}
                      src={item.props.src}
                    />
                  );
                }
              }
            }

            return item;
          } else {
            return item;
          }
        })}
      </button>
    </div>
  );
}

function Hashtags() {
  const message = useAppSelector((state) => state.notificationView.message);

  return (
    <div className="NotificationView-Hashtags">
      {
        <ul>
          {message.documentHashtags.map((hashtag, index) => (
            <li key={index}>
              <Hashtag hashtag={hashtag} />
            </li>
          ))}
        </ul>
      }
    </div>
  );
}

function Hashtag(props: { hashtag: string }) {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(`/search_query/${props.hashtag.slice(1)}`);
  };

  return (
    <div className="NotificationView-Hashtag">
      <img src={hashtag} />
      <button className="NotificationView-Hashtag-tag" onClick={onClick}>
        {props.hashtag.slice(1)}
      </button>
    </div>
  );
}

function ReadMore() {
  const navigate = useNavigate();

  const message = useAppSelector((state) => state.notificationView.message);

  const onClick = () => {
    navigate(`/read/${message.documentId}`);
    navigate(0);
  };

  return (
    <div className="NotificationView-ReadMore">
      <button onClick={onClick}>
        <img src={more}/>
      </button>
    </div>
  );
}

function Summary() {
  return (
    <div className="NotificationView-Summary">
      <NumUps />
      <NumDowns />
      <NumComments />
      <NumTokens />
    </div>
  );
}

function NumUps() {
  const navigate = useNavigate();

  const documentId = useAppSelector(
    (state) => state.notificationView.documentId,
  );
  const commentId = useAppSelector((state) => state.notificationView.commentId);
  const replyId = useAppSelector((state) => state.notificationView.replyId);
  const message = useAppSelector((state) => state.notificationView.message);

  const [numUps, setNumUps] = useState(message.documentNumUps);
  const [click, setClick] = useState(false);

  const auth = getAuth();

  const firestore = getFirestore();

  useEffect(() => {
    if (message.documentId !== "") {
      const metadataDocRef = doc(firestore, "metadata", message.documentId);

      getDoc(metadataDocRef).then((docSnap) => {
        if (docSnap.exists() && docSnap.data().hasOwnProperty("numUps")) {
          setNumUps(docSnap.data()["numUps"]);
        }
      });
    }
  }, [documentId, commentId, replyId]);

  if (message.documentId !== "" && auth.currentUser !== null) {
    const upDocRef = doc(
      firestore,
      "metadata",
      message.documentId,
      "up",
      auth.currentUser.uid,
    );

    getDoc(upDocRef).then((docSnap) => {
      if (docSnap.exists()) {
        setClick(true);
      }
    });

    const summaryDocRef = doc(firestore, "metadata", message.documentId);

    const onClick = async () => {
      if (click) {
        setNumUps(numUps - 1);
        setClick(false);

        await deleteDoc(upDocRef);

        await updateDoc(summaryDocRef, {
          numUps: increment(-1),
        });
      } else {
        setNumUps(numUps + 1);
        setClick(true);

        const upTimestamp = Date.now();

        await setDoc(upDocRef, {
          uid: auth.currentUser!.uid,
          timestamp: upTimestamp,
        });

        await updateDoc(summaryDocRef, {
          numUps: increment(1),
        });
      }
    };

    if (click) {
      return (
        <div className="NotificationView-NumUps-Focus">
          <button onClick={onClick}>
            <img src={upFocus} />
            <p>{numUps}</p>
          </button>
        </div>
      );
    } else {
      return (
        <div className="NotificationView-NumUps">
          <button onClick={onClick}>
            <img src={up} />
            <p>{numUps}</p>
          </button>
        </div>
      );
    }
  } else {
    const onClick = () => {
      navigate("/login");
    };

    return (
      <div className="NotificationView-NumUps">
        <button onClick={onClick}>
          <img src={up} />
          <p>{numUps}</p>
        </button>
      </div>
    );
  }
}

function NumDowns() {
  const navigate = useNavigate();

  const documentId = useAppSelector(
    (state) => state.notificationView.documentId,
  );
  const commentId = useAppSelector((state) => state.notificationView.commentId);
  const replyId = useAppSelector((state) => state.notificationView.replyId);
  const message = useAppSelector((state) => state.notificationView.message);

  const [numDowns, setNumDowns] = useState(message.documentNumDowns);
  const [click, setClick] = useState(false);

  const auth = getAuth();

  const firestore = getFirestore();

  useEffect(() => {
    if (message.documentId !== "") {
      const metadataDocRef = doc(firestore, "metadata", message.documentId);

      getDoc(metadataDocRef).then((docSnap) => {
        if (docSnap.exists() && docSnap.data().hasOwnProperty("numDowns")) {
          setNumDowns(docSnap.data()["numDowns"]);
        }
      });
    }
  }, [documentId, commentId, replyId]);

  if (message.documentId !== "" && auth.currentUser !== null) {
    const downDocRef = doc(
      firestore,
      "metadata",
      message.documentId,
      "down",
      auth.currentUser.uid,
    );

    getDoc(downDocRef).then((docSnap) => {
      if (docSnap.exists()) {
        setClick(true);
      }
    });

    const metadataDocRef = doc(firestore, "metadata", message.documentId);

    const onClick = async () => {
      if (click) {
        setNumDowns(numDowns - 1);
        setClick(false);

        await deleteDoc(downDocRef);

        await updateDoc(metadataDocRef, {
          numDowns: increment(-1),
        });
      } else {
        setNumDowns(numDowns + 1);
        setClick(true);

        const upTimestamp = Date.now();

        await setDoc(downDocRef, {
          uid: auth.currentUser!.uid,
          timestamp: upTimestamp,
        });

        await updateDoc(metadataDocRef, {
          numDowns: increment(1),
        });
      }
    };

    if (click) {
      return (
        <div className="NotificationView-NumDowns-Focus">
          <button onClick={onClick}>
            <img src={downFocus} />
            <p>{numDowns}</p>
          </button>
        </div>
      );
    } else {
      return (
        <div className="NotificationView-NumDowns">
          <button onClick={onClick}>
            <img src={down} />
            <p>{numDowns}</p>
          </button>
        </div>
      );
    }
  } else {
    const onClick = () => {
      navigate("/login");
    };

    return (
      <div className="NotificationView-NumDowns">
        <button onClick={onClick}>
          <img src={down} />
          <p>{numDowns}</p>
        </button>
      </div>
    );
  }
}

function NumComments() {
  const navigate = useNavigate();

  const documentId = useAppSelector(
    (state) => state.notificationView.documentId,
  );
  const commentId = useAppSelector((state) => state.notificationView.commentId);
  const replyId = useAppSelector((state) => state.notificationView.replyId);
  const message = useAppSelector((state) => state.notificationView.message);

  const [numComments, setNumComments] = useState(0);

  const firestore = getFirestore();

  useEffect(() => {
    if (message.documentId !== "") {
      const metadataDocRef = doc(firestore, "metadata", message.documentId);
      getDoc(metadataDocRef).then((docSnap) => {
        if (docSnap.exists() && docSnap.data().hasOwnProperty("numComments")) {
          setNumComments(docSnap.data()["numComments"]);
        }
      });
    }
  }, [documentId, commentId, replyId]);

  const onClick = () => {
    navigate(`/read/${message.documentId}`);
    navigate(0);
  };

  return (
    <div className="NotificationView-NumComments">
      <button onClick={onClick}>
        <img src={comment} />
        <p>{numComments}</p>
      </button>
    </div>
  );
}

function NumTokens() {
  const documentId = useAppSelector(
    (state) => state.notificationView.documentId,
  );
  const commentId = useAppSelector((state) => state.notificationView.commentId);
  const replyId = useAppSelector((state) => state.notificationView.replyId);
  const message = useAppSelector((state) => state.notificationView.message);

  const [numTokens, setNumTokens] = useState(0);

  return (
    <div className="NotificationView-NumTokens">
      <button>
        <p>TOKEN</p>
        <ComingSoon />
      </button>
    </div>
  );
}

function ComingSoon() {
  return (
    <div className="NotificationView-ComingSoon">
      <button>
        <img src={comingSoon} />
      </button>
    </div>
  );
}

function Comment() {
  return (
    <div className="NotificationView-Comment">
      <CommentHeader />
      <CommentBody />
      <CommentSummary />
      <ReplyInput />
      <Replies />
    </div>
  );
}

function CommentHeader() {
  return (
    <div className="NotificationView-CommentHeader">
      <CommentAvatar />
      <CommentNickname />
      <CommentTime />
      <CommentReport />
    </div>
  );
}

function CommentAvatar() {
  const navigate = useNavigate();

  const message = useAppSelector((state) => state.notificationView.message);

  const onClick = () => {
    navigate(`/profile_view/${message.commentAuthorUid}`);
  };

  if (message.commentAuthorAvatarUrl !== "") {
    return (
      <div className="NotificationView-CommentAvatar">
        <img src={message.commentAuthorAvatarUrl} onClick={onClick} />
      </div>
    );
  } else {
    return (
      <div className="NotificationView-CommentAvatar">
        <img src={defaultAvatar} onClick={onClick} />
      </div>
    );
  }
}

function CommentTime() {
  const message = useAppSelector((state) => state.notificationView.message);

  const endTimestamp = Date.now();

  const beginDate = moment(message.commentTimestamp);
  const endDate = moment(endTimestamp);
  const diff = endDate.diff(beginDate);
  const diffDuration = moment.duration(diff);

  if (diffDuration.minutes() < 1) {
    return (
      <div className="NotificationView-CommentTime">
        <p>방금 전</p>
      </div>
    );
  } else if (diffDuration.hours() < 1) {
    return (
      <div className="NotificationView-CommentTime">
        <p>{diffDuration.minutes()} 분 전</p>
      </div>
    );
  } else if (diffDuration.days() < 1) {
    return (
      <div className="NotificationView-CommentTime">
        <p>{diffDuration.hours()} 시간 전</p>
      </div>
    );
  } else {
    return (
      <div className="NotificationView-CommentTime">
        <p>{beginDate.format("YYYY-MM-DD")}</p>
      </div>
    );
  }
}

function CommentNickname() {
  const navigate = useNavigate();

  const message = useAppSelector((state) => state.notificationView.message);

  const onClick = () => {
    navigate(`/profile_view/${message.commentAuthorUid}`);
  };

  if (message.commentAuthorNickname !== "") {
    return (
      <div className="NotificationView-CommentNickname">
        <button onClick={onClick}>{message.commentAuthorNickname}</button>
      </div>
    );
  } else {
    return (
      <div className="NotificationView-CommentNickname">
        <button onClick={onClick}>닉네임 없음</button>
      </div>
    );
  }
}

function CommentReport() {
  const message = useAppSelector((state) => state.notificationView.message);

  const onClick = () => {};

  return (
    <div className="NotificationView-CommentReport">
      <button onClick={onClick}>
        <img src={threeDots} />
      </button>
    </div>
  );
}

function CommentBody() {
  const message = useAppSelector((state) => state.notificationView.message);

  return (
    <div className="NotificationView-CommentBody">
      <p>{message.commentContent}</p>
    </div>
  );
}

function CommentSummary() {
  return (
    <div className="NotificationView-CommentSummary">
      <CommentNumUps />
      <CommentNumDowns />
      <CommentNumReplies />
      <CommentNumTokens />
    </div>
  );
}

function CommentNumUps() {
  const navigate = useNavigate();

  const documentId = useAppSelector(
    (state) => state.notificationView.documentId,
  );
  const commentId = useAppSelector((state) => state.notificationView.commentId);
  const replyId = useAppSelector((state) => state.notificationView.replyId);
  const message = useAppSelector((state) => state.notificationView.message);

  const [numUps, setNumUps] = useState(0);
  const [click, setClick] = useState(false);

  const auth = getAuth();

  const firestore = getFirestore();

  useEffect(() => {
    if (documentId !== "" && commentId !== "") {
      const commentDocRef = doc(
        firestore,
        "metadata",
        documentId,
        "comment",
        commentId,
      );
      getDoc(commentDocRef).then((docSnap) => {
        if (docSnap.exists() && docSnap.data().hasOwnProperty("numUps")) {
          setNumUps(docSnap.data()["numUps"]);
        }
      });
    }
  }, [documentId, commentId, replyId]);

  if (documentId !== "" && commentId !== "" && auth.currentUser !== null) {
    const upDocRef = doc(
      firestore,
      "metadata",
      documentId,
      "comment",
      commentId,
      "up",
      auth.currentUser.uid,
    );
    getDoc(upDocRef).then((docSnap) => {
      if (docSnap.exists()) {
        setClick(true);
      }
    });

    const commentDocRef = doc(
      firestore,
      "metadata",
      documentId,
      "comment",
      commentId,
    );

    const onClick = async () => {
      if (click) {
        setNumUps(numUps - 1);
        setClick(false);

        await deleteDoc(upDocRef);

        await updateDoc(commentDocRef, {
          numUps: increment(-1),
        });
      } else {
        setNumUps(numUps + 1);
        setClick(true);

        const timestamp = Date.now();

        await setDoc(upDocRef, {
          uid: auth.currentUser!.uid,
          timestamp: timestamp,
        });

        await updateDoc(commentDocRef, {
          numUps: increment(1),
        });
      }
    };

    if (click) {
      return (
        <div className="NotificationView-CommentNumUps-Focus">
          <button onClick={onClick}>
            <img src={upFocus} />
            <p>{numUps}</p>
          </button>
        </div>
      );
    } else {
      return (
        <div className="NotificationView-CommentNumUps">
          <button onClick={onClick}>
            <img src={up} />
            <p>{numUps}</p>
          </button>
        </div>
      );
    }
  } else {
    const onClick = () => {
      navigate("/login");
    };

    return (
      <div className="NotificationView-CommentNumUps">
        <button onClick={onClick}>
          <img src={up} />
          <p>{numUps}</p>
        </button>
      </div>
    );
  }
}

function CommentNumDowns() {
  const navigate = useNavigate();

  const documentId = useAppSelector(
    (state) => state.notificationView.documentId,
  );
  const commentId = useAppSelector((state) => state.notificationView.commentId);
  const replyId = useAppSelector((state) => state.notificationView.replyId);
  const message = useAppSelector((state) => state.notificationView.message);

  const [numDowns, setNumDowns] = useState(0);
  const [click, setClick] = useState(false);

  const auth = getAuth();

  const firestore = getFirestore();

  useEffect(() => {
    if (documentId !== "" && commentId !== "") {
      const commentDocRef = doc(
        firestore,
        "metadata",
        documentId,
        "comment",
        commentId,
      );
      getDoc(commentDocRef).then((docSnap) => {
        if (docSnap.exists() && docSnap.data().hasOwnProperty("numDowns")) {
          setNumDowns(docSnap.data()["numDowns"]);
        }
      });
    }
  }, [documentId, commentId, replyId]);

  if (documentId !== "" && commentId !== "" && auth.currentUser !== null) {
    const downDocRef = doc(
      firestore,
      "metadata",
      documentId,
      "comment",
      commentId,
      "down",
      auth.currentUser.uid,
    );
    getDoc(downDocRef).then((docSnap) => {
      if (docSnap.exists()) {
        setClick(true);
      }
    });

    const commentDocRef = doc(
      firestore,
      "metadata",
      documentId,
      "comment",
      commentId,
    );

    const onClick = async () => {
      if (click) {
        setNumDowns(numDowns - 1);
        setClick(false);

        await deleteDoc(downDocRef);

        await updateDoc(commentDocRef, {
          numDowns: increment(-1),
        });
      } else {
        setNumDowns(numDowns + 1);
        setClick(true);

        const timestamp = Date.now();

        await setDoc(downDocRef, {
          uid: auth.currentUser!.uid,
          timestamp: timestamp,
        });

        await updateDoc(commentDocRef, {
          numDowns: increment(1),
        });
      }
    };

    if (click) {
      return (
        <div className="NotificationView-CommentNumDowns-Focus">
          <button onClick={onClick}>
            <img src={downFocus} />
            <p>{numDowns}</p>
          </button>
        </div>
      );
    } else {
      return (
        <div className="NotificationView-CommentNumDowns">
          <button onClick={onClick}>
            <img src={down} />
            <p>{numDowns}</p>
          </button>
        </div>
      );
    }
  } else {
    const onClick = () => {
      navigate("/login");
    };

    return (
      <div className="NotificationView-CommentNumDowns">
        <button onClick={onClick}>
          <img src={down} />
          <p>{numDowns}</p>
        </button>
      </div>
    );
  }
}

function CommentNumReplies() {
  const documentId = useAppSelector(
    (state) => state.notificationView.documentId,
  );
  const commentId = useAppSelector((state) => state.notificationView.commentId);
  const replyId = useAppSelector((state) => state.notificationView.replyId);
  const message = useAppSelector((state) => state.notificationView.message);

  const [numReplies, setNumReplies] = useState(0);

  const firestore = getFirestore();

  useEffect(() => {
    if (documentId !== "" && commentId !== "") {
      const commentDocRef = doc(
        firestore,
        "metadata",
        documentId,
        "comment",
        commentId,
      );
      getDoc(commentDocRef).then((docSnap) => {
        if (docSnap.exists() && docSnap.data().hasOwnProperty("numReplies")) {
          setNumReplies(docSnap.data()["numReplies"]);
        }
      });
    }
  }, [documentId, commentId, replyId]);

  return (
    <div className="NotificationView-CommentNumReplies">
      <button>
        <img src={comment} />
        <p>{numReplies}</p>
      </button>
    </div>
  );
}

function CommentNumTokens() {
  const documentId = useAppSelector(
    (state) => state.notificationView.documentId,
  );
  const commentId = useAppSelector((state) => state.notificationView.commentId);
  const replyId = useAppSelector((state) => state.notificationView.replyId);
  const message = useAppSelector((state) => state.notificationView.message);

  const [numTokens, setNumTokens] = useState(0);

  return (
    <div className="NotificationView-CommentNumTokens">
      <button>
        <p>TOKEN</p>
        <ComingSoon />
      </button>
    </div>
  );
}

function ReplyInput() {
  const message = useAppSelector((state) => state.notificationView.message);

  return (
    <div className="NotificationView-ReplyInput">
      <ReplyImage />
      <ReplyInputAvatar />
      <ReplyInputBody />
    </div>
  );
}

function ReplyImage() {
  return (
    <div className="NotificationView-ReplyImage">
      <img src={reply} />
    </div>
  );
}

function ReplyInputAvatar() {
  const [avatarUrl, setAvatarUrl] = useState("");

  useEffect(() => {
    const auth = getAuth();

    const firestore = getFirestore();

    if (auth.currentUser !== null) {
      const userDocRef = doc(firestore, "user", auth.currentUser.uid);

      getDoc(userDocRef).then((docSnap) => {
        if (docSnap.exists()) {
          const data = docSnap.data();

          setAvatarUrl(data.avatarUrl || "");
        }
      });
    }
  }, []);

  if (avatarUrl !== "") {
    return (
      <div className="NotificationView-ReplyInputAvatar">
        <img src={avatarUrl} />
      </div>
    );
  } else {
    return (
      <div className="NotificationView-ReplyInputAvatar">
        <img src={defaultAvatar} />
      </div>
    );
  }
}

function ReplyInputBody() {
  const message = useAppSelector((state) => state.notificationView.message);

  const dispatch = useAppDispatch();

  const [content, setContent] = useState("");

  const onChange = (e: any) => {
    setContent(e.target.value);
  };

  const onClick = async () => {
    if (content !== "") {
      if (content !== "") {
        dispatch(coreActions.setIsLoading(true));

        const auth = getAuth();
        const firestore = getFirestore();

        const timestamp = Date.now();

        const id = uuid();

        const replyId = timestamp.toString() + "-" + id;

        if (auth.currentUser !== null) {
          await updateDoc(
            doc(
              firestore,
              "metadata",
              message.documentId,
              "comment",
              message.commentId,
            ),
            {
              numReplies: increment(1),
            },
          );

          await setDoc(
            doc(
              firestore,
              "board",
              message.documentId,
              "comment",
              message.commentId,
              "reply",
              replyId,
            ),
            {
              documentId: message.documentId,
              commentId: message.commentId,
              replyId: replyId,
              authorUid: auth.currentUser.uid,
              content: content,
              timestamp: timestamp,
              numUps: 0,
              numDowns: 0,
            },
          );

          await setDoc(
            doc(
              firestore,
              "metadata",
              message.documentId,
              "comment",
              message.commentId,
              "reply",
              replyId,
            ),
            {
              documentId: message.documentId,
              commentId: message.commentId,
              replyId: replyId,
              authorUid: auth.currentUser.uid,
              timestamp: timestamp,
            },
          );

          await setDoc(
            doc(
              firestore,
              "user",
              message.commentAuthorUid,
              "notification",
              replyId,
            ),
            {
              messageType: "reply",
              documentId: message.documentId,
              commentId: message.commentId,
              replyId: replyId,
              authorUid: auth.currentUser!.uid,
              timestamp: timestamp,
            },
          );
        } else {
          alert("답글 쓰기는 로그인하여야 가능합니다.");
        }

        setContent("");
        dispatch(coreActions.setIsLoading(false));
      }
    }
  };

  return (
    <div className="NotificationView-ReplyInputBody">
      <form>
        <textarea
          placeholder={"답글을 남겨주세요!"}
          wrap="soft"
          value={content}
          onChange={onChange}
        />
      </form>
      <button onClick={onClick}>등록</button>
    </div>
  );
}

function Replies() {
  const documentId = useAppSelector(
    (state) => state.notificationView.documentId,
  );
  const commentId = useAppSelector((state) => state.notificationView.commentId);
  const replyId = useAppSelector((state) => state.notificationView.replyId);
  const message = useAppSelector((state) => state.notificationView.message);
  const replies = useAppSelector((state) => state.notificationView.replies);

  const dispatch = useAppDispatch();

  const [lastVisible, setLastVisible] = useState<any>(undefined);

  const firestore = getFirestore();

  const [isEnd, setIsEnd] = useState(true);

  const getReplies = () => {
    if (documentId! !== "" && commentId !== null) {
      let q;
      if (lastVisible === -1) {
        return;
      } else if (lastVisible !== undefined) {
        q = query(
          collection(
            firestore,
            "board",
            documentId,
            "comment",
            commentId,
            "reply",
          ),
          orderBy("timestamp", "desc"),
          limit(10),
          startAfter(lastVisible),
        );
      } else {
        q = query(
          collection(
            firestore,
            "board",
            documentId,
            "comment",
            commentId,
            "reply",
          ),
          orderBy("timestamp", "desc"),
          limit(10),
        );
      }

      onSnapshot(q, async (snapshot) => {
        const newReplies = snapshot.docs.map((elem) => elem.data());

        for (const elem of newReplies) {
          let authorAvatarUrl = "";
          let authorNickname = "";

          const userDocRef = doc(firestore, "user", elem.authorUid);

          const docSnap = await getDoc(userDocRef);
          if (docSnap.exists()) {
            const data = docSnap.data();
            authorAvatarUrl = data.avatarUrl || "";
            authorNickname = data.nickname || "";
          }

          const reply: ReplyState = {
            replyId: elem.replyId,
            authorUid: elem.authorUid,
            authorAvatarUrl: authorAvatarUrl,
            authorNickname: authorNickname,
            timestamp: elem.timestamp,
            content: elem.content,
            numUps: 0,
            numDowns: 0,
          };

          dispatch(notificationViewActions.appendReply(reply));
        }

        if (snapshot.docs.length === 0) {
          setLastVisible(-1);
          setIsEnd(true);
        } else {
          setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
          setIsEnd(false);
        }
      });
    }
  };

  useEffect(() => {
    setLastVisible(undefined);
    dispatch(notificationViewActions.resetReplies());
    getReplies();
  }, [documentId, commentId, replyId]);

  const onClick = () => {
    getReplies();
  };

  if (!isEnd) {
    return (
      <div className="NotificationView-Replies">
        {replies.map((item, _) => {
          return <Reply reply={item} />;
        })}
        <div className="NotificationView-ReadMoreReplies">
          <button onClick={onClick}>답글 더보기</button>
        </div>
      </div>
    );
  } else {
    return (
      <div className="NotificationView-Replies">
        {replies.map((item, _) => {
          return <Reply reply={item} />;
        })}
      </div>
    );
  }
}

function Reply(props: { reply: ReplyState }) {
  return (
    <div className="NotificationView-Reply">
      <ReplyHeader reply={props.reply} />
      <ReplyBody reply={props.reply} />
      <ReplySummary reply={props.reply} />
    </div>
  );
}

function ReplyHeader(props: { reply: ReplyState }) {
  return (
    <div className="NotificationView-CommentHeader">
      <ReplyImage />
      <ReplyAvatar reply={props.reply} />
      <ReplyNickname reply={props.reply} />
      <ReplyTime reply={props.reply} />
      <ReplyReport reply={props.reply} />
    </div>
  );
}

function ReplyAvatar(props: { reply: ReplyState }) {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(`/profile_view/${props.reply.authorUid}`);
  };

  if (props.reply.authorAvatarUrl !== "") {
    return (
      <div className="NotificationView-ReplyAvatar">
        <img src={props.reply.authorAvatarUrl} onClick={onClick} />
      </div>
    );
  } else {
    return (
      <div className="NotificationView-ReplyAvatar">
        <img src={defaultAvatar} onClick={onClick} />
      </div>
    );
  }
}

function ReplyTime(props: { reply: ReplyState }) {
  const endTimestamp = Date.now();

  const beginDate = moment(props.reply.timestamp);
  const endDate = moment(endTimestamp);
  const diff = endDate.diff(beginDate);
  const diffDuration = moment.duration(diff);

  if (diffDuration.minutes() < 1) {
    return (
      <div className="NotificationView-ReplyTime">
        <p>방금 전</p>
      </div>
    );
  } else if (diffDuration.hours() < 1) {
    return (
      <div className="NotificationView-ReplyTime">
        <p>{diffDuration.minutes()} 분 전</p>
      </div>
    );
  } else if (diffDuration.days() < 1) {
    return (
      <div className="NotificationView-ReplyTime">
        <p>{diffDuration.hours()} 시간 전</p>
      </div>
    );
  } else {
    return (
      <div className="NotificationView-ReplyTime">
        <p>{beginDate.format("YYYY-MM-DD")}</p>
      </div>
    );
  }
}

function ReplyNickname(props: { reply: ReplyState }) {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(`/profile_view/${props.reply.authorUid}`);
  };

  if (props.reply.authorNickname !== "") {
    return (
      <div className="NotificationView-ReplyNickname">
        <button onClick={onClick}>{props.reply.authorNickname}</button>
      </div>
    );
  } else {
    return (
      <div className="NotificationView-ReplyNickname">
        <button onClick={onClick}>닉네임 없음</button>
      </div>
    );
  }
}

function ReplyReport(props: { reply: ReplyState }) {
  const onClick = () => {};

  return (
    <div className="NotificationView-ReplyReport">
      <button onClick={onClick}>
        <img src={threeDots} />
      </button>
    </div>
  );
}

function ReplyBody(props: { reply: ReplyState }) {
  return (
    <div className="NotificationView-ReplyBody">
      <p>{props.reply.content}</p>
    </div>
  );
}

function ReplySummary(props: { reply: ReplyState }) {
  return (
    <div className="NotificationView-ReplySummary">
      <ReplyNumUps reply={props.reply} />
      <ReplyNumDowns reply={props.reply} />
    </div>
  );
}

function ReplyNumUps(props: { reply: ReplyState }) {
  const navigate = useNavigate();

  const message = useAppSelector((state) => state.notificationView.message);

  const [numUps, setNumUps] = useState(0);
  const [click, setClick] = useState(false);

  const auth = getAuth();

  const firestore = getFirestore();

  useEffect(() => {
    if (message.documentId !== "" && message.commentId !== "") {
      const replyDocRef = doc(
        firestore,
        "metadata",
        message.documentId,
        "comment",
        message.commentId,
        "reply",
        props.reply.replyId,
      );
      getDoc(replyDocRef).then((docSnap) => {
        if (docSnap.exists() && docSnap.data().hasOwnProperty("numUps")) {
          setNumUps(docSnap.data()["numUps"]);
        }
      });
    }
  }, [props]);

  if (
    message.documentId !== "" &&
    message.commentId !== "" &&
    auth.currentUser !== null
  ) {
    const upDocRef = doc(
      firestore,
      "metadata",
      message.documentId,
      "comment",
      message.commentId,
      "reply",
      props.reply.replyId,
      "up",
      auth.currentUser.uid,
    );
    getDoc(upDocRef).then((docSnap) => {
      if (docSnap.exists()) {
        setClick(true);
      }
    });

    const replyDocRef = doc(
      firestore,
      "metadata",
      message.documentId,
      "comment",
      message.commentId,
      "reply",
      props.reply.replyId,
    );

    const onClick = async () => {
      if (click) {
        setNumUps(numUps - 1);
        setClick(false);

        await deleteDoc(upDocRef);

        await updateDoc(replyDocRef, {
          numUps: increment(-1),
        });
      } else {
        setNumUps(numUps + 1);
        setClick(true);

        const timestamp = Date.now();

        await setDoc(upDocRef, {
          uid: auth.currentUser!.uid,
          timestamp: timestamp,
        });

        await updateDoc(replyDocRef, {
          numUps: increment(1),
        });
      }
    };

    if (click) {
      return (
        <div className="NotificationView-ReplyNumUps-Focus">
          <button onClick={onClick}>
            <img src={upFocus} />
            <p>{numUps}</p>
          </button>
        </div>
      );
    } else {
      return (
        <div className="NotificationView-ReplyNumUps">
          <button onClick={onClick}>
            <img src={up} />
            <p>{numUps}</p>
          </button>
        </div>
      );
    }
  } else {
    const onClick = () => {
      navigate("/login");
    };

    return (
      <div className="NotificationView-ReplyNumUps">
        <button onClick={onClick}>
          <img src={up} />
          <p>{numUps}</p>
        </button>
      </div>
    );
  }
}

function ReplyNumDowns(props: { reply: ReplyState }) {
  const navigate = useNavigate();

  const message = useAppSelector((state) => state.notificationView.message);

  const [numDowns, setNumDowns] = useState(0);
  const [click, setClick] = useState(false);

  const auth = getAuth();

  const firestore = getFirestore();

  useEffect(() => {
    if (message.documentId !== "" && message.commentId !== "") {
      const replyDocRef = doc(
        firestore,
        "metadata",
        message.documentId,
        "comment",
        message.commentId,
        "reply",
        props.reply.replyId,
      );
      getDoc(replyDocRef).then((docSnap) => {
        if (docSnap.exists() && docSnap.data().hasOwnProperty("numDowns")) {
          setNumDowns(docSnap.data()["numDowns"]);
        }
      });
    }
  }, [props]);

  if (
    message.documentId !== "" &&
    message.commentId !== "" &&
    auth.currentUser !== null
  ) {
    const downDocRef = doc(
      firestore,
      "metadata",
      message.documentId,
      "comment",
      message.commentId,
      "reply",
      props.reply.replyId,
      "down",
      auth.currentUser.uid,
    );
    getDoc(downDocRef).then((docSnap) => {
      if (docSnap.exists()) {
        setClick(true);
      }
    });

    const replyDocRef = doc(
      firestore,
      "metadata",
      message.documentId,
      "comment",
      message.commentId,
      "reply",
      props.reply.replyId,
    );

    const onClick = async () => {
      if (click) {
        setNumDowns(numDowns - 1);
        setClick(false);

        await deleteDoc(downDocRef);

        await updateDoc(replyDocRef, {
          numDowns: increment(-1),
        });
      } else {
        setNumDowns(numDowns + 1);
        setClick(true);

        const timestamp = Date.now();

        await setDoc(downDocRef, {
          uid: auth.currentUser!.uid,
          timestamp: timestamp,
        });

        await updateDoc(replyDocRef, {
          numDowns: increment(1),
        });
      }
    };

    if (click) {
      return (
        <div className="NotificationView-ReplyNumDowns-Focus">
          <button onClick={onClick}>
            <img src={downFocus} />
            <p>{numDowns}</p>
          </button>
        </div>
      );
    } else {
      return (
        <div className="NotificationView-ReplyNumDowns">
          <button onClick={onClick}>
            <img src={down} />
            <p>{numDowns}</p>
          </button>
        </div>
      );
    }
  } else {
    const onClick = () => {
      navigate("/login");
    };

    return (
      <div className="NotificationView-ReplyNumDowns">
        <button onClick={onClick}>
          <img src={down} />
          <p>{numDowns}</p>
        </button>
      </div>
    );
  }
}
