import "./right_sidebar.css";
import gToken from "../asset/image/g_token.png";
import gRanking from "../asset/image/g_ranking.png";
import comingSoon from "../asset/image/coming_soon.png";

export function RightSidebar() {
  return (
    <div className="RightSidebar">
      <GRanking />
      <GToken />
    </div>
  );
}

function GRanking() {
  return (
    <div className="RightSidebar-GRanking">
      <img src={gRanking} />
      <ComingSoon />
    </div>
  );
}

function GToken() {
  return (
    <div className="RightSidebar-GToken">
      <img src={gToken} />
      <ComingSoon />
    </div>
  );
}

function ComingSoon() {
  return (
    <div className="RightSidebar-ComingSoon">
      <img src={comingSoon} />
    </div>
  );
}
