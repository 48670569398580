import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface EmailVerificationState {
  email: string;
  password: string;
}

const initialState: EmailVerificationState = {
  email: "",
  password: "",
};

export const emailVerification = createSlice({
  name: "emailVerification",
  initialState,
  reducers: {
    setEmail(state, action: PayloadAction<string>) {
      state.email = action.payload;
    },

    setPassword(state, action: PayloadAction<string>) {
      state.password = action.payload;
    },
  },
});

export const emailVerificationActions = emailVerification.actions;
export default emailVerification.reducer;
